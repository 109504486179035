import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import { config } from "../../utils/config";
import ThemeMaker from "../../components/thememaker";
import { json } from "stream/consumers";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';
import { Paginated } from "../../components/Paginated";
var $ = require("jquery");

const myStatus: any = {
  2: "Open",
  3: "Pending",
  4: "Resolved",
  5: "Closed"
}
const SupportList = () => {
  // const token: any = sessionStorage.getItem("isLogedIn");
  // const decoded: any = jwt_decode(token);
  //console.log(decoded.data.permission.user);
  // const { user, product, order, support } = decoded.data.permission;
  const { apiEndpoint } = config;
  // const history = useHistory();
  const [supportList, setSupportList] = useState([]);

  const [loading, setloading] = useState(true);
  // const closeModel: any = useRef();

  const [error, setError] = useState("");
  const [_id, setId] = useState("");
  const [issueActions, setissueActions] = useState([])
  const getSupportList = async () => {
    let apiEndpoint =  baseUrlDefiner();
    try {
      const {
        data: { data },
      } = await axios({
        url: `${apiEndpoint}/customer_review`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
      },
      });
      setSupportList(data);
      setissueActions(JSON.parse(data?.issue_actions))
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };

  const exportToExcel = (supportList: any) => {
    const headers = [
      'S.No',
      'Ticket ID',
      'Order ID',
      'Network Order ID', 'Buyer NP Name', 'Seller NP Name', 'Logistics NP Name', 'Ticket Creation Timestamp',
      'Issue Category - L1 (From IGM)', 'Issue Category - L2 (From IGM)', 'Sub Category', 'Order Category', 'Ticket Status',
      'Ticket Closure Time Stamp', 'Timestamp of Ticket Relay to Seller NP', 'Timestamp of Ticket Relay to Logistics NP',
      'Timestamp of Ticket Relay to Buyer NP', 'Timestamp of Ticket Last Update'
      // Add more custom headers as needed
    ];

    // // Prepare data with just the values to be displayed in the columns
    const data = supportList.map((item: any, index: any) => {
      let buyerNp = JSON.parse(item?.buyerNp)
      let Createdat = moment((item.item?.createdAt)).format("DD-MM-YYYY hh:mm:ss")
      let ResolvedDate = (item?.resolvedAt == null) ? "" : moment((item?.resolvedAt)).format("DD-MM-YYYY hh:mm:ss")
      let lastUpdate = (item?.lastUpdate !== null) ? moment((item?.lastUpdate)).format("DD-MM-YYYY hh:mm:ss") : ""
      let EstimateTimeTicketCloser = (item?.estimateTimeToCloase == null) ? "" : moment((item?.estimateTimeToCloase)).format("DD-MM-YYYY hh:mm:ss");
      let buyerrelaydate = (item?.relaytimebuyer == null) ? "" : moment((item?.relaytimebuyer)).format("DD-MM-YYYY hh:mm:ss");
      let sellerrelaydate = (item?.relaytime == null) ? "" : moment((item?.relaytime)).format("DD-MM-YYYY hh:mm:ss");

      function suncat() {
        switch (item.issue_cat_level2) {
          case "FLM01": return "Wrong delivery address"
          case "FLM02": return "Delay in delivery"
          case "FLM03": return "Delayed delivery"
          case "FLM04": return "Packaging"
          case "FLM05": return "Buyer not found"
          case "FLM06": return "Seller not found"
          case "FLM07": return "Package info mismatch"
          case "FLM08": return "Incorrectly marked as delivered"
          case "ITM01": return "Missing items"
          case "ITM02": return "Quantity Issue"
          case "ITM03": return "Item mismatch"
          case "ITM04": return "Quality issue"
          case "ITM05": return "Expired item"
        }
      }

      const subcatvalue = suncat()

      return {
        "S.No": index + 1,
        "Ticket ID": item?.ticketID,
        "Order ID": item?.orderId,
        "Network Order ID": item?.ntOrderId,
        "Buyer NP Name": buyerNp?.network_participant_id,
        "Seller NP Name": item?.sellerNp,
        'Logistics NP Name': "",
        "Ticket Creation Timestamp": Createdat,
        "Issue Category - L1 (From IGM)": item?.issue_category,
        "Issue Category - L2 (From IGM)": subcatvalue,
        "Sub Category": subcatvalue,
        "Order Category": "Grocery",
        "Ticket Status": item?.ntStatus,
        "Ticket Closure Time Stamp": EstimateTimeTicketCloser,
        'Timestamp of Ticket Relay to Seller NP': sellerrelaydate,
        'Timestamp of Ticket Relay to Logistics NP': "",
        'Timestamp of Ticket Relay to Buyer NP': buyerrelaydate,
        "Timestamp of Ticket Last Update": lastUpdate

        // Add more data properties corresponding to the custom headers
      }
    });

    // // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    // // Generate a unique name for the Excel file (you can customize this as needed)
    const fileName = `orders_${new Date().getTime()}.xlsx`;

    // // Export the workbook as an Excel file
    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
    getSupportList();
  }, []);

  function searchHandler(e: any) {
    // console.log(e.target.value)
    if (e.target.value == "") {
      getSupportList()
    } else {

      let filteredItems: any = supportList?.filter((item: any) => {
        return item?.ntOrderId.includes(e.target.value) || item?.orderId.includes(e.target.value) || item?.ticketID.includes(e.target.value)
      })
      setSupportList(filteredItems)

    }
  }


  const COLUMNS = [
    {
      Header: "Sn No",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Ticket id",
      accessor: "ticketID",
      sticky: "left"
    },
    {
      Header: "Order id",
      accessor: "orderId",
      sticky: "left"
    },
    {
      Header: "Network-Order-ID",
      accessor: "ntOrderId",
      sticky: "left"
    },
    {
      Header: "Buyer NP Name",
      accessor: (a: any) => { let buyerNp = JSON.parse(a?.buyerNp); return buyerNp?.network_participant_id },
      sticky: "left"
    },
    {
      Header: "Seller NP Name",
      sticky: "left",
      accessor: "sellerNp"
    },
    {
      Header: "Logistics NP Name",
      sticky: "left",
      accessor: () => ""
    },
    {
      Header: "Ticket Creation Timestamp",
      sticky: "left",
      accessor: (a: any) => { let createdDate = moment((a?.createdAt)).format("DD-MM-YYYY hh:mm:ss"); return createdDate }
    },
    {
      Header: "Issue Category - L1 (From IGM)",
      accessor: "issue_category",
      sticky: "left",

    },
    {
      Header: "Issue Category - L2 (From IGM)",
      accessor: (a: any) => {
        switch (a.issue_cat_level2) {
          case "FLM01": return "Wrong delivery address"
          case "FLM02": return "Delay in delivery"
          case "FLM03": return "Delayed delivery"
          case "FLM04": return "Packaging"
          case "FLM05": return "Buyer not found"
          case "FLM06": return "Seller not found"
          case "FLM07": return "Package info mismatch"
          case "FLM08": return "Incorrectly marked as delivered"
          case "ITM01": return "Missing items"
          case "ITM02": return "Quantity Issue"
          case "ITM03": return "Item mismatch"
          case "ITM04": return "Quality issue"
          case "ITM05": return "Expired item"
        }
      },
      sticky: "left",

    },
    {
      Header: "Sub Category",
      accessor: (a: any) => {
        switch (a.issue_cat_level2) {
          case "FLM01": return "Wrong delivery address"
          case "FLM02": return "Delay in delivery"
          case "FLM03": return "Delayed delivery"
          case "FLM04": return "Packaging"
          case "FLM05": return "Buyer not found"
          case "FLM06": return "Seller not found"
          case "FLM07": return "Package info mismatch"
          case "FLM08": return "Incorrectly marked as delivered"
          case "ITM01": return "Missing items"
          case "ITM02": return "Quantity Issue"
          case "ITM03": return "Item mismatch"
          case "ITM04": return "Quality issue"
          case "ITM05": return "Expired item"
        }
      },
      sticky: "left",

    },
    {
      Header: "Order Category",
      accessor: () => "Grocery",
      sticky: "left",

    },
    {
      Header: "Ticket Status",
      accessor: "ntStatus",
      sticky: "left",
    },
    {
      Header: "Ticket Closure TIme Stamp",
      accessor: (a: any) => { let EstimateTimeTicketCloser = (a?.estimateTimeToCloase == null) ? "" : moment((a?.estimateTimeToCloase)).format("DD-MM-YYYY hh:mm:ss"); return EstimateTimeTicketCloser },
      sticky: "left",
    },
    {
      Header: "Timestamp of Ticket Relay to Seller NP",
      accessor: (a: any) => { let sellerrelaytime = (a?.relaytime == null) ? "" : moment((a?.relaytime)).format("DD-MM-YYYY hh:mm:ss"); return sellerrelaytime },
      sticky: "left",
    },
    {
      Header: "Timestamp of Ticket Relay to Logistics NP",
      accessor: (a: any) => { return "" },
      sticky: "left",
    },
    {
      Header: "Timestamp of Ticket Relay to Buyer NP",
      accessor: (a: any) => { let buyerelaytime = (a?.relaytimebuyer == null) ? "" : moment((a?.relaytimebuyer)).format("DD-MM-YYYY hh:mm:ss"); return buyerelaytime },
      sticky: "left",
    },

    {
      Header: "Timestamp of Ticket Last Update",
      accessor: (a: any) => { let lastUpdate = (a?.lastUpdate !== null) ? moment((a?.lastUpdate)).format("DD-MM-YYYY hh:mm:ss") : ""; return lastUpdate },
      sticky: "left",
    },

  ];
  return (
    <>
      <ThemeMaker>
        <div className="row m-0" style={{ width: "100%" }}>
          <div className="col-md-12  mt-3">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold" style={{ color: "blue", display: "flex", justifyContent: "space-between  " }}>
                  Support List

                  <button className="btn btn-primary" onClick={() => exportToExcel(supportList)}>Download Sheet</button>

                </h6>

                <div>
                  Search   <input type="text" placeholder="By OrderID, Ticket id,Network-Order-ID " style={{ width: "300px" }} onChange={(e: any) => searchHandler(e)} />
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  {loading ? (
                    // <Loader />
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                      <CircularProgress />
                    </div>

                  ) : (
                    <Paginated data={supportList}
                      columns={COLUMNS} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default SupportList;
