import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { config } from "../../utils/config";
import { BasicTable } from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import UserTemplate from "../../components/UserTemplate/UserTemplate";
import '../../components/table.css';
import styles from "../transaction/transaction.module.css"
import searchicon_black from "../../icons/searchicon_black.png"
import style from "../../components/Search&Options/Search&Options.module.css"
import CircularProgress from '@mui/material/CircularProgress';
import Sellerpagination from "../../components/Paginated/Sellerpagination";
import CryptoJS from 'crypto-js';
const { apiEndpoint } = config;

var $ = require('jquery');

var count = 1;
const UserList = () => {
    const [userlist, setUserList] = useState([]);
    const [userloading, setuserLoading] = useState(false);
    const [sellerList, setSellerList] = useState([]);
    const rawData = {
        seller_id: ''
    }
    const [secretKey, setSecretKey] = useState('yourSecretKey123');
    const [searchData, setSearchData]: any = useState(rawData);
    const decode = tokenDecode();
    const { data } = decode;
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const fetchUserList = async () => {
        let apiEndpoint =  baseUrlDefiner();
        let parentId = decode?.data?.user?.parent_id;
        let SellerId = decode?.data?.user?.seller_id;
        let payload;
        if (parentId > 1) {
          payload = { seller_id: parentId }
    
        } else {
          payload = { seller_id: SellerId }
    
        }
        try {
            setuserLoading(true);
            const { data } = await axios({
                url: `${apiEndpoint}/getuserlist`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: payload
            });
            // console.log("user listing", data);
            setuserLoading(false);
            if (data.status === 200) {
                setUserList(data.data);
            }
            setTimeout(() => {
                $('#dataTable').DataTable();
            }, 500)
        } catch (error) {
            setuserLoading(false)
        }
    }
    const userDelete = async (id: any) => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/userdelete`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: { id }
            });
            console.log("data----", data);
            if (data.status === 200) {
                setUserList(userlist.filter((item: any) => item.id !== id));
                toast.success("User deleted !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const getSellerList = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
            });
            if (data.status === 200) {
                console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        try {

            var optionvalue = e.target.value;
            setSearchData({ ...searchData, [e.target.name]: e.target.value });
            console.log(optionvalue);
            const { data } = await axios({
                url: `${apiEndpoint}/getuserfilterbyseller`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    seller_id: optionvalue
                }

            });

            if (data.status === 200) {
                console.log(data);
                setUserList(data.data);
            }


        } catch (error) {
            console.log("error", error);
        }
    };
    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Name",
            Footer: "Name",
            accessor: "name",
            sticky: "left"
        },
        {
            Header: "Email",
            Footer: "Email",
            accessor: "email",
            sticky: "left"
        },
        {
            Header: "Contact",
            Footer: "Contact",
            accessor: "contact_no",
            sticky: "left"
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row: any) => {
                return <>{row.status ? <span className="badge badge-success">Active</span> :
                    <span className="badge badge-danger">Deactive</span>}</>
            },
            sticky: "left"
        },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                const encryptNumber = () => {
                    const cipherText = CryptoJS.AES.encrypt(row.id.toString(), secretKey).toString();
                    const sanitizedCipherText = cipherText.replace(/\//g, ''); // Remove '/'
                    return sanitizedCipherText
                  };
                return (
                    <>
                        {user.update ? <Link to={`../../updateuser/${encryptNumber()}`} className="btn btn-success btn-circle"> <i className="fas fa-eye"></i></Link> : null}{user.delete ? <button className="btn btn-danger btn-circle" onClick={() => userDelete(row.id)}><i className="fas fa-trash"></i></button> : null}
                    </>
                )
            },
            sticky: "left"
        },

    ];
    count++
    useEffect(() => {
        // $('#dataTable').DataTable();
        fetchUserList();
        getSellerList();
    }, [])
    const [searchValue, setsearchValue] = useState("")
    const masterSearchHandler = async (e: any) => {
        let searchedData = e.target.value;
        setsearchValue(searchedData)
       
        let dataToSend = {
            title: searchedData
        }
        console.log(dataToSend)
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/searchUserFilter`,
                method: "post",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: dataToSend
            })
            console.log("Response from Server-------------", data)
            if (data.status == 200) {
                setUserList(data.message)
            } else {
                console.log("Search API is not running!!");
            }

        } catch (err) {
            console.log(err)
        }

    }
    return (
        <>
            <ThemeMaker>

                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    User list
                                </h6>
                                {/* {seller?.read ?
                                    <div className={styles.sellerListOptions}>
                                        <b>Filter By Seller: </b> <div className={styles.selectionControl}>
                                            <select name="seller_id" id="" onChange={(e: any) => inputHandler(e)} >
                                                <option value="">Select Seller</option>
                                                {
                                                    sellerList.map((item: any) => (
                                                        <option value={item?.seller_id} key={item?.seller_id}>{item?.name}</option>
                                                    ))
                                                }

                                            </select></div></div>
                                    : ""} */}

                                <div className="search col-md-4 mt-1">
                                    <div className="row" style={{ marginTop: "20px" }}>  
                                        <div className={`${style.inputMain} w-100 mt-2`} style={{ margin: "10px auto" }}>
                                            <img src={searchicon_black} alt="" style={{ width: "6%" }} />
                                            <input type="search" name="name" placeholder="Search User"
                                                className="mr-2 w-100"
                                                // onChange={(e) => {
                                                //     seller?.read ? inputeHandler(e) : inputeeHandler(e);
                                                // }}
                                                // value={valuename ? valuename : ""}
                                                value={searchValue}
                                                onChange={masterSearchHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0 p-1" style={{ minHeight: "70vh" }}>
                                <div className="table-responsive">

                                    {/* <Paginated data={userlist} columns={COLUMNS} />   */}

                                    <div className="productListing_desktop">
                                       
                                        {!userloading ? <Paginated data={userlist} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                    </div>
                                    <div className="productListing_mobile">
                                        {/* <UserTemplate userlist={userlist} userDelete={userDelete}></UserTemplate> */}
                                        <Sellerpagination itemsPerPage={20} items={userlist} sellerDelete={userDelete} paginationFor="user"></Sellerpagination>




                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </ThemeMaker>
        </>
    )
}

export default UserList;