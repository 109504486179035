// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.br1 {
    border-radius: 8px;  
  }
  .w80 {
     width: 80%;
  }
  .card1 {
    border: 2px solid #fff;
    box-shadow:0px 0px 10px 0 #a9a9a9;
    padding: 30px 40px;
    width: 95%;
    margin: 50px auto;
  }
  .profilePic {
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }
  .comment1 {
    height: 10px;
    background: #777;
    margin-top: 20px;
  }
  
  .wrapper1 {
    width: 0px;
    animation: fullView 0.5s forwards linear;
  }
  
  @keyframes fullView {
    100% {
      width: 100%;
    }
  }

  .animate1 {
    animation : shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
 }

 @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/tabelloader/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;EACA;KACG,UAAU;EACb;EACA;IACE,sBAAsB;IACtB,iCAAiC;IACjC,kBAAkB;IAClB,UAAU;IACV,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,wCAAwC;EAC1C;;EAEA;IACE;MACE,WAAW;IACb;EACF;;EAEA;IACE,+BAA+B;IAC/B,2EAA2E;IAC3E,4BAA4B;CAC/B;;CAEA;IACG;MACE,8BAA8B;IAChC;IACA;MACE,6BAA6B;IAC/B;EACF","sourcesContent":[".br1 {\n    border-radius: 8px;  \n  }\n  .w80 {\n     width: 80%;\n  }\n  .card1 {\n    border: 2px solid #fff;\n    box-shadow:0px 0px 10px 0 #a9a9a9;\n    padding: 30px 40px;\n    width: 95%;\n    margin: 50px auto;\n  }\n  .profilePic {\n    height: 65px;\n    width: 65px;\n    border-radius: 50%;\n  }\n  .comment1 {\n    height: 10px;\n    background: #777;\n    margin-top: 20px;\n  }\n  \n  .wrapper1 {\n    width: 0px;\n    animation: fullView 0.5s forwards linear;\n  }\n  \n  @keyframes fullView {\n    100% {\n      width: 100%;\n    }\n  }\n\n  .animate1 {\n    animation : shimmer 2s infinite;\n    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);\n    background-size: 1000px 100%;\n }\n\n @keyframes shimmer {\n    0% {\n      background-position: -1000px 0;\n    }\n    100% {\n      background-position: 1000px 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
