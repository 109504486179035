// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td span.onIssue_badge__bhqJE {
  cursor: pointer;
}

input[type=radio] {
  border: 0px;
  width: 100%;
  height: 2em;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  border-radius: 1rem;
}

.onIssue_text-small__MM0gK {
  font-size: 0.9rem;
}

.onIssue_messages__IhL\\+n.onIssue_chat-box__rym3K {
  height: 335px;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="media"] {
    width: 100%!important;
    margin-top: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/onIssue/onIssue.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB;IACE,qBAAqB;IACrB,gBAAgB;EAClB;AACF","sourcesContent":["td span.badge {\n  cursor: pointer;\n}\n\ninput[type=radio] {\n  border: 0px;\n  width: 100%;\n  height: 2em;\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n}\n\n::-webkit-scrollbar-track {\n  width: 5px;\n}\n\n::-webkit-scrollbar-thumb {\n  width: 1em;\n  background-color: #ddd;\n  border-radius: 1rem;\n}\n\n.text-small {\n  font-size: 0.9rem;\n}\n\n.messages.chat-box {\n  height: 335px;\n  overflow-y: scroll;\n}\n\n@media only screen and (max-width: 768px) {\n  /* For mobile phones: */\n  [class*=\"media\"] {\n    width: 100%!important;\n    margin-top: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `onIssue_badge__bhqJE`,
	"text-small": `onIssue_text-small__MM0gK`,
	"messages": `onIssue_messages__IhL+n`,
	"chat-box": `onIssue_chat-box__rym3K`
};
export default ___CSS_LOADER_EXPORT___;
