import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import Papa from 'papaparse';
import "./Master.css"
import Pagination from "../../components/Paginated/Pagination";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

var $ = require("jquery");
var count = 1;
const ProductList = () => {
    const Sstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "10px",
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
    };
    const decode = tokenDecode();
    const { data } = decode;
    const { permission: { product } } = data;
    const decoded = tokenDecode();
    let current_sellerid = decoded.data.user.seller_id
    console.log("current Seller id:-----", current_sellerid)
    const [loading, setLoading] = useState(false);
    //code for importing the csv file
    const [file, setFile] = useState(null);

    const [masterProductListState, setmasterProductListState] = useState({
        data: [], isProductListLoading: false
    });
    const [shipmentModalOpen, setshipmentModalOpen] = useState(false);

    const categoryListing = async () => {
        let apiEndpoint = baseUrlDefiner();
        setmasterProductListState((prev) => { return { ...prev, isProductListLoading: true } });
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/get_master_product`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    "id": []
                },
            });
            console.log("data of master product list-->", data)
            if (data) {
                setmasterProductListState((prev) => { return { ...prev, data: data?.data, isProductListLoading: false } });

            }

        } catch (error) {
            toast.error("Error in getting master product list !")
            setmasterProductListState((prev) => { return { ...prev, isProductListLoading: false } });
        }
    };

    const deleteProduct = async (id) => {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/deletemasterprod`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: { id },
            });
            if (data.status === 200) {
                // setProductList(productList.filter((item) => item.id !== id));
                toast.success("Product deleted!");
            } else {
                toast.error("403 error !");
            }
        } catch (error) {
            toast.error("403 error !");
        }
    };

    const handleImport = async () => {
        let apiEndpoint = baseUrlDefiner();
        // console.log(file)
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: ({ data }) => {
                console.log(data)
                let abc = data.filter(item => item.name !== null)
                let orders = abc.map(order => ({
                    name: order.name,
                    position: order.position,
                    category_id: order.category_id,
                    product_type: order.product_type,
                    pf_brand_owner_FSSAI_license_no: order.pf_brand_owner_FSSAI_license_no,
                    short_description: order.short_description,
                    description: order.description,
                    pc_manufacturer_or_packer_name: order.pc_manufacturer_or_packer_name,
                    pc_common_or_generic_name_of_commodity: order.pc_common_or_generic_name_of_commodity,
                    // pc_common_of_generic_name_of_commodity_in_pkg: order.pc_common_of_generic_name_of_commodity_in_pkg,
                    pc_month_year_of_manufacture_packing_import: order.pc_month_year_of_manufacture_packing_import,
                    pc_multiple_product_name_number_of_quantity: order.pc_multiple_product_name_number_of_quantity,
                    pc_imported_product_country_of_origin: order.pc_imported_product_country_of_origin,
                    // pf_nutritional_info: order.pf_nutritional_info,
                    // pf_additives_info: order.pf_additives_info,
                    // pf_other_fassai_license_no: order.pf_other_fassai_license_no,
                    // pf_importer_fassai_license_no: order.pf_importer_fassai_license_no,                    
                    pc_manufacturer_or_packer_address: order.pc_manufacturer_or_packer_address,
                    mrp: order.mrp,
                    uom: order.uom,
                    selling_price: order.selling_price,
                    tax_included_price: order.tax_included_price,
                    tax_included: order.tax_included,
                    tax: order.tax,
                    hsn: order.hsn,
                    tags: order.tags,
                    sku: order.sku,
                    country_of_origin: order.country_of_origin,
                    customer_care_contact: order.customer_care_contact,
                    time_to_ship: order.time_to_ship,
                    returnable: order.returnable,
                    cancelable: order.cancelable,
                    category_name: order.category_name,
                    return_window: order.return_window,
                    seller_pickup_return: order.seller_pickup_return,
                    replaceable: order.replaceable,
                    cod: order.cod,
                    image: order.image,
                    status: order.status,
                    approve_status: order.approve_status,
                    // retail_approve_status: order.retail_approve_status,
                    brand: order.brand,
                    // upc: order.upc,
                    hair_type: order.hair_type,
                    herbal_or_ayurvedic: order.herbal_or_ayurvedic,
                    quantity: order.quantity,
                    net_qty: order.net_qty,
                    ready_to_eat: order.ready_to_eat,
                    ready_to_cook: order.ready_to_cook,
                    rice_grain_type: order.rice_grain_type,
                    capacity: order.capacity,
                    preservatives: order.preservatives,
                    preservatives_details: order.preservatives_details,
                    flavours_and_spices: order.flavours_and_spices,
                    scented_or_flavour: order.scented_or_flavour,
                    theme_or_occasion_type: order.theme_or_occasion_type,
                    recommended_age: order.recommended_age,
                    mineral_source: order.mineral_source,
                    caffeine_content: order.caffeine_content,
                    absorption_duration: order.absorption_duration,
                    baby_weight: order.baby_weight,
                    brand_name: order.brand_name,
                    weight: order.weight,
                    reference_id: order?.reference_id,
                    //extraas:-
                    seller_id: order.seller_id,
                    updated_at: order.updated_at,
                    ondc_product_id: order.ondc_product_id,
                    product_type_name: order.product_type_name

                }))
                let jsonData = {
                    orders: orders
                };
                console.log(jsonData)
                sendDataToDb(jsonData)
            },
        });
    };
    async function sendDataToDb(jsonData) {
        let apiEndpoint = baseUrlDefiner();
        try {
            setLoading(true);
            console.log(jsonData)
            let result = await axios({
                url: `${apiEndpoint}/uploadFileData`,
                method: 'POST',
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: jsonData,

            })
            // console.log(result)
            setLoading(false);
            if (result.status === 200) {
                // console.log("success")
                toast.success("Products added successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setFile(null)
                categoryListing()
                document.getElementById('inputFile').value = ''
            } else {
                // console.log("this is from succes if")
                toast.error("403 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (error) {
            setLoading(false);
            // console.log("this is from catch")
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row, count) => {
                return <>{count + 1}</>;
            },
            disableFilters: true,
            sticky: "left",
        },
        {
            Header: "Image",
            Footer: "Image",
            accessor: (row) => {
                return (
                    <img
                        src={row?.product ? row?.variations[0]?.image?.split(",")[0] : row?.image?.split(",")[0]}
                        alt="Product Image"
                        height={80}
                        width={80}
                    />
                );
            },
            sticky: "left",
        },
        {
            Header: "Name",
            Footer: "Name",
            accessor: (row) => row?.product ? row?.product?.name : row?.name,
            sticky: "left",
        },
        {
            Header: "Selling Price",
            Footer: "Selling Price",
            accessor: (row) => row?.product ? row?.variations[0]?.selling_price : row?.selling_price,
            sticky: "left",
        },
        {
            Header: "Sku",
            Footer: "Sku",
            accessor: (row) => row?.product ? row?.variations[0]?.sku : row?.sku,
            sticky: "left",
        },
        {
            Header: "Variations",
            Footer: "Variations",
            accessor: (row) => (row?.product && row?.product?.has_variations) ? "Available" : row?.has_variations ? "Available" : "Not Available",
            sticky: "left",
        },
        // {
        //     Header: "Status",
        //     Footer: "Status",
        //     accessor: (row) => {
        //         return <> {row?.product ? <>
        //             {row?.product?.status ? "Active" : "Draft"} </> :
        //             <>  {row.status ? "Active" : "Draft"}</>}</>
        //     },
        //     sticky: "left",
        // },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row) => {
                return (
                    <>
                        {product.update ? (
                            <Link
                                to={`mastercatalog/${  row?.product ?  row?.product?.id : row?.id}?q=${ row?.product ?  row?.product?.has_variations :row?.has_variations}`}
                                className="btn-sm m-1 btn btn-success btn-circle"
                            >
                                <i className="fas fa-pen"></i>
                            </Link>
                        ) : null}
                        {/* {product.delete ? (
                            <button
                                className="btn-sm m-1 btn btn-danger btn-circle"
                                onClick={() => deleteProduct(row.id)}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                        ) : null} */}

                    </>
                );
            },
            sticky: "left",
        },
    ];
    count++;
    useEffect(() => {
        if (current_sellerid == 1) {
            categoryListing();
        }
    }, []);

    function handleShipementModalClose() {
        setshipmentModalOpen(false)
    }


    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary text-left">Master Product List</h6>

                                <div className="col-md-12 d-flex mt-2 justify-content-between">

                                    <div className=" justify-content-end d-none align-items-center mb-2" style={{ gap: "20px" }}>
                                        <span className="text-center" style={{ background: "lightgrey", borderRadius: "12px", fontSize: "14px", width: "150px", color: "black", fontWeight: "600", height: "27px", cursor: "pointer", paddingTop: "3px" }}
                                            onClick={() => {
                                                setshipmentModalOpen(true)
                                            }}
                                        >Import Products</span>
                                    </div>
                                </div>
                            </div>


                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="table-responsive">
                                    <div className="productListing_desktop">
                                        {masterProductListState?.isProductListLoading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div> : <Paginated data={masterProductListState?.data} columns={COLUMNS} />}
                                    </div>
                                    <div className="productListing_mobile">
                                        {/* <ProductTemplate data={productList} deleteProduct={deleteProduct} handle="mastercatalog" categoryListing={categoryListing}></ProductTemplate> */}
                                        <Pagination itemsPerPage={20} items={masterProductListState?.data} deleteProduct={deleteProduct} handle="mastercatalog" categoryListing={categoryListing}></Pagination>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <Modal
                        open={shipmentModalOpen}
                        // onClose={handleShipementModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">

                            <div className="modal-content ">
                                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                    <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Import Using CSV File..</p>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="importingSection">
                                        <input type="file" id="inputFile" onChange={(e) => {
                                            setFile(e.target.files[0]);
                                        }} style={{ marginTop: "30px" }} />


                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={handleImport} > Submit </button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </>
            </ThemeMaker>
        </>
    );
};

export default ProductList;
