// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Invoice_inv_con__aGmi1{
    /* style={{border: "2px solid black", maxWidth:"1024px", margin: "0 auto"}}  */
    /* border: 1px solid black; */
    max-width: 1024px;
    margin: 0 auto;
    padding: 10px;

}

.Invoice_inv_con__aGmi1 p, h6{
    margin: 0 !important;
    padding: 0 !important;
}

.Invoice_inv_con__aGmi1 .Invoice_text_boxes__x859a{
    width: 33%;
    /* border: 1px solid red; */
}

.Invoice_invoiceTableHead__2tLkV th{
    background-color: grey !important;
  
}


@media screen and (max-width: 720px) {
    .Invoice_inv_con__aGmi1 .Invoice_text_boxes__x859a{
        width: 46%;
        /* border: 1px solid red; */
    }

    .Invoice_inv_con__aGmi1{
        font-size: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Invoice/Invoice.module.css"],"names":[],"mappings":"AAAA;IACI,8EAA8E;IAC9E,6BAA6B;IAC7B,iBAAiB;IACjB,cAAc;IACd,aAAa;;AAEjB;;AAEA;IACI,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,iCAAiC;;AAErC;;;AAGA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".inv_con{\n    /* style={{border: \"2px solid black\", maxWidth:\"1024px\", margin: \"0 auto\"}}  */\n    /* border: 1px solid black; */\n    max-width: 1024px;\n    margin: 0 auto;\n    padding: 10px;\n\n}\n\n.inv_con p, h6{\n    margin: 0 !important;\n    padding: 0 !important;\n}\n\n.inv_con .text_boxes{\n    width: 33%;\n    /* border: 1px solid red; */\n}\n\n.invoiceTableHead th{\n    background-color: grey !important;\n  \n}\n\n\n@media screen and (max-width: 720px) {\n    .inv_con .text_boxes{\n        width: 46%;\n        /* border: 1px solid red; */\n    }\n\n    .inv_con{\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inv_con": `Invoice_inv_con__aGmi1`,
	"text_boxes": `Invoice_text_boxes__x859a`,
	"invoiceTableHead": `Invoice_invoiceTableHead__2tLkV`
};
export default ___CSS_LOADER_EXPORT___;
