/* eslint-disable @typescript-eslint/no-unused-vars */
// import { BasicTable } from '../../components/Table';
// import { Paginated } from "../../components/Paginated";
// import '../../components/table.css';
// import TransactionTemplate from "../../components/TransactionTemplate/TransactionTemplate";
// import InputTemplate from "../../components/Search&Options/InputTemplate"
// import { toast } from "react-toastify";

import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import '../../components/table.css';
import styles from "./issues.module.css";
import style from "../../components/Search&Options/Search&Options.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import { Paginated } from "../../components/Paginated";
import styleii from "../transaction/transaction.module.css";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { styled } from '@mui/material/styles';
import eye from "../../icons/eye.png";
import moment from "moment";
import { AgChartsReact } from 'ag-charts-react';
import Cookies from 'js-cookie';
import "../../index.css"
import { baseUrlDefiner, NodebaseUrlDefiner } from "../../utils/config";
const { apiEndpoint, nodeapiEndpoint } = config;

// console.log("apiEndpoint", apiEndpoint);

var $ = require('jquery');

const Issues = () => {
    const decoded: any = tokenDecode();
    const [issuesList, setIssuesList] = useState([]);
    const [SearchList, setSearchList] = useState([]);
    // const [stransactionList, setStransactionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isGraphLoading, setIsGraphLoading] = useState(false);
    const [issueDate, setissueDate]: any = useState([])
    const { data } = decoded;
    let sellerProviderId = data?.seller_data?.provider_id
    const fullUrl = window.location.host;
    const rawData = {
        seller_id: ''
    }

    const [selectedProvider, setselectedProvider]: any = useState("");
    const [issellerSelected, setissellerselected] = useState(false);
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const [sellerList, setSellerList] = useState([]);
    const current_seller_id = decoded?.data?.user?.seller_id;
    var count = 1;
    // const shipping_address = JSON.parse(_orderDetail?.shipping_address);
    const fetchIssuesList = async () => {
        let apiEndpoint = NodebaseUrlDefiner();
        setIsLoading(true);
        try {
            const {
                data: { data },
            } = await axios({
                url: `${apiEndpoint}/igm/get_issue`,
                method: "GET",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    "Ens": `${fullUrl}`
                }
            });
            setIssuesList(data.reverse());
            if (current_seller_id === 1 && decoded?.data?.user?.parent_id === 0) {
                setSearchList(data)
            } else {
                setSearchList(data.filter((item: any) => item.order_details.provider_id == sellerProviderId));
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }
    const getfilterOption = async (optionvalue: any) => {
        let apiEndpoint = NodebaseUrlDefiner();
        setIsLoading(true);
        try {
            const providerID = current_seller_id === 1 ? "" : sellerProviderId;
            const { data } = await axios({
                url: `${apiEndpoint}/igm/countfilter`,
                data: {
                    provider_id: providerID,
                    status: optionvalue
                },
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    "Ens":`${fullUrl}`
                }
            });
            console.log("data", data);
            setIssuesList(data?.data?.reverse());
            // const isRootSeller = current_seller_id === 1 && decoded?.data?.user?.parent_id === 0;
            // const filteredData = isRootSeller ? data?.data : data?.data?.filter((item: any) => item.order_details.provider_id === sellerProviderId);
            // setSearchList(filteredData);
            if (current_seller_id === 1 && decoded?.data?.user?.parent_id === 0) {
                setSearchList(data?.data)
            } else {
                setSearchList(data?.data?.filter((item: any) => item.order_details.provider_id === sellerProviderId))
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }

    const filterBySearch = (event: any) => {
        const querySearch = event.target.value;
        let SearchFiltere = issuesList.filter((item: any) => {
            return (
                item?.issue_id.toLowerCase().includes(querySearch.toLowerCase()) ||
                item?.order_id.toLowerCase().includes(querySearch.toLowerCase())
            );
        });
        setSearchList(SearchFiltere);

    };


    const COLUMNS = [
        {
            Header: "Serial No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Ticket ID",
            accessor: (item: any) => (item?.issue_id),
            sticky: "left"
        },
        {
            Header: "Issues Type",
            accessor: (item: any) => (item?.issue_type),
            sticky: "left"
        },
        {
            Header: "Category",
            accessor: (item: any) => (item?.category),
            sticky: "left"
        },
        {
            Header: "Sub Category",
            accessor: (item: any) => (item?.sub_category),
            sticky: "left"
        },
        {
            Header: "Status",
            sticky: "left",
            accessor: (item: any) => (item?.status === "Closed" || item?.status === "CLOSED" ? (
                <span className="badge badge-success">Closed</span>
            ) : item?.status === "RESOLVED" ? (
                <span className="badge badge-success">Resolved</span>
            ) : item?.status === "ESCALATE" ? (
                <span className="badge badge-danger">Escalated</span>
            )
                : item?.status === "NEED-MORE-INFO" ? (
                    <span className="badge badge-warning">Need More Info</span>
                )
                    : (
                        <span className="badge badge-warning">Processing</span>
                    )
            ),
        },
        {
            Header: "Action",
            filterable: true,
            sticky: "left",
            Cell: ({ row }: any) => (<Link to={{ pathname: '/onissues', state: { tkt: row?.original.issue_id } }} className="badge badge-primary">View</Link>),
        },
    ];
    const getSellerList = async () => {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    "Ens":`${fullUrl}`
                }
            });
            if (data.status === 200) {
                // console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        var optionvalue = e.target.value;
        if (optionvalue === "") {
            fetchIssuesList();
        } else {
            let data = [...issuesList]
            setSearchList(data.filter((item: any) => item.order_details.provider_id == optionvalue));
            getcounts(optionvalue);
            setissellerselected(true)
        }
    };

    const handlerFilterOption = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const optionValue = e.target.value;
        optionValue === "" || optionValue === null ? fetchIssuesList() : getfilterOption(optionValue);
    };


    useEffect(() => {
        fetchIssuesList();
        getSellerList();
        getgraphData();
        if (current_seller_id == 1) {
            getcounts("");
        } else {
            getcounts(sellerProviderId);
        }


    }, []);

    const [counts, setcounts] = useState({
        Closed_Tickets: 0,
        Processing_Tickets: 0,
        Resolved_Tickets: 0,
        Total_tickets: 0,
        Seller_Closed_Tickets: 0,
        Seller_Processing_Tickets: 0,
        Seller_Resolved_Tickets: 0,
        Seller_Total_Tickets: 0
    })

    const [series, setSeries] = useState([
        {
            name: 'Open Tickets',
            data: [50, 11, 10, 40]
        },
        {
            name: 'Closed Tickets',
            data: [11, 30, 40, 76]
        }
    ]);
    const [pieChartData, setpieChartData] = useState({
        "resolved": 0,
        "open": 0,
        "closed": 0,
        // "processing": 0
    })
    const [options, setOptions] = useState<any>({
        data: [
            { asset: 'Resolved', amount: pieChartData.resolved },
            { asset: 'Open', amount: pieChartData.open },
            { asset: 'Closed', amount: pieChartData.closed },
            // { asset: 'Processing', amount: pieChartData.processing },

        ],
        width: 300,

        series: [
            {
                type: "pie",
                angleKey: "amount",
                calloutLabelKey: "asset",
                sectorLabelKey: "amount",
                sectorLabel: {
                    color: "white",
                    fontWeight: "bold",
                    formatter: ({ value }: any) => `${(value)}`,
                },
            },
        ],

    });

    async function getcounts(sellerProviderId: string) {
        let apiEndpoint = NodebaseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/igm/get_tickets_count`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    "Ens":`${fullUrl}`
                },
                data: { provider_id: sellerProviderId }

            });

            setcounts(data?.data);
            setpieChartData(data.data);
            setOptions((prev: any) => {
                return {
                    ...prev,
                    data: [
                        { asset: 'Resolved', amount: sellerProviderId === "" ? data.data.Resolved_Tickets : data.data.Seller_Resolved_Tickets },
                        { asset: 'Open', amount: sellerProviderId === "" ? data.data.Processing_Tickets : data.data.Seller_Processing_Tickets },
                        { asset: 'Closed', amount: sellerProviderId === "" ? data.data.Closed_Tickets : data.data.Seller_Closed_Tickets },
                        // { asset: 'Processing', amount: data.data.cancelled },

                    ]

                }
            })

        } catch (error) {
            console.log("error", error);
        }


    }

    console.log("options--------->", options)

    async function getgraphData() {
        let apiEndpoint = NodebaseUrlDefiner();
        let providerID;
        if (current_seller_id == 1) {
            providerID = ""
        } else {
            providerID = sellerProviderId
        }
        try {
            setIsGraphLoading(true)
            const { data } = await axios({
                url: `${apiEndpoint}/igm/get_ticketsby_date`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    "Ens":`${fullUrl}`
                },
                data: {
                    provider_id: providerID
                }
            });
            console.log(data);
            if (data?.data?.length >= 0) {
                let x = data?.data?.map((item: any) => item?.date);
                let openIssueCountArray = data?.data?.map((item: any) => item?.processingCount);
                let closedIssueCountArray = data?.data?.map((item: any) => item?.resolvedCount);
                let g = [...series];
                g[0] = { ...g[0], data: openIssueCountArray };
                g[1] = { ...g[1], data: closedIssueCountArray };

                if (x && x.length > 0) {
                    setissueDate(x);
                    setOptions((prev: any) => {
                        return { ...prev, xaxis: { ...prev.xaxis, categories: x } }
                    })

                    setSeries((prev: any) => {
                        return g
                    })
                }
            }

            setIsGraphLoading(false)
        } catch (error) {
            console.log("error", error);
            setIsGraphLoading(false)
        }


    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },

    }));




    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e2e2e2",
            color: "black",
            fontSize: 12,
            fontWeight: 600,

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
            fontWeight: 600,

        },
    }));

    function calculateAge(creationDateString: any, closedDateString: any) {
        var currentDate: any = new Date(); // Current date and time

        // Convert creation and closed dates to Date objects
        var creationDate: any = new Date(creationDateString);
        var closedDate: any = closedDateString ? new Date(closedDateString) : null;

        // Calculate age based on whether the item is closed or not
        var age;
        if (closedDate) {
            age = closedDate - creationDate; // Duration between creation and closure
        } else {
            age = currentDate - creationDate; // Duration between creation and current date
        }

        // Convert age from milliseconds to days (or hours, minutes, etc. as needed)
        age = age / (1000 * 60 * 60 * 24); // Convert milliseconds to days

        return age;
    }






    console.log("series------>", series);
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1" style={{}}>
                    <div className="col-md-12 p-2">
                        <div className="">
                            <div className={`d-flex  flex-md-row ${styles.main}`} style={{ gap: "30px" }}>
                                <div className={`p-3 rounded bg-white shadow border ${styles.ticketlist} `} >
                                    <div className="col-sm-12 col-md-12 d-flex align-items-center my-2 justify-content-between" >
                                        <div className=" col-sm-4 col-md-4 ticket_heading">
                                            <p className="genericlabel ps-1 m-0">Ticket List</p></div>
                                        <div className="col-sm-8 col-md-8 d-flex justify-content-end">
                                            <div className={`d-flex py-1 ${styles.search_filter}`}>
                                                <div className="">
                                                    <select name="" id="" className={`form-control ${styles?.fixed_width}`} onChange={(e: any) => handlerFilterOption(e)}>
                                                        <option value="" disabled selected>Search By</option>
                                                        <option value="" >Search All</option>
                                                        <option value="PROCESSING">Processing</option>
                                                        <option value="RESOLVED">Resolved</option>
                                                        <option value="CLOSED">Closed</option>
                                                    </select>
                                                </div>
                                                <div className="">
                                                    {current_seller_id === 1 ? (
                                                        <select name="" id="" className={`form-control ${styles?.fixed_width}`} onChange={(e: any) => inputHandler(e)}>
                                                            <option value="" disabled selected>Select Seller</option>
                                                            {sellerList.map((item: any) => (
                                                                <option value={item.provider_id}>{item?.company_name}</option>
                                                            ))}
                                                        </select>
                                                    ) : null}
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="search"
                                                        className={`form-control ${styles?.fixed_width}`}
                                                        placeholder="Enter Ticket ID"
                                                        onChange={filterBySearch}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: "70vh", overflow: "scroll" }}>
                                        {!isLoading ? <TableContainer component={Paper}   >
                                            <Table sx={{ minWidth: 500 }} aria-label="customized table" >
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Serial No.</StyledTableCell>
                                                        <StyledTableCell>Ticket ID</StyledTableCell>
                                                        <StyledTableCell>Order Id</StyledTableCell>
                                                        <StyledTableCell align="center">Status</StyledTableCell>
                                                        <StyledTableCell>Issue Created Date</StyledTableCell>
                                                        <StyledTableCell>Ageing</StyledTableCell>
                                                        <StyledTableCell align="center">Category</StyledTableCell>
                                                        <StyledTableCell align="center">Sub Category</StyledTableCell>
                                                        <StyledTableCell align="center">Closed Date</StyledTableCell>
                                                        <StyledTableCell align="center">Action</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {SearchList.length > 0 ? <>{SearchList.map((row: any, index: number) => {
                                                        const creationDate = row?.created_at;
                                                        const closedDate = row?.closed_time;
                                                        const age = calculateAge(creationDate, closedDate);
                                                        return <StyledTableRow key={row.issue_id}>
                                                            <StyledTableCell >
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell >
                                                                {row.issue_id}
                                                            </StyledTableCell>
                                                            <StyledTableCell >
                                                                {row?.order_id}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{row.status}</StyledTableCell>
                                                            <StyledTableCell >
                                                                {row.created_at ? moment(row.created_at).format("DD-MM-YYYY hh:mm:ss") : ""}
                                                            </StyledTableCell>
                                                            <StyledTableCell >
                                                                {Math.ceil(age)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{row.category}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.sub_category}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.closed_time ? moment(row.closed_time).format("DD-MM-YYYY hh:mm:ss") : ""}</StyledTableCell>
                                                            <StyledTableCell align="center"  > <Link to={{ pathname: '/onissues', state: { tkt: row?.issue_id } }} className=""> <img src={eye} alt="" width={25} height={25} style={{ cursor: "pointer" }} /> </Link></StyledTableCell>
                                                        </StyledTableRow>
                                                    })}</> : <p style={{ width: "200px" }}>No Tickets Yet!!</p>}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> : <div className="d-flex  justify-content-center align-items-center" style={{ height: "100%" }}><CircularProgress /></div>}
                                    </div>

                                </div>

                                <div className={`bg-white rounded shadow border p-3  d-flex flex-column ${styles.countmain}`}>

                                    <div className="d-flex justify-content-around my-1" style={{ width: "100%", flexWrap: "wrap", gap: "20px" }}>

                                        <div className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `} >

                                            <AssignmentTurnedInIcon style={{ color: "blue" }} />
                                            <p className={`mt-1 mb-1 ${styles.counti}`}>{(current_seller_id == 1 && !issellerSelected) ? counts?.Total_tickets : counts.Seller_Total_Tickets}</p>
                                            <p className="m-0 p-0" style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>Total Tickets</p>
                                        </div>

                                        <div className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}>
                                            <PublishedWithChangesIcon style={{ color: "red" }} />
                                            <p className={`mt-1 mb-1 ${styles.counti}`}>{(current_seller_id == 1 && !issellerSelected) ? counts.Processing_Tickets : counts?.Seller_Processing_Tickets}</p>
                                            <p className="m-0 p-0" style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>Open Tickets</p>
                                        </div>

                                        <div className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <p className={`mt-1 mb-1 ${styles.counti}`}>{(current_seller_id == 1 && !issellerSelected) ? counts?.Closed_Tickets : counts?.Seller_Closed_Tickets}</p>
                                            <p className="m-0 p-0" style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>Closed Tickets</p>
                                        </div>

                                        <div className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}>
                                            <HowToRegIcon style={{ color: "lightgreen" }} />
                                            <p className={`mt-1 mb-1 ${styles.counti}`}>{(current_seller_id == 1 && !issellerSelected) ? counts?.Resolved_Tickets : counts?.Seller_Resolved_Tickets}</p>
                                            <p className="m-0 p-0" style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}>Resolved Tickets</p>
                                        </div>
                                    </div>

                                    {options?.data.some((item: any) => item.amount !== 0) && <div className={` justify-content-center ${styles.piechart}`}>
                                        <AgChartsReact options={options} />
                                    </div>}

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default Issues;