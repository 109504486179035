// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_main__6Q6K0 {
    /* border: 2px solid grey; */
    padding: 3px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 4px 2px;
}

.Notification_main__6Q6K0 div:nth-child(1) {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15px;
}

.Notification_main__6Q6K0 div:nth-child(1) span {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: blue;
    color: white;
    text-align: center;
    padding-top: 3px;
   
}

.Notification_main__6Q6K0 div:nth-child(2) {
    width: 85%;
    color: white
}

.Notification_main__6Q6K0 div:nth-child(2) p:nth-child(1){
    font-weight: 600;
}
.Notification_main__6Q6K0 div:nth-child(2) p:nth-child(2){
    font-size: 13px;
}

.Notification_bgtype1__ZSGFZ{
    background-color: #006CD6    ;
}
.Notification_bgtype2__P0-Ne{
    background-color: #E85E5B
    ;
}
.Notification_bgtype3__yLeO9{
    background-color: #FF8139    ;
}
.Notification_bgtype4__dlKF5{
    background-color: #FFBA0C
    ;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,YAAY;IACZ,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,UAAU;IACV;AACJ;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;AACA;IACI;IACA;AACJ;AACA;IACI,6BAA6B;AACjC;AACA;IACI;IACA;AACJ","sourcesContent":[".main {\n    /* border: 2px solid grey; */\n    padding: 3px;\n    border-top-right-radius: 10px;\n    border-bottom-right-radius: 10px;\n    margin: 4px 2px;\n}\n\n.main div:nth-child(1) {\n    width: 15%;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    padding-top: 15px;\n}\n\n.main div:nth-child(1) span {\n    display: inline-block;\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    background-color: blue;\n    color: white;\n    text-align: center;\n    padding-top: 3px;\n   \n}\n\n.main div:nth-child(2) {\n    width: 85%;\n    color: white\n}\n\n.main div:nth-child(2) p:nth-child(1){\n    font-weight: 600;\n}\n.main div:nth-child(2) p:nth-child(2){\n    font-size: 13px;\n}\n\n.bgtype1{\n    background-color: #006CD6    ;\n}\n.bgtype2{\n    background-color: #E85E5B\n    ;\n}\n.bgtype3{\n    background-color: #FF8139    ;\n}\n.bgtype4{\n    background-color: #FFBA0C\n    ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Notification_main__6Q6K0`,
	"bgtype1": `Notification_bgtype1__ZSGFZ`,
	"bgtype2": `Notification_bgtype2__P0-Ne`,
	"bgtype3": `Notification_bgtype3__yLeO9`,
	"bgtype4": `Notification_bgtype4__dlKF5`
};
export default ___CSS_LOADER_EXPORT___;
