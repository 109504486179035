import React, { useState, useEffect } from 'react'
import ThemeMaker from '../../components/thememaker'
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import { baseUrlDefiner } from '../../utils/config';
import jwt_decode from "jwt-decode";
import { config } from '../../utils/config';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Cookies from 'js-cookie';
import { NodebaseUrlDefiner } from "../../utils/config";

interface City {
    id: number;
    city_name: string;
    city_code: string
}

export default function Serviceability() {
    let rawToken: any = sessionStorage.getItem("us_st_d");
    var decode: any = jwt_decode(rawToken!);
    // console.log(decode)
    const seller_provider_id = decode?.data?.seller_data?.provider_id;
    // const { baseNodeapiUrl,NodebaseUrlDefiner } = config;
    const [selectedCities, setSelectedCities] = useState<City[]>([]);
    const [allCities, setallcities] = React.useState<any[]>([]);
    const [serviceData, setservicedata] = useState({
        "seller_id": decode?.data?.seller_data?.provider_id,
        "service_type": "",
        "service_range": "",
        "service_city": ""
    })
    const [update, setshowUpdate] = useState(false)
    const handleAutocompleteChange = (
        event: React.ChangeEvent<{}>,
        newSelectedCities: City[]
    ) => {
        setSelectedCities(newSelectedCities); // Update the selectedCities state
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    useEffect(() => {
        getCities()

    }, [])
    useEffect(() => {
        getServiceAbilityData()
    }, [allCities])

    async function getCities() {
        let apiEndpoint =  NodebaseUrlDefiner();
        try {
            const { data } = await axios({
                url: "https://xpressbazarprod.hulsecure.in/public/index.php/api/getcity",
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
                }
            })

            if (data.status == 200) {
                setallcities(data.data)
                console.log(data.data)
            }
        } catch (err) {
            console.log(err)
        }


    }

    // console.log(serviceData)
    const ITEM_HEIGHT = 36;
    const ITEM_PADDING_TOP = 6;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    async function submitHandler(url: string) {
        let payload;
        let apiEndpoint =  NodebaseUrlDefiner();
        if (serviceData.service_type === "intercity") {
            let selectedCityCodes = selectedCities.map((item) => item.city_code)
            payload = {
                ...serviceData, service_city: selectedCityCodes.toString(), service_range: ""
            }
        } else if (serviceData.service_type === "pan-india") {
            payload = {
                ...serviceData, service_city: "", service_range: ""
            }
        } else {
            payload = {
                ...serviceData
            }
        }

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/admin/api/${url}`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: payload
            })
            if (data.message === "Serviceability inserted" || data.message === "Serviceability updated") {
                toast.success("Serviceability Updated")
            } else {
                toast.error("Error in data insertion")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went Wrong")
        }

    }

    function onChangeHandler(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) {

        setservicedata((prev) => {
            return {
                ...prev, [e.target.name]: e.target.value
            }
        })

    }

    async function getServiceAbilityData() {
        let apiEndpoint =  NodebaseUrlDefiner();
        const { data } = await axios({
            url: `${apiEndpoint}/admin/api/getserviceable`,
            method: "POST",
            headers: {
                "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
            },
            data: {
                "seller_id": seller_provider_id
            }

        })
        console.log(data.data)
        let serviceCities = data?.data?.service_city.split(",");
        if (data.data === null) {
            setshowUpdate(false)
        } else {
            setshowUpdate(true)
            if (data?.data?.service_city !== "") {

                let z: City[] = []
                if (allCities.length > 0) {
                    serviceCities.forEach((item: string) => {
                        let p: City[] = allCities.filter((city: City) => city.city_code === item)
                        z.push(p[0])

                    })

                    if (z.length > 0) {
                        setSelectedCities([...z])
                        setservicedata((prev) => {
                            return {
                                ...prev, service_type: data?.data?.service_type, service_range: data?.data?.service_range,
                                service_city: data?.data?.service_city
                            }
                        })
                    }
                }
            } else {
                setservicedata((prev) => {
                    return {
                        ...prev, service_type: data?.data?.service_type, service_range: data?.data?.service_range,
                        service_city: data?.data?.service_city
                    }
                })
            }
        }



    }


    return (
        <ThemeMaker>

            <div className="row w-100 m-0 mt-1" style={{ minHeight: "80vh" }}>
                <div className="col-md-12 ">
                    <div className="card shadow mb-4 h-100">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left">
                                Serviceability
                            </h6>
                        </div>




                        <div className="card-body" >
                            <div className="row col-md-12">
                                <div className='col-md-12'>
                                    <select name="service_type" id="" className='form-control col-md-6' value={serviceData.service_type} onChange={onChangeHandler}>
                                        <option value="">Select Serviceabilty</option>
                                        <option value="hyperlocal">Hyper Local</option>
                                        <option value="intercity">Intercity</option>
                                        <option value="pan-india">Pan India</option>
                                    </select>
                                </div>

                                {
                                    serviceData.service_type === "hyperlocal" ? <div className='p-3 mt-3 col-md-12'>
                                        <div className='col-md-7 row'>

                                            <div className='col-md-4 p-0 m-0 mt-1'>
                                                <label htmlFor=""> Area (in Km)</label>
                                                <input className='form-control col-md-8' type="text" name='service_range' value={serviceData.service_range} onChange={onChangeHandler} />

                                            </div>

                                            <div className='col-md-6 p-0 m-0 mt-1'>
                                                <label htmlFor="">City</label>
                                                <select name="service_city" value={serviceData.service_city} id="" className='form-control col-md-12' onChange={onChangeHandler}>
                                                    <option value="">Select City</option>
                                                    {
                                                        allCities.map((item) => {

                                                            return <option key={item.city_code} value={item.city_code}>{item.city_name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>


                                        </div>
                                    </div> : null
                                }
                                {
                                    serviceData.service_type === "intercity" ? <div className='p-3 mt-3 w-100'>
                                        <div style={{ maxWidth: "598px" }} >

                                            <label htmlFor="">Cities</label> <br />
                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-demo"
                                                options={allCities}
                                                disableCloseOnSelect
                                                getOptionLabel={(option: City) => option?.city_name}
                                                onChange={handleAutocompleteChange} // Handle changes and update state
                                                value={selectedCities} // Set the selected values
                                                renderOption={(props, option: City, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.city_name}
                                                    </li>
                                                )}
                                                style={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Cities" placeholder="Search" />
                                                )}
                                            />

                                        </div>
                                    </div> : null
                                }


                            </div>

                            {!update ?
                                <button className='btn btn-success mt-4 ml-3' onClick={() => submitHandler("addserviceable")}>Submit</button> : <button className='btn btn-success  mt-4 ml-3' onClick={() => submitHandler("updateserviceable")} >Update</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </ThemeMaker >
    )
}
