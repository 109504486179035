import ReactS3Client from 'react-aws-s3-typescript';
import { useState, useEffect, CSSProperties, useRef } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import { useCSVReader } from 'react-papaparse';
import { config } from "../../utils/config";
import btnstyle from "../../components/Buttons/Buttons.module.css"
import ExifReader from 'exifreader'; // Import ExifReader library
import { removeExifData } from '../NewmasterPage/helper';

const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;

const s3Config = {
  bucketName: 'bucket-name',
  dirName: 'directory-name',      /* Optional */
  region: 'ap-south-1',
  accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
  secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
  s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
const aws = require('aws-sdk');



// const s3 = new aws.S3({
//     region: "ap-south-1",
//     accessId: 'AKIAYJUL5VJOBYQMJDT7',
//     secretAccessKey: "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"
// });

// const uploadImage = async () => {
//     const params = {
//         Bucket: "ondc-marketplace",
//         key: "image-name",
//         Expires: 60
//     }
//    // const uploadUrl = await s3.getSignedUrlPromise

// }
const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {} as CSSProperties,
  acceptedFile: {
    border: "1px solid #ccc",
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "50%",
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: "0 20px",
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: "red",
  } as CSSProperties,
};
interface ICustomer {
  id: any;
}
const Addproduct = () => {
  const decode = tokenDecode();
  let current_sellerid = decode.data.user.seller_id

  console.log("current_sellerid from add product ---->", current_sellerid)
  const { CSVReader } = useCSVReader();
  let params: ICustomer = useParams();
  const { id } = params;
  console.log("params", params);

  const [pageNamem, setpageName] = useState(true);

  const [loading, setLoading] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [locatioList, setLocationList]: any = useState([]);

  const categoryListing = async () => {
    let apiEndpoint =  baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getcategorylist`,
        method: "GET",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
      });
      console.log("category listing", data);
      if (data.status === 200) {
        setCategoryList(data.data);
      }

    } catch (error) { }
  };

  
  const getLocation = async () => {
    let apiEndpoint =  baseUrlDefiner();
    try {
      console.log("yes");

      const data = Promise.all([
        await axios({
          url: `${apiEndpoint}/getlocation`,
          method: "POST",
          headers: {
            "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
          },
          data: {
            seller_id: decode?.data?.user?.seller_id,
          },
        }),
        await axios({
          url: `${apiEndpoint}/getinventory`,
          method: "POST",
          headers: {
            "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
          },
          data: {
            product_id: productData?.ondc_product_id,
          },
        }),
      ]);
      const [location, inventory] = await data;
      console.log("LocationList", location.data);
      console.log("InventoyList", inventory.data);
      const locationData = location.data.data.map((_item: any, index: any) => {
        const inve = inventory.data.data.filter(
          (item: any) =>
            item.location_id === _item.location_id && item.product_id == productData.ondc_product_id
        );
        //   console.log("inve --", inve);
        return {
          name: _item.location_name,
          location_id: _item?.location_id,
          product_id: productData?.ondc_product_id,
          quantity: inve.length >= 1 ? inve[0].quantity : 0,
          status: _item?.status,
        };
      });
      //  console.log("locationData", locationData);
      const whareHouse = {
        product_id: productData?.ondc_product_id,
        location: locationData,
      };
      console.log("whareHouse", whareHouse);

      // if (data1.status === 200) {
      // }
      setLocationList(whareHouse);
    } catch (error) { }
  };
  const setLocationValue = (e: any, index: any) => {
    let locationUp = locatioList.location;
    console.log("firts", locationUp);

    locationUp[index] = {
      ...locationUp[index],
      quantity: e.target.value,
    };
    console.log("sec", locationUp);

    console.log("s", locatioList);

    setLocationList({ ...locatioList, location: locationUp });
  };
  const rawData = {
    seller_id: decode?.data?.user?.seller_id,
    name: "",
    net_qty: "",
    pc_manufacturer_or_packer_name: "",
    quantity: "",
    short_description: "",
    description: "",
    category_id: "",
    mrp: 0,
    selling_price: 0,
    tax_included_price: 0,
    tax: 0,
    tags: "",
    image: "",
    sku: "",
    hsn: "",
    position: "",
    status: false,
    food_type_veg: "",
    returnable: "",
    cancelable: "",
    return_window: "",
    seller_pickup_return: "",
    time_to_ship: "",
    cod: "",
    customer_care_contact: "",
    product_type: "",
    preservatives: "",
    preservatives_details: "",
    flavours_and_spices: "",
    ready_to_cook: "",
    ready_to_eat: "",
    rice_grain_type: "",
    recommended_age: "",
    baby_weight: "",
    absorption_duration: "",
    scented_or_flavour: "",
    herbal_or_ayurvedic: "",
    theme_or_occasion_type: "",
    hair_type: "",
    mineral_source: "",
    caffeine_content: "",
    capacity: "",
    pf_brand_owner_FSSAI_license_no: "",
    tax_included: "",
    country_of_origin: "",
    uom: "",
    replaceable: "",
    // brand_name: ""

  };
  const rawDataError = {
    name: false,
    short_description: false,
    description: false,
    category_id: false,
    net_qty: false,
    pc_manufacturer_or_packer_name: false,
    quantity: false,
    mrp: false,
    selling_price: false,
    tax_included_price: false,
    tax: false,
    tags: false,
    image: false,
    customer_care_contact: false,
    sku: false,
    hsn: false,
    position: false,
    status: false,
    food_type_veg: false,
    returnable: false,
    cancelable: false,
    return_window: false,
    seller_pickup_return: false,
    time_to_ship: false,
    cod: false,
    product_type: false,
    preservatives: false,
    preservatives_details: false,
    flavours_and_spices: false,
    ready_to_cook: false,
    ready_to_eat: false,
    rice_grain_type: false,
    recommended_age: false,
    baby_weight: false,
    absorption_duration: false,
    scented_or_flavour: false,
    herbal_or_ayurvedic: false,
    theme_or_occasion_type: false,
    hair_type: false,
    mineral_source: false,
    caffeine_content: false,
    capacity: false,
    pf_brand_owner_FSSAI_license_no: false,
    tax_included: false,
    country_of_origin: false,
    uom: false,
    replaceable: false
    // brand_name: false

  };
  const [productData, setProductData]: any = useState(rawData);
  const [productDataError, setProductDataError]: any = useState(rawDataError);

  const errorHandler = (e: any) => {
    if (e.target.value) {
      setProductDataError({ ...productDataError, [e.target.name]: false });
    } else {
      setProductDataError({ ...productDataError, [e.target.name]: true });
    }
  }
  const [csvData, setCsvData] = useState([]);
  const [taxData, setTaxData] = useState([]);

  const taxListHanler = async () => {
    let apiEndpoint =  baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/gettaxlist`,
        method: "GET",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`
        }
      });
      console.log("tax listing", data);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) {
    }
  }
  const priceHandler = (e: any) => {
    console.log("e.target.name", e.target.name);
    if (e.target.name === "selling_price") {
      const percentAmmount =
        (parseFloat(productData.tax) / 100) * parseFloat(e.target.value);
      setProductData((preState: any) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: productData?.tax_included ? e.target.value : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4))

      }));
    } else if (e.target.name === "tax") {
      const percentAmmount =
        (parseFloat(e.target.value) / 100) * parseFloat(productData.mrp);
      setProductData((preState: any) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: productData?.tax_included ? productData?.tax_included_price :
          parseFloat(productData.mrp) +
          parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax_included") {
      const percentAmmount = (parseFloat(productData.tax) / 100) * parseFloat(productData.mrp);
      setProductData({
        ...productData,
        [e.target.name]: !productData?.tax_included,
        tax_included_price: !productData?.tax_included ? productData.mrp : percentAmmount + parseFloat(productData.mrp)

      });

    }
  };
  const inputHandler = (e: any) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };
  const getProduct = async () => {
    let apiEndpoint =  baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getproducts`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: { id },
      });
      console.log("getProduct >---------> ", data);
      if (data.status === 200) {
        setProductData(data.data);
      }
    } catch (error) { }
  };
  const createProductHandler = async () => {
    setLoading(true);
    let apiEndpoint =  baseUrlDefiner();
    const errorEmptyAlert = () => {
      toast.error("Please fill the required fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const {
      name,
      short_description,
      description,
      category_id,
      mrp,
      net_qty,
      pc_manufacturer_or_packer_name,
      quantity,
      selling_price,
      tax_included_price,
      tax,
      tags,
      image,
      sku,
      hsn,
      position,
      status,
      food_type_veg,
      returnable,
      cancelable,
      return_window,
      seller_pickup_return,
      time_to_ship,
      cod,
      product_type,
      pf_brand_owner_FSSAI_license_no,
      preservatives,
      preservatives_details,
      flavours_and_spices,
      ready_to_cook,
      ready_to_eat,
      rice_grain_type,
      recommended_age,
      baby_weight,
      absorption_duration,
      scented_or_flavour,
      herbal_or_ayurvedic,
      theme_or_occasion_type,
      hair_type,
      mineral_source,
      caffeine_content,
      capacity,
      tax_included,
      country_of_origin,
      uom,
      replaceable,
      customer_care_contact
    } = productData;
    console.log("productData ====, ", productData);
    let validChe = false;
    let validChe_2 = false;
    if (name && short_description && description && quantity && uom && sku && pc_manufacturer_or_packer_name && country_of_origin && customer_care_contact && net_qty && category_id && mrp && selling_price && tax && position && return_window && time_to_ship && product_type && image && status !== "" && returnable !== "" && cancelable !== "" && seller_pickup_return !== "" && cod !== "") {
      if (Number(category_id) === 25) {
        if (food_type_veg) {
          validChe_2 = true;
        } else {
          validChe_2 = false;

        }
      } else {
        validChe_2 = true;
      }

      if (product_type === "0") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "1") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
          // console.log("product_type = 1 false");

        }
      }
      if (product_type === "2") {
        if (pf_brand_owner_FSSAI_license_no && preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "3") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "4") {
        if (rice_grain_type && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "5") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "6") {
        if (scented_or_flavour) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "7") {
        if (recommended_age && capacity && mineral_source && caffeine_content && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "8") {
        if (scented_or_flavour && herbal_or_ayurvedic && hair_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "9") {
        if (pf_brand_owner_FSSAI_license_no && preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat && scented_or_flavour && theme_or_occasion_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "10") {
        if (preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "11") {
        if (recommended_age && baby_weight && absorption_duration && scented_or_flavour && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "12") {
        if (preservatives && preservatives_details && flavours_and_spices && ready_to_cook && ready_to_eat && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "14") {
        if (theme_or_occasion_type) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      console.log("data sen to db--", validChe, validChe_2)
      console.log("data sen to db--", productData)
      if (validChe && validChe_2) {
        try {
          const { data } = await axios({
            url: `${apiEndpoint}/addproductv`,
            method: "POST",
            headers: {
              "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
            },
            data: productData,
          });
          console.log("data from db--", data)
          if (data.status === 200) {
            setProductData(rawData);
            (document.getElementById("image") as HTMLInputElement).value = ""
            toast.success("New product created successfully !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("404 error !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error("404 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        errorEmptyAlert();
        console.log("from first")
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }
    } else {
      errorEmptyAlert();
      console.log("from second")
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }

  };
  const updateProductHanlder = async () => {
    console.log("productData", productData);
    let apiEndpoint =  baseUrlDefiner();
    const errorEmptyAlert = () => {
      toast.error("Please fill the required fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const {
      name,
      short_description,
      description,
      category_id,
      mrp,
      net_qty,
      pc_manufacturer_or_packer_name,
      quantity,
      selling_price,
      tax_included_price,
      tax,
      tags,
      image,
      sku,
      hsn,
      position,
      status,
      food_type_veg,
      returnable,
      cancelable,
      return_window,
      seller_pickup_return,
      time_to_ship,
      cod,
      product_type,
      pf_brand_owner_FSSAI_license_no,
      preservatives,
      preservatives_details,
      flavours_and_spices,
      ready_to_cook,
      ready_to_eat,
      rice_grain_type,
      recommended_age,
      baby_weight,
      absorption_duration,
      scented_or_flavour,
      herbal_or_ayurvedic,
      theme_or_occasion_type,
      hair_type,
      mineral_source,
      caffeine_content,
      capacity,
      tax_included,
      country_of_origin,
      uom,
      replaceable,
      customer_care_contact
    } = productData;
    console.log("datatosend ---->", productData)
    let validChe = false;
    let validChe_2 = false;
    if (name && short_description && description && category_id && mrp && selling_price && tax && position && return_window && time_to_ship && product_type && image && status !== "" && returnable !== "" && cancelable !== "" && seller_pickup_return !== "" && cod !== "") {
      if (Number(category_id) === 25) {
        if (food_type_veg !== "") {
          validChe_2 = true;
          console.log("heredddddddddddddddddddddddddddd");

        } else {
          validChe_2 = false;
          console.log("here");

        }
      } else {
        validChe_2 = true;
        console.log("4444444444444444444444444");

      }
      if (product_type === "0") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "1") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
          // console.log("product_type = 1 false");

        }
      }
      if (product_type === "2") {
        if (pf_brand_owner_FSSAI_license_no && preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "") {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      console.log("ddfadahd", validChe, pf_brand_owner_FSSAI_license_no, preservatives, preservatives_details, flavours_and_spices, ready_to_cook, ready_to_eat)
      if (product_type === "3") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "4") {
        if (rice_grain_type && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "5") {
        if (pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "6") {
        if (scented_or_flavour) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "7") {
        if (recommended_age && capacity && mineral_source && caffeine_content && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "8") {
        if (scented_or_flavour && herbal_or_ayurvedic && hair_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "9") {
        if (pf_brand_owner_FSSAI_license_no && preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "" && scented_or_flavour && theme_or_occasion_type && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "10") {
        if (preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "" && capacity) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "11") {
        if (recommended_age && baby_weight && absorption_duration && scented_or_flavour && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "12") {
        if (preservatives !== "" && preservatives_details && flavours_and_spices && ready_to_cook !== "" && ready_to_eat !== "" && pf_brand_owner_FSSAI_license_no) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      if (product_type === "14") {
        if (theme_or_occasion_type) {
          validChe = true;
        } else {
          validChe = false;
        }
      }
      console.log("valid check -----", validChe, validChe_2)
      if (validChe && validChe_2) {
        try {
          setUpLoading(true);
          const { data } = await axios({
            url: `${apiEndpoint}/updateproduct`,
            method: "post",
            headers: {
              "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
            },
            data: productData,
          });
          setUpLoading(false);
          if (data.status === 200) {
            toast.success("Product updated successfully !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("404 error !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          setUpLoading(false);
          toast.error("404 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        errorEmptyAlert();
      }
    } else {
      console.log("herre");

      errorEmptyAlert();
    }

  };
 
  const productImageHandler = async (e : any) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];

      // Remove the Exif metadata using the custom function
      const modifiedArrayBuffer = await removeExifData(file);
    // Convert the modified data back to a Blob
      const strippedBlob = new Blob([modifiedArrayBuffer], { type: file.type });

      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
        region: awsRegion
      });

      const params = {
        Bucket: "ondc-marketplace",
        Key: `images/${uuidv4()}.${myFileType}`,
        Body: strippedBlob, // Use the stripped file
        ContentType: file?.type
      };

      let result = await s3.upload(params).promise();
      const { Location } = result;

      let c = { ...productData };
      c.image.push(Location);

      setProductData({ ...c });
    } catch (exception) {
      console.error('Error during image upload:', exception);
    }
  };
 
  const importCsvHanlder = async () => {
    // console.log("ssss", locatioList);
    let apiEndpoint =  baseUrlDefiner();
    try {
      setLoading(true);
      const { data } = await axios({
        url: `${apiEndpoint}/updateinventory`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: locatioList,
      });
      setLoading(false);
      if (data.status === 200) {
        toast.success("Inventory updated successfully !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };





  // Function to strip Exif metadata from the file
  const stripExif = async (file: any, tags: any) => {
    const blob = new Blob([file]);
    const strippedFile = new File([blob], file.name, { type: file.type });
    return strippedFile;
  };
  const productTypeHandler = (e: any) => {
    if (e.target.value === "0") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: ""
      });
    } else if (e.target.value === "1") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: ""
      });
    } else if (e.target.value === "2") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: "",
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
      });
    }
    else if (e.target.value === "3") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: "",
      });
    }
    else if (e.target.value === "4") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        rice_grain_type: "",
        pf_brand_owner_FSSAI_license_no: "",
      });
    }
    else if (e.target.value === "5") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "6") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        scented_or_flavour: ""
      });
    }
    else if (e.target.value === "7") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        recommended_age: "",
        capacity: "",
        mineral_source: "",
        caffeine_content: "",
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "8") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        scented_or_flavour: "",
        herbal_or_ayurvedic: "",
        hair_type: "",
        capacity: "",

      });
    }
    else if (e.target.value === "9") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
        scented_or_flavour: "",
        theme_or_occasion_type: "",
        capacity: "",
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "10") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
        scented_or_flavour: "",
        capacity: ""
      });
    }
    else if (e.target.value === "11") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        recommended_age: "",
        baby_weight: "",
        absorption_duration: "",
        scented_or_flavour: "",
        pf_brand_owner_FSSAI_license_no: ""
      });
    }
    else if (e.target.value === "12") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        preservatives: "",
        preservatives_details: "",
        flavours_and_spices: "",
        ready_to_cook: "",
        ready_to_eat: "",
      });
    }
    else if (e.target.value === "13") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,

      });
    }
    else if (e.target.value === "14") {
      setProductData({
        ...productData, [e.target.name]: e.target.value,
        theme_or_occasion_type: ""
      });
    }

    // setProductData({ ...productData, [e.target.name]: e.target.value })
  }
  const caregoryHandler = (e: any) => {
    if (Number(e.taget.value) !== 25) {
      //  setProductData({ ...productData, [e.target.name]: e.target.value });
    }
  }
  useEffect(() => {
    categoryListing();
    taxListHanler();
    if (id) {
      setpageName(false);
      getProduct();

      // setProductData(JSON.parse(user))
    } else {
      setpageName(true);
    }
  }, []);
  const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  {pageNamem ? "Create product" : "Product Details"}
                </h6>
                {/* {!pageNamem ? (
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={getLocation}
                  >
                    {" "}
                    Update Inventory{" "}
                  </button>
                ) : null} */}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Name</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.name ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter title"
                        value={productData.name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Position</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.position ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="number"
                        className="form-control"
                        name="position"
                        placeholder="Position"
                        value={productData.position}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Product status</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.status ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.status}
                        name="status"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      >
                        <option value="">Select product status</option>
                        <option value="true">Active</option>
                        <option value="false">Draft</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Country Origin</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.country_of_origin ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="text"
                        value={productData.country_of_origin}
                        className="form-control"
                        name="country_of_origin"
                        placeholder="Country Origin"
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Category</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.category_id ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.category_id}
                        name="category_id"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value=""> Select category</option>
                        {categoryList
                          .filter((categoryList: any) => {
                            if (categoryList.status) {
                              return categoryList;
                            }
                          })
                          .map((item: any) => (
                            <option value={item?.id}>
                              {item?.category_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {
                    Number(productData?.category_id) === 25 ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Food type</label>
                          <span className="text-danger" > * </span>

                          {
                            productDataError?.food_type_veg ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select
                            className="form-control"
                            value={productData.food_type_veg}
                            name="food_type_veg"
                            id=""
                            onChange={(e) => inputHandler(e)}
                            onBlur={(e) => errorHandler(e)}

                          >
                            <option value="">Select food type</option>
                            <option value="true">Veg</option>
                            <option value="false">Non veg</option>
                          </select>
                        </div>
                      </div>
                    ) : null
                  }

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Short description</label>
                      <span className="text-danger" > * </span>

                      {
                        productDataError?.short_description ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <textarea
                        className="form-control"
                        name="short_description"
                        placeholder="Short description"
                        value={productData.short_description}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Long description</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.description ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <textarea
                        className="form-control"
                        name="description"
                        placeholder="Long description"
                        value={productData.description}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Selling price
                        <span className="text-danger" > * </span>

                        <input type="checkbox" name="tax_included" style={{ marginLeft: "20px" }}
                          onChange={(e: any) => priceHandler(e)}
                          checked={productData?.tax_included}
                        /> Tax included
                      </label>
                      {
                        productDataError?.selling_price ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="number"
                        className="form-control"
                        name="selling_price"
                        placeholder="Selling price"
                        value={productData.selling_price}
                        onChange={(e) => priceHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Max price</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.mrp ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="number"
                        className="form-control"
                        name="mrp"
                        placeholder="Max price"
                        value={productData.mrp}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tax included price</label>
                      <input
                        type="number"
                        className="form-control"
                        disabled
                        name="tax_included_price"
                        placeholder=""
                        value={productData.tax_included_price}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tax ( % ) </label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.tax ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }

                      <select
                        className="form-control"
                        name="tax"
                        placeholder="Tax"
                        value={productData.tax}
                        onChange={(e) => priceHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      >
                        <option value="">Select tax</option>
                        {
                          taxData.map((item: any) => (
                            <option value={item?.tax_rate}>{item?.tax_rate}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Net Quantity</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.net_qty ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="number"
                        className="form-control"
                        name="net_qty"
                        placeholder="Net Quantity"
                        value={productData.net_qty}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Manufacturer or Packer Name</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.pc_manufacturer_or_packer_name ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="text"
                        className="form-control"
                        name="pc_manufacturer_or_packer_name"
                        placeholder="Manufacturer Name"
                        value={productData.pc_manufacturer_or_packer_name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Quantity</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.quantity ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        placeholder="Quantity"
                        value={productData.quantity}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tags</label>
                      <input
                        type="text"
                        className="form-control"
                        name="tags"
                        placeholder="Enter tags"
                        value={productData.tags}
                        onChange={(e) => inputHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Sku</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.sku ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="text"
                        className="form-control"
                        name="sku"
                        placeholder="Enter sku"
                        value={productData.sku}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Hsn</label>
                      <input
                        type="text"
                        className="form-control"
                        name="hsn"
                        placeholder="Enter hsn"
                        value={productData.hsn}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>UOM</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.uom ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="text"
                        className="form-control"
                        name="uom"
                        placeholder="Enter uom"
                        value={productData.uom}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Images</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.image ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      {productData.image && <a href={productData.image} target="_blank" style={{ fontSize: "10px", marginLeft: "3px", textDecoration: "underline" }}>View Document</a>}
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => productImageHandler(e)}
                        accept="image/*"
                        name="image"
                        multiple
                        onBlur={(e) => errorHandler(e)}
                        id="image"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Product Type</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.product_type ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select name="product_type" id="" className="form-control"
                        value={productData.product_type}
                        onChange={(e: any) => productTypeHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Select your product type</option>
                        {/* <option value="0">Fruits and Vegetables</option> */}
                        <option value="1">Masala and Seasoning</option>
                        <option value="2">Oil & Ghee</option>
                        <option value="3">Gourmet & World Foods</option>
                        <option value="4">Foodgrains</option>
                        <option value="5">Eggs, Meat & Fish</option>
                        <option value="6">Cleaning & Household</option>
                        <option value="7">Beverages</option>
                        <option value="8">Beauty & Hygiene</option>
                        <option value="9">Bakery, Cakes & Dairy </option>
                        <option value="10">Kitchen Accessories</option>
                        <option value="11">Baby Care</option>
                        <option value="12">Snacks & Branded Foods</option>
                        <option value="13">Pet Care</option>
                        <option value="14">Stationery</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {/* {
                      productData.product_type === "0" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Fruits and Vegetables</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FSSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    } */}
                    {
                      productData.product_type === "1" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Masala and Seasoning</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "2" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Oil & Ghee</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.preservatives}
                                name="preservatives"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Preservatives</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives Details</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives_details ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="preservatives_details"
                                placeholder="Enter preservatives details"
                                value={productData.preservatives_details}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Flavours and Spices</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.flavours_and_spices ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="flavours_and_spices"
                                placeholder="Enter Flavour & Spices"
                                value={productData.flavours_and_spices}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to cook</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_cook ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_cook}
                                name="ready_to_cook"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Cook</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to eat</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_eat ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_eat}
                                name="ready_to_eat"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Eat</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "3" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Gourmet and World Foods</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "4" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Foodgrains</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Rice Grain Type</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.rice_grain_type ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="rice_grain_type"
                                placeholder="Enter Rice Grain Type"
                                value={productData.rice_grain_type}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "5" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Eggs, Meat & Fish</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "6" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Cleaning & Household</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Scented or Flavour</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.scented_or_flavour ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="scented_or_flavour"
                                placeholder="Enter Scented/Flavour"
                                value={productData.scented_or_flavour}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "7" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Beverages</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Recommended Age</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.recommended_age ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="recommended_age"
                                placeholder="Enter Age No."
                                value={productData.recommended_age}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Capacity</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.capacity ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="capacity"
                                placeholder="Enter capacity"
                                value={productData.capacity}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Mineral Source</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.mineral_source ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="mineral_source"
                                placeholder="Enter Mineral Source"
                                value={productData.mineral_source}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Caffeine Content</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.caffeine_content ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="caffeine_content"
                                placeholder="Enter Caffeine Content"
                                value={productData.caffeine_content}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No"
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "8" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Beauty & Hygiene</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Scented or Flavour</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.scented_or_flavour ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="scented_or_flavour"
                                placeholder="Enter Scented or Flavour"
                                value={productData.scented_or_flavour}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Herbal or Ayurvedic</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.herbal_or_ayurvedic ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="herbal_or_ayurvedic"
                                placeholder="Enter Herbal or Ayurvedic"
                                value={productData.herbal_or_ayurvedic}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Hair Type</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.hair_type ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="hair_type"
                                placeholder="Enter Hair Type"
                                value={productData.hair_type}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Capacity</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.capacity ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="capacity"
                                placeholder="Enter Capacity"
                                value={productData.capacity}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "9" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Bakery, Cakes and Dairy</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.preservatives}
                                name="preservatives"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Preservatives</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives Details</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives_details ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="preservatives_details"
                                placeholder="Enter Preservatives Details"
                                value={productData.preservatives_details}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Flavours & Spices</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.flavours_and_spices ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="flavours_and_spices"
                                placeholder="Enter Flavours & Spices"
                                value={productData.flavours_and_spices}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to cook</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_cook ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_cook}
                                name="ready_to_cook"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Cook</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to Eat</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_eat ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_eat}
                                name="ready_to_eat"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Eat</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Scented or Flavour</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.scented_or_flavour ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="scented_or_flavour"
                                placeholder="Enter Scented or Flavour"
                                value={productData.scented_or_flavour}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Theme or Occasion Type</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.theme_or_occasion_type ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="theme_or_occasion_type"
                                placeholder="Enter Theme or Occasion Type"
                                value={productData.theme_or_occasion_type}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Capacity</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.capacity ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="capacity"
                                placeholder="Enter Capacity"
                                value={productData.capacity}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No"
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "10" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Kitchen Accessories</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.preservatives}
                                name="preservatives"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Preservatives</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives Details</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives_details ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="preservatives_details"
                                placeholder="Enter Preservatives Details"
                                value={productData.preservatives_details}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Flavours & Spices</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.flavours_and_spices ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="flavours_and_spices"
                                placeholder="Enter Flavours & Spices"
                                value={productData.flavours_and_spices}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Capacity</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.capacity ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="capacity"
                                placeholder="Enter Capacity"
                                value={productData.capacity}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to cook</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_cook ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_cook}
                                name="ready_to_cook"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Cook</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to Eat</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_eat ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_eat}
                                name="ready_to_eat"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Eat</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>



                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "11" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Baby Care</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Recommended Age</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.recommended_age ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="recommended_age"
                                placeholder="Enter Recommended Age"
                                value={productData.recommended_age}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Baby Weight</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.baby_weight ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="baby_weight"
                                placeholder="Enter Weight Details"
                                value={productData.baby_weight}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Absorption Duration (in Hrs)</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.absorption_duration ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="absorption_duration"
                                placeholder="Enter Absorption Duration "
                                value={productData.absorption_duration}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Scented or Flavour</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.scented_or_flavour ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="scented_or_flavour"
                                placeholder="Enter Scented or Flavour"
                                value={productData.scented_or_flavour}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No"
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>




                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "12" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Snacks & Branded Foods</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.preservatives}
                                name="preservatives"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Preservatives</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Preservatives Details</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.preservatives_details ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="preservatives_details"
                                placeholder="Enter Preservatives Details"
                                value={productData.preservatives_details}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Flavours & Spices</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.flavours_and_spices ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="flavours_and_spices"
                                placeholder="Enter Flavours & Spices"
                                value={productData.flavours_and_spices}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to cook</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_cook ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_cook}
                                name="ready_to_cook"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Cook</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Ready to Eat</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.ready_to_eat ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <select
                                className="form-control"
                                value={productData.ready_to_eat}
                                name="ready_to_eat"
                                id=""
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              >
                                <option value="">Select Ready to Eat</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>FASSAI No.</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.pf_brand_owner_FSSAI_license_no ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="pf_brand_owner_FSSAI_license_no"
                                placeholder="Enter FASSAI No."
                                value={productData.pf_brand_owner_FSSAI_license_no}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>




                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "13" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Pet Care</p>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      productData.product_type === "14" ? (
                        <div className="row">
                          <div className="col-md-12">
                            <p className='bold_mak' >Stationery</p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Theme or Occasion Type</label>
                              <span className="text-danger" > * </span>
                              {
                                productDataError?.theme_or_occasion_type ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                              }
                              <input
                                type="text"
                                className="form-control"
                                name="theme_or_occasion_type"
                                placeholder="Enter Theme or Occasion Type"
                                value={productData.theme_or_occasion_type}
                                onChange={(e) => inputHandler(e)}
                                onBlur={(e) => errorHandler(e)}

                              />
                            </div>
                          </div>
                        </div>
                      ) : null
                    }

                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Product returnable</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.returnable ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.returnable}
                        name="returnable"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Select returnable</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Product replaceable</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.replaceable ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.replaceable}
                        name="replaceable"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Select replaceable</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Customer Care Contact </label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.customer_care_contact ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <input
                        type="text"
                        className="form-control"
                        name="customer_care_contact"
                        placeholder="Customer care contact"
                        value={productData.customer_care_contact}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      />


                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Product cancelable</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.cancelable ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.cancelable}
                        name="cancelable"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Select cancelable</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Return window</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.return_window ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.return_window}
                        name="return_window"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Select return window</option>
                        {returnWindowLength.map((item: any) => (
                          <option value={`P${item}D`}>{`P${item}D`}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Seller pickup return</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.seller_pickup_return ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.seller_pickup_return}
                        name="seller_pickup_return"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Select seller return pickup</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Time to ship</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.time_to_ship ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.time_to_ship}
                        name="time_to_ship"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Time to Ship</option>
                        {timeToShipLength.map((item: any) => (
                          <option value={`P${item}D`}>{`P${item}D`}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cash on delivery</label>
                      <span className="text-danger" > * </span>
                      {
                        productDataError?.cod ? (
                          <span className="text-danger tiny_size" > This field is required ! </span>
                        ) : null
                      }
                      <select
                        className="form-control"
                        value={productData.cod}
                        name="cod"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}

                      >
                        <option value="">Select cash on delivery</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    {pageNamem ? (
                      <button
                        type="submit"
                        className={btnstyle.button}
                        onClick={createProductHandler}
                      >
                        {loading ? "Loading..." : "Create"}
                      </button>
                    ) : (current_sellerid == 1) ? <button
                      type="submit"
                      className="btn btn-success"
                      onClick={updateProductHanlder}
                    >
                      {uploading ? "Loading..." : "Update"}
                    </button> : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center"></h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-10">
                    <p> Location Name </p>
                  </div>
                  <div className="col-md-2">
                    <p> Quantity </p>
                  </div>
                  {locatioList?.location?.map((item: any, index: any) => (
                    <>
                      <div className="col-md-10">
                        <p>
                          {" "}
                          {index + 1}. {item?.name}{" "}
                        </p>
                      </div>
                      <div className="col-md-2">
                        <input
                          type="number"
                          value={item?.quantity}
                          className="form-control"
                          onChange={(e) => setLocationValue(e, index)}
                        />
                      </div>

                    </>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={importCsvHanlder}
                >
                  {loading ? "updating..." : "Update"}
                </button>

                <button
                  type="button"
                  id="closebtn"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default Addproduct;
