// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectboxwidth{
    width: 200px;
}

@media screen and (max-width: 480px) {
    .selectboxwidth{
        width: 291px;
    }

    .widthControl{
        width: 100%;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/pages/unsettledTransaction/unsettledTransaction.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,WAAW;IACf;;AAEJ","sourcesContent":[".selectboxwidth{\n    width: 200px;\n}\n\n@media screen and (max-width: 480px) {\n    .selectboxwidth{\n        width: 291px;\n    }\n\n    .widthControl{\n        width: 100%;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
