// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input-container {
    position: relative;
    display: inline-block;
  }
  
  .custom-file-input-button {
    display: inline-block;
    padding: 5px 17px;
    background-color: #007bff; /* Your desired button color */
    color: #fff; /* Button text color */
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  /* Style the label like a button */
  .custom-file-input-button:hover {
    background-color: #0056b3; /* Button color on hover */
  }
  
  /* Hide the file input */
 .input_file {
    display: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ImageAdd/ImageAdd.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;IACjB,yBAAyB,EAAE,8BAA8B;IACzD,WAAW,EAAE,sBAAsB;IACnC,eAAe;IACf,YAAY;IACZ,kBAAkB;EACpB;;EAEA,kCAAkC;EAClC;IACE,yBAAyB,EAAE,0BAA0B;EACvD;;EAEA,wBAAwB;CACzB;IACG,aAAa;EACf","sourcesContent":[".file-input-container {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .custom-file-input-button {\n    display: inline-block;\n    padding: 5px 17px;\n    background-color: #007bff; /* Your desired button color */\n    color: #fff; /* Button text color */\n    cursor: pointer;\n    border: none;\n    border-radius: 5px;\n  }\n  \n  /* Style the label like a button */\n  .custom-file-input-button:hover {\n    background-color: #0056b3; /* Button color on hover */\n  }\n  \n  /* Hide the file input */\n .input_file {\n    display: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
