// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderdetails{
    border: 1px solid lightslategray;
    box-shadow: 2px 2px ;
    width: 82%;
    padding: 5px 20px;
    border-radius: 10px;
    width: 90%;
    box-shadow: -5px 7px 12px -7px rgba(0,0,0,0.75);

}

.orderdetails label{
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.orderdetails span{
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/pages/PartialOrder/order.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oBAAoB;IACpB,UAAU;IACV,iBAAiB;IACjB,mBAAmB;IACnB,UAAU;IACV,+CAA+C;;AAEnD;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".orderdetails{\n    border: 1px solid lightslategray;\n    box-shadow: 2px 2px ;\n    width: 82%;\n    padding: 5px 20px;\n    border-radius: 10px;\n    width: 90%;\n    box-shadow: -5px 7px 12px -7px rgba(0,0,0,0.75);\n\n}\n\n.orderdetails label{\n    font-size: 14px;\n    font-weight: 500;\n    color: black;\n}\n\n.orderdetails span{\n    text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
