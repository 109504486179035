// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Variant_doneButton__YTxAh {
    background: #105293;
    color: white;
    width: 100px;
}

.Variant_tablecellBox__1z2-j{
    
    width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/Variants/Variant.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;;IAEI,YAAY;AAChB","sourcesContent":[".doneButton {\n    background: #105293;\n    color: white;\n    width: 100px;\n}\n\n.tablecellBox{\n    \n    width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doneButton": `Variant_doneButton__YTxAh`,
	"tablecellBox": `Variant_tablecellBox__1z2-j`
};
export default ___CSS_LOADER_EXPORT___;
