// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `   table {
     font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
     border-collapse: collapse;
     width: 100%;
   }

   table td,
   table th {
     border: 1px solid #ddd;
     padding: 8px;
     font-size: 15px;
     /* width: 100px!important;
    max-width: 100px; */
     text-align: center;
   }

   table tr:nth-child(even) {
     background-color: #f2f2f2;
   }

   table tr:hover {
     background-color: #ddd;
   }

   thead {
     white-space: nowrap;
   }

   table th,
   tfoot td {
     padding-top: 12px !important;
     padding-bottom: 12px !important;
     text-align: center;
     background: #001ad5;
     color: white;
   }

   .page_link_item {
     position: relative;
     display: block;
     padding: 0.5rem 0.75rem;
     margin-left: -1px;
     line-height: 1.25;
     border: 1px solid #dddfeb;
   }`, "",{"version":3,"sources":["webpack://./src/components/table.css"],"names":[],"mappings":"GAAG;KACE,yDAAyD;KACzD,yBAAyB;KACzB,WAAW;GACb;;GAEA;;KAEE,sBAAsB;KACtB,YAAY;KACZ,eAAe;KACf;uBACkB;KAClB,kBAAkB;GACpB;;GAEA;KACE,yBAAyB;GAC3B;;GAEA;KACE,sBAAsB;GACxB;;GAEA;KACE,mBAAmB;GACrB;;GAEA;;KAEE,4BAA4B;KAC5B,+BAA+B;KAC/B,kBAAkB;KAClB,mBAAmB;KACnB,YAAY;GACd;;GAEA;KACE,kBAAkB;KAClB,cAAc;KACd,uBAAuB;KACvB,iBAAiB;KACjB,iBAAiB;KACjB,yBAAyB;GAC3B","sourcesContent":["   table {\n     font-family: \"Trebuchet MS\", Arial, Helvetica, sans-serif;\n     border-collapse: collapse;\n     width: 100%;\n   }\n\n   table td,\n   table th {\n     border: 1px solid #ddd;\n     padding: 8px;\n     font-size: 15px;\n     /* width: 100px!important;\n    max-width: 100px; */\n     text-align: center;\n   }\n\n   table tr:nth-child(even) {\n     background-color: #f2f2f2;\n   }\n\n   table tr:hover {\n     background-color: #ddd;\n   }\n\n   thead {\n     white-space: nowrap;\n   }\n\n   table th,\n   tfoot td {\n     padding-top: 12px !important;\n     padding-bottom: 12px !important;\n     text-align: center;\n     background: #001ad5;\n     color: white;\n   }\n\n   .page_link_item {\n     position: relative;\n     display: block;\n     padding: 0.5rem 0.75rem;\n     margin-left: -1px;\n     line-height: 1.25;\n     border: 1px solid #dddfeb;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
