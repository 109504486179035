import { useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseUrlDefiner } from '../../utils/config';
import { config } from '../../utils/config';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

export default function UpdatePassword() {
    const { apiEndpoint } = config;
    const rawData = {
        newPassword: "",
        oldPassword: "",
        confirmPassword: ""
    }
    const history = useHistory()
    const [passwordData, setpasswordData] = useState(rawData);
    const [errorShow, seterrorShow] = useState(false)
    function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        const { newPassword, confirmPassword } = passwordData;
        setpasswordData((prev) => {
            return {
                ...prev, [e.target.name]: e.target.value
            }

        })

        if (e.target.name == "confirmPassword") {
            if (e.target.value !== newPassword) {
                seterrorShow(true)
            } else {
                seterrorShow(false)
            }
        }
    }

    async function PasswordHandler() {
        let apiEndpoint =  baseUrlDefiner();
        const { newPassword, confirmPassword } = passwordData;
        if (!errorShow && newPassword !== "" && confirmPassword !== "") {
            const data = await axios({
                url: `${apiEndpoint}/updatepassword`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: { password: passwordData?.confirmPassword, old_password: passwordData?.oldPassword }
            })
            console.log(data)
            if (data.data.status == 200) {
                toast.success("Password Updated");

                setTimeout(() => {
                    sessionStorage.removeItem("us_st_d")
                    history.push("/login")
                }, 3000)
            } 
            if(data.data.status == 403) {
                toast.error("New Password and Old Password can't be same!")
            }
            if(data.data.status == 405) {
                toast.error("Old Password is incorrect")
            }
        } else {
            toast.error("Please Enter Fileds Correctly!")
        }

    }

    // console.log(passwordData)
    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">
                                Update Password
                            </h6>


                        </div>

                        <div className="card-body" style={{ minHeight: "80vh" }}>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Old Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="oldPassword"
                                            placeholder="Enter Old Password"

                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="newPassword"
                                            placeholder="Enter New Password"


                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm New Password</label>


                                        <input
                                            type="password"
                                            className="form-control"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            onChange={onChangeHandler}
                                        />

                                        {
                                            errorShow ? (
                                                <span className="text-danger tiny_size" > Password Mismatch ! </span>
                                            ) : null
                                        }
                                    </div>
                                </div>

                                                

                                <div className="col-md-12">

                                    <button className='btn btn-primary' onClick={PasswordHandler}>Update </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ThemeMaker >
    )
}
