import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import axios from "axios";
import { baseUrlDefiner } from '../../utils/config';
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ThemeMaker from '../../components/thememaker';
import { Link } from 'react-router-dom';
import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import { show, hide } from "react-functional-modal"
import styled from "styled-components"
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { removeExifData } from '../NewmasterPage/helper';
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket  } = config
const s3Config = {
    bucketName: 'bucket-name',
    dirName: 'directory-name',      /* Optional */
    region: 'ap-south-1',
    accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
    secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
    s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
const aws = require('aws-sdk');

const ImageContainer = styled.div
    `
 border: 1px solid black;
    border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

    
    img{
      width: 100%!important;
    height: 80%;
    object-fit: contain;
  }

  button{
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
    `
export default function Personaldetails(props) {

    let rawToken = sessionStorage.getItem("us_st_d");
    var decode = jwt_decode(rawToken);
    // console.log("decode-->", decode)
    let decodedUserdata = decode.data;


    const param = useParams()
    const id = param.id
    // console.log("id--> ", id)
    const rawData = {
        id: id,
        pan_name: '',
        pan_image: '',
        street: '',
        city: decodedUserdata.seller_data.city,
        area_code: '',
        state: '',
        longitude: "",
        latitude: "",
        personal_address: "",
        address_proof_type: "",
        address_proof_image: "",
        // description: "Shikhar Seller App",
        // short_description: "Shikhar Seller App",
        radius: "",
        building: "",
        locality: ""

    }
    const rawErrorData = {
        pan_name: false,
        pan_image: false,
        street: false,
        city: false,
        area_code: false,
        state: false,
        longitude: false,
        latitude: false,
        personal_address: false,
        address_proof_type: false,
        address_proof_image: false,
        radius: false,
        building: false,
        locality: false

    }
    const [personalData, setpersonalData] = useState(rawData);
    const [personalDataError, setpersonalDataError] = useState(rawErrorData);


    const renderAfterCalled = useRef(false);
    if (!renderAfterCalled.current) {
        givedta()
    }


    async function givedta() {
        let apiEndpoint =  baseUrlDefiner();
        const { data } = await axios({
            url: `${apiEndpoint}/getbankdetails`,
            method: "POST",
            headers: {
                "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
            },
            data: {
                seller_id: id
            }
        });

        if (data.status === 200) {
            // setpersonalData(data.data[0])
            let { id, pan_name, pan_image, street, city, area_code,
                state, longitude, latitude, personal_address, address_proof_type, address_proof_image,locality, radius } = data?.data[0]
            // console.log(data.data[0])
            setpersonalData({
                id, pan_name, pan_image, street, city, area_code, state, longitude, latitude, personal_address, address_proof_type, address_proof_image,locality, radius
            })
            renderAfterCalled.current = true;
        }

    }
    const inputHandler = (e) => {
        setpersonalData({ ...personalData, [e.target.name]: e.target.value });
    }
    const errorHandler = (e) => {
        if (e.target.value) {
            setpersonalDataError({ ...personalDataError, [e.target.name]: false });
        } else {
            setpersonalDataError({ ...personalDataError, [e.target.name]: true });
        }
    }

    const onSubmitHandler = async () => {
        let apiEndpoint =  baseUrlDefiner();
        // console.log("personalData => ", personalData);
        // console.log("personalDataError => ", personalDataError);
        const { id,
            pan_name,
            pan_image,
            street,
            city,
            area_code,
            state,
            longitude,
            latitude,
            personal_address,
            address_proof_type,
            address_proof_image,
            short_description,
            description,
            radius, locality, building
        } = personalData;
        if (pan_name && pan_image && personal_address && address_proof_type && address_proof_image && state && city && longitude && latitude && area_code && street  && locality) {
            // dispatch(registerSeller(personalData));
            // console.log(">>>>>>>>>>>>", personalData)
            try {
                const { data } = await axios({
                    url: `${apiEndpoint}/personaldetails`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: personalData

                })

                if (data.status === 200) {
                    toast.success("Details submitted Successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                // console.log("data from server---", data)
                // console.log("data from server---", personalData)

            } catch (err) {
                console.log(err)
            }
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const productImageHandler = async (e) => {
        try {
          const file = e.target.files[0];
          let myFile = file.name.split(".");
          let myFileType = myFile[myFile.length - 1];
    
          // Remove the Exif metadata using the custom function
          const modifiedArrayBuffer = await removeExifData(file);
        // Convert the modified data back to a Blob
          const strippedBlob = new Blob([modifiedArrayBuffer], { type: file.type });
    
          const s3 = new aws.S3({
            accessKeyId: awsKeyId,
            secretAccessKey: awsSecretKey,
            region: awsRegion
          });
    
          const params = {
            Bucket: "ondc-marketplace",
            Key: `images/${uuidv4()}.${myFileType}`,
            Body: strippedBlob, // Use the stripped file
            ContentType: file?.type
          };
    
          let result = await s3.upload(params).promise();
          const { Location } = result;
    
          setpersonalData({ ...personalData, [e.target.name]: Location })
        } catch (exception) {
          console.error('Error during image upload:', exception);
        }
      };
    

    const open = (image) => {
        show(
            <ImageContainer>
                <img src={image} alt="" />
                <button onClick={() => {
                    hide("key#1")
                }}>x</button>
            </ImageContainer>,
            { key: "key#1" }
        )
    }

    return (


        <div className="row m-0 w-100 mt-1">
            <div className="col-md-12" style={{ background: "white" }}>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-bg-gradient-dark">
                            Personal Details
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >
                                        Pan Name
                                        <span className="text-danger" > * </span>
                                        {
                                            personalDataError?.pan_name !== "valid" && personalDataError?.pan_name !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="pan_name" placeholder="Enter Pan Name"
                                        value={personalData.pan_name}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >
                                        Pan Image
                                        <span className="text-danger" > * </span>
                                        {
                                            personalDataError?.pan_image !== "valid" && personalDataError?.pan_image !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    {personalData.pan_image && <a
                                        // href={product.image}
                                        onClick={() => open(personalData.pan_image)}
                                        target="_blank"
                                        style={{
                                            fontSize: "10px",
                                            marginLeft: "3px",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        View Document
                                    </a>}
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => productImageHandler(e)}
                                        accept="image/*"
                                        name="pan_image"
                                        multiple
                                        onBlur={(e) => errorHandler(e)}
                                        id="image"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Street<span className="text-danger" >*</span>
                                        {
                                            personalDataError?.street !== "valid" && personalDataError?.street !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="street" placeholder="Enter Street"
                                        value={personalData.street}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >City<span className="text-danger" >*</span>
                                        {
                                            personalDataError?.city !== "valid" && personalDataError?.city !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" name="city" className="form-control" placeholder="Enter City"
                                        value={personalData.city}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Locality<span className="text-danger" >*</span>
                                        {
                                            personalDataError?.locality !== "valid" && personalDataError?.locality !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" name="locality" className="form-control" placeholder="Enter Locality"
                                        value={personalData.locality}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label >Building<span className="text-danger" >*</span>
                                        {
                                            personalDataError?.building !== "valid" && personalDataError?.building !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" name="building" className="form-control" placeholder="Enter Building"
                                        value={personalData.building}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Radius (In KM)
                                    {/* <span className="text-danger" >*</span> */}
                                        {/* {
                                            personalDataError?.radius !== "valid" && personalDataError?.radius !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        } */}
                                    </label>
                                    <input type="text" name="radius" className="form-control" placeholder="Enter Radius"
                                        value={personalData.radius}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Area PIN Code<span className="text-danger" >*</span>
                                        {
                                            personalDataError?.area_code !== "valid" && personalDataError?.area_code !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="number" name="area_code" className="form-control" placeholder="Enter Area Code"
                                        value={personalData.area_code}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                        onWheel={(e) => e.target.blur()}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >State<span className="text-danger" >*</span>
                                        {
                                            personalDataError?.state !== "valid" && personalDataError?.state !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <select id="State"
                                        className="form-control" name="state" placeholder="State"
                                        value={personalData.state}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    >
                                        <option value="">Select State</option>
                                        <option value="AP">Andhra Pradesh</option>
                                        <option value="AR">Arunachal Pradesh</option>
                                        <option value="AN">Andaman and Nicobar Islands</option>
                                        <option value="AS">Assam</option>
                                        <option value="BR">Bihar</option>
                                        <option value="CG">Chhattisgarh</option>
                                        <option value="CH">Chandigarh</option>
                                        <option value="DH">Dadra and Nagar Haveli</option>
                                        <option value="DD">Daman and Diu</option>
                                        <option value="DL">Delhi</option>
                                        <option value="GA">Goa</option>
                                        <option value="GJ">Gujarat</option>
                                        <option value="HR">Haryana</option>
                                        <option value="HP">Himachal Pradesh</option>
                                        <option value="JK">Jammu and Kashmir</option>
                                        <option value="JH">Jharkhand</option>
                                        <option value="KA">Karnataka</option>
                                        <option value="KL">Kerala</option>
                                        <option value="LD">Lakshadweep</option>
                                        <option value="MP">Madhya Pradesh</option>
                                        <option value="MH">Maharashtra</option>
                                        <option value="MN">Manipur</option>
                                        <option value="ML">Meghalaya</option>
                                        <option value="MZ">Mizoram</option>
                                        <option value="NL">Nagaland</option>
                                        <option value="OR">Orissa</option>
                                        <option value="PB">Punjab</option>
                                        <option value="PY">Pondicherry</option>
                                        <option value="RJ">Rajasthan</option>
                                        <option value="SK">Sikkim</option>
                                        <option value="TN">Tamil Nadu</option>
                                        <option value="TS">Telangana</option>
                                        <option value="TR">Tripura</option>
                                        <option value="UK">Uttarakhand</option>
                                        <option value="UP">Uttar Pradesh</option>
                                        <option value="WB">West Bengal</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Latitude <span className="text-danger" >*</span>
                                        {
                                            personalDataError?.latitude !== "valid" && personalDataError?.latitude !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="latitude" placeholder="Enter Latitude"
                                        value={personalData.latitude}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Longitude <span className="text-danger" >*</span>
                                        {
                                            personalDataError?.longitude !== "valid" && personalDataError?.longitude !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="longitude" placeholder="Enter Longitude"
                                        value={personalData.longitude}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Personal Address <span className="text-danger" >*</span>
                                        {
                                            personalDataError?.personal_address !== "valid" && personalDataError?.personal_address !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="personal_address" placeholder="Enter Personal Address"
                                        value={personalData.personal_address}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6" style={{ display: "none" }}>
                                <div className="form-group">
                                    <label >Short Description of Shop

                                    </label>
                                    <input type="text" className="form-control" name="short_description" placeholder="Enter Short Description"
                                        value={personalData.short_description}
                                        onChange={(e) => inputHandler(e)}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6" style={{ display: "none" }}>
                                <div className="form-group">
                                    <label >Long Description of Shop

                                    </label>
                                    <input type="text" className="form-control" name="description" placeholder="Enter Long Description"
                                        value={personalData.description}
                                        onChange={(e) => inputHandler(e)}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label >Address Proof Type <span className="text-danger" >*</span>
                                    {
                                        personalDataError?.address_proof_type ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <div className="form-group">

                                    <select name="address_proof_type" id="" className="form-control"
                                        value={personalData.address_proof_type}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    >
                                        <option value="">Select address proof</option>
                                        <option value="Aadhar card">Aadhar card</option>
                                        <option value="Ration card">Ration card</option>
                                        <option value="Telephone bill">Telephone bill</option>
                                        <option value="Bank Passbook">Bank Passbook</option>
                                        <option value="Electricity bill">Electricity bill</option>
                                        <option value="Lease or license agreement">Lease or license agreement</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Address Proof Image <span className="text-danger" >*</span>
                                        {
                                            personalDataError?.address_proof_image ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    {personalData.address_proof_image && <a
                                        // href={product.image}
                                        onClick={() => open(personalData.address_proof_image)}
                                        target="_blank"
                                        style={{
                                            fontSize: "10px",
                                            marginLeft: "3px",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        View Document
                                    </a>}
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => productImageHandler(e)}
                                        accept="image/*"
                                        name="address_proof_image"
                                        multiple
                                        onBlur={(e) => errorHandler(e)}
                                        id="address_proof_image"
                                    />
                                </div>
                            </div>









                            <div className="col-md-12">

                                <button type="submit" className="btn btn-dark" style={{ background: "#0094ff" }}
                                    onClick={() => {
                                        onSubmitHandler()
                                        setTimeout(() => {
                                            props.pageChangeController()
                                        }, 3000)

                                    }}>
                                    Update
                                </button>
                            </div>
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
