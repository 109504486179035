import { useState, useEffect, useRef, memo } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import ThemeMaker from "../../components/thememaker";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import { integer } from "aws-sdk/clients/frauddetector";
import NewCategorylist from "../../components/CategoryTemplate/NewCategorylist";
// import styles from "../transaction/transaction.module.css"
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from "react-router-dom";
import styled from "styled-components"
import style from "../SellerDetails/Sellerdetails.module.css"
const { apiEndpoint } = config
var $ = require('jquery');

const Wrapper = styled.div
    `
padding: 10px;
display: flex;
flex-wrap: wrap


`
let arr = []
let Ndata = arr;
const Addcategory = () => {
    const decoded = tokenDecode();
    const { data } = decoded;
    const { permission: { category } } = data;
    const [listing, setListing] = useState(true);
    let rawSubCatData = {
        parent_id: "",
        cat_name: "",
        status: true,
        id: ""
    }
    const [Subcategorydata, setSubcategorydata] = useState(rawSubCatData);
    const [subcat, setSubcat] = useState(false)
    const [subcattoggle, setSubcattoggle] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [listingLoading, setListingLoading] = useState(false);
    const [taxData, setTaxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectsub, setSelectsub] = useState(0);
    // const [catlist, setCatlist] = useState('');
    const [Nadata, setNadata] = useState([]);
    // const [isChecked, setIsChecked] = useState(false);

    const [tax, setTax] = useState({
        tax: '',
        status: true,
        id: ''
    });

    const taxD = {
        tax: false,
        name: false,
        parent_id: false
    }
    const [taxErrorData, setTaxErrorData] = useState(taxD);
    const errorHandler = (e) => {
        if (e.target.value) {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: false });
        } else {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: true });
        }
    }
    const inputHandler = (e) => {
        setSelectsub(e.target.value);

    };

    const createTaxHandler = async () => {
        let apiEndpoint = baseUrlDefiner();
        try {
            setLoading(true);
            if (tax?.tax) {
                const { data } = await axios({
                    url: `${apiEndpoint}/addcategorie`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                      },
                    data: {
                        cat_name: tax.tax,
                        status: tax.status
                    }
                });

                if (data.status === 200) {
                    toast.success("New Category created !");
                    setTax({
                        tax: '',
                        status: true,
                        id: ''
                    })
                } else {
                    toast.error("403 Error !");
                }
            } else {
                toast.error("Please fill the required field !");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !");
        }
    }


    const createSubCatHandler = async () => {
        let apiEndpoint = baseUrlDefiner();
        console.log("data to send-->", Subcategorydata);
        const { parent_id, cat_name, status } = Subcategorydata
        try {
            setLoading(true);

            if (Subcategorydata?.cat_name) {
                const { data } = await axios({
                    url: `${apiEndpoint}/ptaddsubcategories`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: { product_cat_id: parent_id, name: cat_name, status: status }
                });

                if (data.status === 200) {
                    toast.success("New Sub Category created !");
                    setSubcategorydata(rawSubCatData)
                } else {
                    toast.error("Error in Payload!");
                }
            } else {
                toast.error("Please fill the required field !");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Server Error !");
        }
    }

    const taxListHanler = async () => {
        let apiEndpoint = baseUrlDefiner();
        try {
            setListing(true);
            setSubcat(false)
            setListingLoading(true);

            const { data } = await axios({
                url: `${apiEndpoint}/ptgetcategories`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                  },
            });

            setListingLoading(false);
            if (data.status === 200) {
                setTaxData(data.response);
            }

        } catch (error) {
            setListingLoading(false);


        }
    }

    const setCrete = () => {
        setListing(false);
        setIsUpdate(false);
        setSubcat(false);
        setTax({
            tax: '',
            status: true,
            id: ''
        })
    }
    const setsubCrete = async () => {
        let apiEndpoint = baseUrlDefiner();
        setTax({
            tax: '',
            status: true,
            id: ''
        })
        setSubcategorydata(rawSubCatData)
        setListing(false);
        setIsUpdate(false);
        setSubcat(true);
        try {
            setListing(false);
            setListingLoading(false);
            const { data } = await axios({
                url: `${apiEndpoint}/getcategories`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                  },
            });
            setListingLoading(false);
            if (data.status === 200) {
                Ndata = data.response;
                const parsedData = data.response.map(item => ({
                    id: item.id,
                    parent_id: item.parent_id,
                    cat_name: item.cat_name,
                    status: item.status,
                    subcategory: item.subcategory || []
                }));
                setNadata(parsedData);
            }
        } catch (error) {
            setListingLoading(false);
        }
    }


    const editRedirect = async (id, parentCatName, isParentCategory) => {
        let endpointforCategoryView
        let apiEndpoint = baseUrlDefiner();
        if (isParentCategory) {
            endpointforCategoryView = "ptcatsub"
        } else {
            endpointforCategoryView = "ptgetcatsub"
        }
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/${endpointforCategoryView}`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                  },
                data: {
                    "id": id
                }
            });
            if (!isParentCategory) {
                if (data.status === 200) {
                    setTax({
                        tax: data?.parent_id?.cat_name,
                        status: data?.parent_id?.status,
                        id: data?.parent_id?.id
                    });
                    setSubcategorydata({
                        parent_id: data?.parent_id?.parent_id,
                        cat_name: data?.parent_id?.cat_name,
                        status: data?.parent_id?.status,
                        id: data?.parent_id?.id
                    })
                    setSelectsub(data?.parent_id?.parent_id)
                    setSubcattoggle(true)
                }
            }

            if (isParentCategory) {
                if (data.status === 200) {
                    setTax({
                        tax: data?.parent_id[0]?.cat_name,
                        status: data?.parent_id[0]?.status,
                        id: data?.parent_id[0]?.id
                    });
                    setSubcategorydata({
                        parent_id: data?.parent_id[0]?.parent_id,
                        cat_name: data?.parent_id[0]?.cat_name,
                        status: data?.parent_id[0]?.status,
                        id: data?.parent_id[0]?.id
                    })
                    setSelectsub(data?.parent_id[0]?.parent_id)
                    setSubcattoggle(true)
                }
            }

        } catch (error) {

        }
        setIsUpdate(true);
        setListing(false);
        if (parentCatName !== "") {
            setSubcat(true)
        } else {
            setSubcat(false);
        }


    }

    const updateTax = async () => {
        let apiEndpoint = baseUrlDefiner();
        try {
            if (tax?.tax) {
                setLoading(true);
                console.log("tax", tax);
                const { data } = await axios({
                    url: `${apiEndpoint}/updatecategories`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                      },
                    data: {
                        "cat_name": tax.tax,
                        "status": tax.status,
                        id: tax.id
                    }
                });
                // console.log("data----", data);
                if (data.status === 200) {
                    toast.success("Category Updated !");
                } else {
                    toast.error("403 Error !");
                }
            } else {
                toast.error("Please fill the required field !");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !");
        }
    }

    const updateSubTax = async () => {
        let apiEndpoint = baseUrlDefiner();
        const { parent_id, cat_name, status, id } = Subcategorydata
        try {
            if (Subcategorydata?.cat_name) {
                setLoading(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/ptupdatesubcategories`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                      },
                    data: {
                        "name": cat_name,
                        "product_cat_id": parent_id,
                        "id": id,
                        "status": status
                    }
                });

                if (data.status === 200) {
                    toast.success("Category Updated !");
                } else {
                    toast.error("Payload Error!");
                }
            } else {
                toast.error("Please fill the required field !");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Api not working!");
        }
    }

    const deleteTax = async (id, parent_id) => {
        let apiEndpoint = baseUrlDefiner();
        try {
            setLoading(true);

            const { data } = await axios({
                url: `${apiEndpoint}/ptdelcatshiftsubcat`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                  },
                data: { id, product_cat_id:parent_id }
            });
            if (data.status === 200) {
                toast.success("Category Deleted !");
                setTimeout(() => {
                    window.location.reload(false)
                }, 1000);

            } else {
                toast.error("403 Error !");
            }
        } catch (error) {
            toast.error("403 Error !");
        }
    }


    const handleSubcategoryChange = (id) => {
        setSubcategorydata({ ...Subcategorydata, parent_id: id });
    };

    const MakeCategory = memo(({ cat_name, subcategory, id, onSubcategoryChange, subcattoggle, setSubcattoggle, Subcategorydata, setSubcategorydata }) => {
        const subCategoryChecker = subcategory && subcategory.length > - 1;
        const handleCheckboxChange = () => {
            setSubcategorydata((prevState) => ({
                ...prevState,
                parent_id: prevState.parent_id === id ? null : id,
            }));
            onSubcategoryChange(id);
        };
        const handleToggleSubcategory = () => {
            setSubcattoggle((prevState) => ({ ...prevState, [id]: !prevState[id] }));
        };

        return (
            <>
                <div className="makeCategory_subCategory">
                    <ul className="my-0 category_list">
                        <li key={id}>
                            {subCategoryChecker && (
                                <span onClick={handleToggleSubcategory}>
                                    {subcattoggle[id] ? <RemoveIcon /> : <AddIcon />}
                                </span>
                            )}
                            <input
                                type="checkbox"
                                value={id}
                                onChange={handleCheckboxChange}
                                id={id}
                                checked={Subcategorydata.parent_id === id}
                            /> {cat_name}
                        </li>

                        {subCategoryChecker && subcattoggle[id] && (
                            <ul>
                                {subcategory.map((item) => (
                                    <MakeCategory
                                        {...item}
                                        key={item.id}
                                        onSubcategoryChange={onSubcategoryChange}
                                        subcattoggle={subcattoggle}
                                        setSubcattoggle={setSubcattoggle}
                                        Subcategorydata={Subcategorydata}
                                        setSubcategorydata={setSubcategorydata}
                                    />
                                ))}
                            </ul>
                        )}
                        <hr style={{ marginBottom: "0", marginTop: "0" }} />
                    </ul>
                </div>
            </>
        );
    });

    useEffect(() => {
        taxListHanler()
    }, [])

    console.log("dgfsghdasd", subcattoggle)
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0">
                    <div className="col-md-12 mt-2">
                        <div className="card shadow mb-4" style={{ minHeight: "80vh" }}>
                            <div className="card-header py-3 px-0 mt-2" >
                                <h6 className="m-0 font-weight-bold text-primary">
                                    <div className={style.tabs}>
                                        <button className={listing ? `${style.tab} ${style.active}` : `${style.tab}`}
                                            onClick={taxListHanler}
                                        >
                                            Category list
                                        </button>
                                        <button className={(!subcat && !listing) ? `${style.tab} ${style.active}` : `${style.tab}`}
                                            onClick={setCrete}
                                        >
                                            New Category
                                        </button>
                                        <button className={subcat ? `${style.tab} ${style.active}` : `${style.tab}`}
                                            onClick={setsubCrete}
                                        >
                                            Sub Category
                                        </button>

                                    </div>
                                </h6>
                            </div>
                            {
                                listing ? (<>

                                    <Wrapper >  {

                                        <NewCategorylist item={taxData} editRedirect={editRedirect} deleteTax={deleteTax} />

                                    }</Wrapper></>
                                ) : (<>
                                    {subcat ?
                                        <div className="card-body">
                                            <div className="">
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label >Sub Category name</label>
                                                        <span className="text-danger" > * </span>
                                                        {
                                                            taxErrorData?.name ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                        <input type="text" className="form-control" name="cat_name" placeholder="Sub Category"
                                                            value={Subcategorydata.cat_name}
                                                            onChange={(e) => setSubcategorydata({ ...Subcategorydata, [e.target.name]: e.target.value })}
                                                            onBlur={(e) => errorHandler(e)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">

                                                        <div className="subcategory">
                                                            <div className="selectcategory">Select Category <span style={{ "float": "right" }}>▼</span>
                                                                {/* <input type="text" className="form-control" name="subcat" placeholder="Search" value={search} onChange={handleSearchChange} /> */}
                                                            </div>
                                                            <div style={{maxHeight: "400px", overflow: "scroll"}}>
                                                                {
                                                                    Nadata.map((item, index) => (
                                                                        <MakeCategory
                                                                            {...item}
                                                                            key={index}
                                                                            onSubcategoryChange={handleSubcategoryChange}
                                                                            subcattoggle={subcattoggle}
                                                                            setSubcattoggle={setSubcattoggle}
                                                                            Subcategorydata={Subcategorydata}
                                                                            setSubcategorydata={setSubcategorydata}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label >Status</label>
                                                        <input type="checkbox" checked={Subcategorydata.status} name="status" placeholder="Name"
                                                            value=""
                                                            className="ml-2"
                                                            onChange={(e) => setSubcategorydata({ ...Subcategorydata, [e.target.name]: !setSubcategorydata.status })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    {
                                                        !isUpdate && !listing ? (
                                                            <button type="submit" className="btn btn-primary"
                                                                onClick={createSubCatHandler}
                                                            >
                                                                SaveSub
                                                            </button>
                                                        ) : (
                                                            <button type="submit" className="btn btn-success"
                                                                onClick={updateSubTax}
                                                            >
                                                                Update
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                            </div>
                                        </div> : <div className="card-body">
                                            <div className="">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label >Category name</label>
                                                        <span className="text-danger" > * </span>
                                                        {
                                                            taxErrorData?.tax ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                        <input type="text" className="form-control" name="tax" placeholder="Category"
                                                            value={tax.tax}
                                                            onChange={(e) => setTax({ ...tax, [e.target.name]: e.target.value })}
                                                            onBlur={(e) => errorHandler(e)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group d-flex align-items-center" style={{ gap: "3px" }}>
                                                        <label className="m-0">Status</label>
                                                        <input type="checkbox" checked={tax.status} name="status" placeholder="Name"
                                                            value=""
                                                            className="ml-2"
                                                            onChange={(e) => setTax({ ...tax, [e.target.name]: !tax.status })}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    {
                                                        !isUpdate && !listing ? (
                                                            <button type="submit" className="btn btn-primary"
                                                                onClick={createTaxHandler}
                                                            >
                                                                Save
                                                            </button>
                                                        ) : (
                                                            <button type="submit" className="btn btn-success"
                                                                onClick={updateTax}
                                                            >
                                                                Update
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                            </div>
                                        </div>
                                    }
                                </>

                                )
                            }
                        </div>
                    </div>
                </div>
            </ThemeMaker>

        </>
    )
}

export default Addcategory;