
export const config = {
    // apiEndpoint: "https://xpressbazarprod.hulsecure.in/public/index.php/api",
    // preprode Live
    apiEndpoint: "https://preprodshikharapp.hulsecure.in/public/index.php/api",
    // Preprod Local
    // apiEndpoint: "http://localhost:5001",
    nodeapiEndpoint: "https://preprod.xpressbaazaar.com/igm",
    baseNodeapiUrl: "https://preprod.xpressbaazaar.com",
    ideamasterNodeapiUrl: "https://multisellers.thesellerapp.com",
    awsKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    awsSecretKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    awsRegion: process.env.REACT_APP_AWS_REGION,
    awsBucket: process.env.REACT_APP_AWS_BUCKET,
    // ------------------------------- FOR MULTISELLER -----------------------------------------------
}

// export function baseUrlDefiner() {
//     const fullurl: any = window.location.host
//     const port = window.location.port || "3000";
//     console.log(fullurl)
//     // const result = "http://localhost:3000";
//     // const result = fullurl?.match(/\/\/(.*?)\.com/)[1];
//     if(fullurl === "multipleselleradmin.thesellerapp.com"){
//         config.apiEndpoint = "https://multisellerpreprod.hulsecure.in//public/index.php/api"
//         return config.apiEndpoint
//     }
//     if(fullurl === "preprodadmin.xpressbaazaar.com"){
//         config.apiEndpoint = "https://preprodshikharapp.hulsecure.in/public/index.php/api"
//         return  config.apiEndpoint
//     }
//     if (fullurl.includes(`localhost:${port}`)) {
//          config.apiEndpoint = "https://preprodshikharapp.hulsecure.in/public/index.php/api"
//         return  config.apiEndpoint
//     }
// }

export function baseUrlDefiner() {
    const fullUrl = window.location.host;
    const port = window.location.port || "3000";
    let apiEndpoint = "";
   
    switch (fullUrl) {
        case "multipleselleradmin.thesellerapp.com":
            apiEndpoint = "https://multisellerpreprod.hulsecure.in/public/index.php/api";
            
            break;
        case "preprodadmin.xpressbaazaar.com":
            apiEndpoint = "https://preprodshikharapp.hulsecure.in/public/index.php/api";
            
            break;
        // case "wacpreprod-admin.thesellerapp.com":
        //     apiEndpoint = "https://wac-preprod.hulsecure.in/public/index.php/api";
           
        
        //gcp-heritage:-
        case "multisellersadmin.thesellerapp.com":
            apiEndpoint = "https://multisellersphp.thesellerapp.com/public/index.php/api";
           
            break;
        
        default:

                apiEndpoint = "https://multisellersphp.thesellerapp.com/public/index.php/api";

            
            break;
    }
    return apiEndpoint;
}
export function NodebaseUrlDefiner() {
    const fullUrl = window.location.host;
    const port = window.location.port || "3000"; 
    let baseNodeapiUrl="";
    switch (fullUrl) {
        case "multipleselleradmin.thesellerapp.com":           
            baseNodeapiUrl="https://ondc.theideamasters.com"
            break;
        case "preprodadmin.xpressbaazaar.com":         
            baseNodeapiUrl="https://preprod.xpressbaazaar.com"
            break;
        case "wacpreprod-admin.thesellerapp.com":
            baseNodeapiUrl="https://preprod.xpressbaazaar.com"
            break;
            //gcp-heritage:-
        case "multisellersadmin.thesellerapp.com":
            baseNodeapiUrl="https://multisellers.thesellerapp.com"
            break;
        default:
            
                baseNodeapiUrl="https://multisellers.thesellerapp.com"
            
            break;
    }
    return baseNodeapiUrl;
}




