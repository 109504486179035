import React, { useState, useEffect } from 'react'
import ThemeMaker from '../../components/thememaker'
import moment from "moment"
import { Paginated } from '../../components/Paginated';
import CircularProgress from '@mui/material/CircularProgress';
import { config } from "../../utils/config";
import axios from "axios"
import * as XLSX from 'xlsx';
import Cookies from 'js-cookie';
import { baseUrlDefiner } from '../../utils/config';


export default function Rspdashboard() {
  const { apiEndpoint } = config;
  const [loading, setloading] = useState(false);
  const [supportList, setSupportList] = useState([]);

  const COLUMNS = [
    {
      Header: "Sn No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Network Order ID",
      accessor: "orderId",
      sticky: "left",
    },
    {
      Header: "Collector ID",
      accessor: "collectoId",
      sticky: "left",
    },
    {
      Header: "Receiver ID",
      accessor: "reciverId",
      sticky: "left",
    },
    {
      Header: "Order State",
      accessor: "Orderstate",
      sticky: "left",
    },
    {
      Header: "Order Created Date-time (ISO 8601 format: YYYY-MM-DD)",
      accessor: (a: any) => moment(a.order_created_date).format("YYYY-MM-DD"),
      sticky: "left",
    },
    {
      Header: "Item Value",
      accessor: (a: any) => {
        
        let itemParsedValue = JSON.parse(a?.item_value);
        
        let itemBreakup = itemParsedValue.breakup;
        let z = itemBreakup.filter((item: any) => item["@ondc/org/title_type"] ==="item").map((item: any) => parseInt(item?.item?.price?.value))
        
        let itemValue = z.reduce((a: any, b: any) => a + b, 0)
       
        return itemValue
      },
      sticky: "left",
    },
    {
      Header: "Shipping Charges",
      accessor: (a: any) => { let c = JSON.parse(a?.shipping_charges); return c.breakup[c?.breakup?.length - 2]?.price?.value },
      sticky: "left",
    },
    {
      Header: "Packaging Charges",
      accessor: (a: any) => { let c = JSON.parse(a?.shipping_charges); return c.breakup[c?.breakup?.length - 1]?.price?.value },
      sticky: "left",
    },
    {
      Header: "Convenience Charges",
      accessor: "convenienceCharges",
      sticky: "left",
    },
    {
      Header: "Total Order Value",
      accessor: "totalOrdervalue",
      sticky: "left",
    },
    {
      Header: "Buyer Finder Fee (Amount)",
      accessor: "buyer_commission",
      sticky: "left",
    },
    {
      Header: "Withholding Amount",
      accessor: "withholding_amount",
      sticky: "left",
    },
    {
      Header: "TCS (Withholding Tax under GST Act)",
      accessor: "tcs",
      sticky: "left",
    },
    {
      Header: "TDS (Withholding Tax under IT Act)",
      accessor: "tds",
      sticky: "left",
    },
    {
      Header: "Deduction by Collector (11+13+14)",
      accessor: "deductionbycollector",
      sticky: "left",
    },
    {
      Header: "Settlement Amount",
      accessor: "settlementAmount",
      sticky: "left",
    },
    {
      Header: "Return Window",
      accessor: "return_window",
      sticky: "left"
    },
    {
      Header: "Beneficiary IFSC",
      accessor: (a: any) => {
       
        let c = JSON.parse(a.benificiaryifsc);
        console.log("c-------", c)
        let ifsc;
        if (c["@ondc/org/settlement_details"]) {
          ifsc = (c["@ondc/org/settlement_details"][0]?.settlement_ifsc_code);
        }

        return ifsc
      },
      // Cell: (item: any) => moment((item.value)).format("DD-MM-YYYY hh:mm:ss"),
      sticky: "left",
    },
    {
      Header: "Collector IFSC",
      accessor: "collectorIfsc",
      // Cell: (item: any) => moment((item.value)).format("DD-MM-YYYY hh:mm:ss"),
      sticky: "left",
    },
    {
      Header: "Settlement Reference Number",
      accessor: "settlementRefrenceAmount",
      sticky: "left",
    },
    {
      Header: "Difference amount",
      accessor: "differenceAmount",
      sticky: "left",
    },
    {
      Header: "Message (if any)",
      accessor: "msg",
      sticky: "left",
    },
    {
      Header: "Created Date-time (ISO 8601 format: YYYY-MM-DD)",
      accessor: (a: any) => {
        let c = JSON.parse(a.createddate);
        let createdDate = c.created_at;
        let cdate = moment(createdDate).format("YYYY-MM-DD")

        return cdate
      },
      sticky: "left",
    }
  ];

  const getSupportList = async () => {
    let apiEndpoint =  baseUrlDefiner();
    setloading(true)
    try {
      const {
        data: { data },
      } = await axios({
        url: `${apiEndpoint}/rspDetails`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
      },
      });
      setSupportList(data);
      setloading(false);

    } catch (error) {
      setloading(false);
      // setError("error");
    }
  };


  useEffect(() => {
    getSupportList();
  }, []);

  const exportToExcel = (orders: any) => {
    console.log("csv orders----->", orders)
    // Define custom column headers for the Excel file
    const headers = [
      "Sn No.",
      "Network Order ID",
      "Collector ID",
      "Receiver ID",
      "Order State",
      "Order Created Date - time(ISO 8601 format: YYYY - MM - DD)",
      "Item Value",
      "Shipping Charges",
      "Packaging Charges",
      "Convenience Charges",
      "Total Order Value",
      "Buyer Finder Fee(Amount)",
      "Withholding Amount",
      "TCS(Withholding Tax under GST Act)",
      "TDS(Withholding Tax under IT Act)",
      "Deduction by Collector(11 + 13 + 14)",
      "Settlement Amount",
      "Return Window",
      "Beneficiary IFSC",
      "Collector IFSC",
      "Settlement Reference Number",
      "Difference amount",
      "Message(if any)",
      "Created Date - time(ISO 8601 format: YYYY - MM - DD)",
    ];
    console.log("orders----->", orders)
    // // Prepare data with just the values to be displayed in the columns
    const data = orders.map((order: any, index: any) => {

      const itemvalue = (order: any) => {
        let itemParsedValue = JSON.parse(order?.item_value)
        let itemBreakup = itemParsedValue.breakup;
        let z =  itemBreakup.filter((item: any) => item["@ondc/org/title_type"] ==="item").map((item: any) => parseInt(item?.item?.price?.value))
        let itemValue = z.reduce((a: any, b: any) => a + b, 0)
        return itemValue
      }
      const ItemValue = itemvalue(order)

      const shippingCharge = () => {
        let c = JSON.parse(order?.shipping_charges)
        return c.breakup[c?.breakup?.length - 2]?.price?.value
      }

      const packingCharge = () => {
        let c = JSON.parse(order?.shipping_charges)
        return c.breakup[c?.breakup?.length - 1]?.price?.value
      }

      const beneficiaryIfsc = () => {
        let c = JSON.parse(order.benificiaryifsc);
        let ifsc;
        if (c["@ondc/org/settlement_details"]) {
          ifsc = (c["@ondc/org/settlement_details"][0]?.settlement_ifsc_code);
        }

        return ifsc
      }

      const createdAt = () => {
        let c = JSON.parse(order.createddate);
        let createdDate = c.created_at;
        let cdate = moment(createdDate).format("YYYY-MM-DD")

        return cdate
      }
      return {
        "Sn No.": index + 1,
        "Network Order ID": order.orderId,
        "Collector ID": order.collectoId,
        "Receiver ID": order.reciverId,
        "Order State": order.Orderstate,
        "Order Created Date - time(ISO 8601 format: YYYY - MM - DD)": moment(order.order_created_date).format("YYYY-MM-DD"),
        "Item Value": ItemValue,
        "Shipping Charges": shippingCharge(),
        "Packaging Charges": packingCharge(),
        "Convenience Charges": order.convenienceCharges,
        "Total Order Value": order.totalOrdervalue,
        "Buyer Finder Fee(Amount)": order.buyer_commission,
        "Withholding Amount": order.withholding_amount,
        "TCS(Withholding Tax under GST Act)": order.tcs,
        "TDS(Withholding Tax under IT Act)": order.tds,
        "Deduction by Collector(11 + 13 + 14)": order.deductionbycollector,
        "Settlement Amount": order.settlementAmount,
        "Return Window": order.return_window,
        "Beneficiary IFSC": beneficiaryIfsc(),
        "Collector IFSC": order.collectorIfsc,
        "Settlement Reference Number": order.settlementRefrenceAmount,
        "Difference amount": order.differenceAmount,
        "Message(if any)": order.msg,
        "Created Date - time(ISO 8601 format: YYYY - MM - DD)": createdAt(),
      }
    });

    // // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    // // Generate a unique name for the Excel file (you can customize this as needed)
    const fileName = `RSP_Dashboard.xlsx`;

    // // Export the workbook as an Excel file
    XLSX.writeFile(wb, fileName);
  };

  return (
    <ThemeMaker>

      <div className="row m-0" style={{width: "100%"}}>
        <div className="col-md-12 ">
          <div className="card shadow mt-3 mb-4">
            <div className="card-header  py-3">
              <div className="row mt-3 d-flex justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  RSP Dashboard
                </h6>

                <div>
                  <a onClick={() => setTimeout(() => {
                    exportToExcel(supportList)
                  }, 2000)} className="btn btn-primary">
                    Download Sheet
                  </a>
                </div>
              </div>

            </div>

            <div className="card-body">
              <div className="table-responsive table table-striped table-bordered table-sm">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Paginated
                    data={supportList}
                    columns={COLUMNS}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>


      </div>

    </ThemeMaker>

  )
}
