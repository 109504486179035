import * as React from "react";
import "./switch.css";
import { useEffect, useState } from "react";
import { tokenDecode } from "../../utils/helper";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import styled from "styled-components";
import { config } from "../../utils/config";
import Circularprogress from "../../components/Generics/Circularprogress";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import ReactPaginate from 'react-paginate';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import JsBarcode from "jsbarcode";
import Barcode from 'react-barcode';
import { Paginated } from "../../components/Paginated";
import ThemeMaker from "../../components/thememaker";
import { toast } from "react-toastify";
const { apiEndpoint } = config
const ImageContainer = styled.div
  `
  max-height: 64px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 68px;
  object-fit: contain;
}
  `

const Mastercatalog = () => {
  const ref = React.useRef(" ");
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  // console.log("current Seller id:-----", current_sellerid)
  const decode = tokenDecode();
  const { data } = decode;
  const [productlist, setproductlist] = useState([]);
  const [noproducts, setNoproducts] = useState(false);
  const [productisLoading, setproductisLoading] = useState(false);
  const [searchValue, setsearchValue] = useState({
    name: "",
    searchFilter: ""
  })
  const [mopen, setmopen] = React.useState(false);
  const smstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, minHeight: 200, maxHeight: 500, overflow: "scroll", color: "black",
  };
  const handleClose = () => setmopen(false);
  useEffect(() => {
    getproductlist();
  }, []);

  useEffect(() => {
    // When productlist changes, update itemCheckboxes to reflect the current state
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.in_store;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  async function getproductlist() {
    let apiEndpoint = baseUrlDefiner();
    // let parentId = decoded?.data?.user?.parent_id;
    // let SellerId = decoded?.data?.user?.seller_id;
    // let payload;
    // if (parentId > 1) {
    //   payload = { seller_id: parentId }

    // } else {
    //   payload = { seller_id: SellerId }

    // }
    setproductisLoading(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/get_master_product`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: { "id": [] },
      });
      // console.log("data of master product list-->", data)
      if (data) {
        setNoproducts(false)
        setproductlist(data.data);
        setproductisLoading(false)

      }
    } catch (error) { }
  }


  function handleSort(e) {
    if (e.target.value == 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.mrp) - parseInt(b.mrp);
      });
      setproductlist(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.mrp) - parseInt(a.mrp);
      });
      setproductlist(sortedHightoLow);
    } else {
      getproductlist();
    }
  }

  //---------------------------------------------- code  for pagination------------------------------------------------
  const [itemCheckboxes, setItemCheckboxes] = useState({});
  useEffect(() => {
    // When productlist changes, update itemCheckboxes to reflect the current state
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.in_store;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  // const currentItems = productlist.slice(itemOffset, endOffset);

  const [currentItems, setcurrentItems] = useState([]);

  useEffect(() => {
    let z = productlist.slice(itemOffset, endOffset)
    setcurrentItems([...z])
  }, [productlist, itemOffset])


  //---------------------------------------------- code for pagination------------------------------------------------


  function updateProductList(productList) {
    setproductlist(productList)
  }
  const [svg, setsvg] = useState("")
  async function getBarcode(item) {
    // console.log("item--------->", item)
    setsvg(item.id)

  }

  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row, count) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row) => {
        return (
          <img
            src={row?.product ? row?.variations[0]?.variation?.image?.split(",")[0] : row?.image?.split(",")[0]}
            alt="Product Image"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: (row) => row?.product ? row?.product?.name : row?.name,
      sticky: "left",
    },
    {
      Header: "Selling Price",
      Footer: "Selling Price",
      accessor: (row) => row?.product ? row?.variations[0]?.variation?.selling_price : row?.selling_price,
      sticky: "left",
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: (row) => row?.product ? row?.variations[0]?.variation?.sku : row?.sku,
      sticky: "left",
    },
    {
      Header: "Variations",
      Footer: "Variations",
      accessor: (row) => (row?.product && row?.product?.has_variations) ? "Available" : row?.has_variations ? "Available" : "Not Available",
      sticky: "left",
    },
    // {
    //   Header: "Status",
    //   Footer: "Status",
    //   accessor: (row) => {
    //     return <> {row?.product ? <>
    //       {row?.product?.status ? "Active" : "Draft"} </> :
    //       <>  {row.status ? "Active" : "Draft"}</>}</>
    //   },
    //   sticky: "left",
    // },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row) => {
        return (
          // <button>Action</button>
          <div >
            <label className="switch">
              <input type="checkbox" checked={itemCheckboxes[row?.product?.id]} onChange={async (e) => {
                const itemId = row.id;
                const isChecked = !itemCheckboxes[row?.product?.id];
                setItemCheckboxes({ ...itemCheckboxes, [itemId]: isChecked });
                let apiEndpoint = baseUrlDefiner();
                // console.log("objecthsjdshdksdjas---", { id: itemId, isCheked });
                try {
                  const { data } = await axios({
                    url: `${apiEndpoint}/seller_instore_product`,
                    method: "POST",
                    headers: {
                      "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: {
                      "product_id": [row?.product ? row?.product?.id : row.id]
                    },
                  });

                  if (data?.status == "200") {
                    toast.success("Product added to your store!")
                  } else {
                    toast.error("Error in adding product to your store")
                  }
                } catch (err) {
                  toast.error("Error in server, please try later!")
                }


              }} />
              <span className="slider round"></span>
            </label>
          </div>

        );
      },
      sticky: "left",
    },
  ];

  return (
    <ThemeMaker>
      <>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex">
                <h6 className="m-0 font-weight-bold text-primary text-left">Master Product List</h6>


              </div>

              <div className="card-body" style={{ minHeight: "70vh" }}>
                <div className="productListing_desktop">
                  <Paginated data={productlist} columns={COLUMNS} />
                </div>
              </div>
            </div>
          </div>


        </div>

        <Modal
          open={mopen}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={smstyle} className="variantBox">
            <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleClose} />
            <p className="text-center" style={{ fontSize: "20px", fontWeight: "700" }}>Bar Code</p>
            <div className="d-flex justify-content-center">
              <Barcode value={svg} width={4} />;
            </div>


          </Box>
        </Modal>
      </>
    </ThemeMaker>
  );
};

export default Mastercatalog;
