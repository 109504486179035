// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_main{
    /* border: 1px solid red */
    margin: 20px auto;
}

.pagination_main ul{
    display: flex;
    justify-content: center;
    gap: 3px    ;
    align-items: center;

}

.pagination_main ul li a{
    list-style: none;
    /* border: 1px solid black; */
    background-color: #105293;
    color: white;
    font-weight: 600;
    /* margin-left: 3px; */
    /* padding: 2px 5px; */
    border-radius: 50%;
    width: 43px;
    /* width: fit-content; */
    height: 43px;
    /* margin: 0 auto; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_main ul li {
    color: white;
    list-style: none;
    width: -moz-fit-content;
    width: fit-content;
}


.current_active{
    background-color: rgb(32, 32, 174) !important;
}

@media screen and (max-width: 321px) {
    .pagination_main ul li a{
        width: 25px;
        height: 25px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/Paginated/pagination.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;;AAEvB;;AAEA;IACI,gBAAgB;IAChB,6BAA6B;IAC7B,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,wBAAwB;IACxB,YAAY;IACZ,oBAAoB;IACpB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,uBAAkB;IAAlB,kBAAkB;AACtB;;;AAGA;IACI,6CAA6C;AACjD;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;;AAEJ","sourcesContent":[".pagination_main{\n    /* border: 1px solid red */\n    margin: 20px auto;\n}\n\n.pagination_main ul{\n    display: flex;\n    justify-content: center;\n    gap: 3px    ;\n    align-items: center;\n\n}\n\n.pagination_main ul li a{\n    list-style: none;\n    /* border: 1px solid black; */\n    background-color: #105293;\n    color: white;\n    font-weight: 600;\n    /* margin-left: 3px; */\n    /* padding: 2px 5px; */\n    border-radius: 50%;\n    width: 43px;\n    /* width: fit-content; */\n    height: 43px;\n    /* margin: 0 auto; */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.pagination_main ul li {\n    color: white;\n    list-style: none;\n    width: fit-content;\n}\n\n\n.current_active{\n    background-color: rgb(32, 32, 174) !important;\n}\n\n@media screen and (max-width: 321px) {\n    .pagination_main ul li a{\n        width: 25px;\n        height: 25px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
