// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller_register_thanks_container__0S7GE{
    width: 100%;
    /* border: 1px solid red; */
    height: 100%;
    /* background-image: url(../../icons/hurray.png); */
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    
}



/* .thanks_container > div img{
    width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

.seller_register_thanks_statement__Z-z\\+p p{
    text-align: center;
}
.seller_register_thanks_statement__Z-z\\+p p:nth-child(1){
    color: black;
    font-size: 17px;
    font-weight: 600;
    

}




.seller_register_thanks_button__14Q6P button{
    background-color: #0094ff !important;
    
    
}`, "",{"version":3,"sources":["webpack://./src/pages/SellerRegisteration/seller_register.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;IAC3B,YAAY;IACZ,mDAAmD;IACnD,2BAA2B;IAC3B,wBAAwB;IACxB,4BAA4B;;AAEhC;;;;AAIA;;;;;GAKG;;AAEH;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;;;AAGpB;;;;;AAKA;IACI,oCAAoC;;;AAGxC","sourcesContent":[".thanks_container{\n    width: 100%;\n    /* border: 1px solid red; */\n    height: 100%;\n    /* background-image: url(../../icons/hurray.png); */\n    background-position: bottom;\n    background-size: contain;\n    background-repeat: no-repeat;\n    \n}\n\n\n\n/* .thanks_container > div img{\n    width: 100%;\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n} */\n\n.thanks_statement p{\n    text-align: center;\n}\n.thanks_statement p:nth-child(1){\n    color: black;\n    font-size: 17px;\n    font-weight: 600;\n    \n\n}\n\n\n\n\n.thanks_button button{\n    background-color: #0094ff !important;\n    \n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thanks_container": `seller_register_thanks_container__0S7GE`,
	"thanks_statement": `seller_register_thanks_statement__Z-z+p`,
	"thanks_button": `seller_register_thanks_button__14Q6P`
};
export default ___CSS_LOADER_EXPORT___;
