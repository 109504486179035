import ThemeMaker from "../../components/thememaker";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { checkLogin } from "../../store/features/login/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import request from "../../icons/request.png"
import producticon from "../../icons/product.png"
import sellericon from "../../icons/seller.png"
import ordericon from "../../icons/order.png"
import sellerDetails from "../../icons/sellerDetailsss.png"
// import revenue from "../../icons/sellerDetails.png"  
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import styles from "./dashboard.module.css"
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
// import { baseUrlDefiner } from "../../utils/config";
const { apiEndpoint } = config;
var $ = require('jquery');

const Dashboard = () => {
    const [Dashboard, setDashboard] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const history = useHistory()
    const [sidebarShow, setsidebarShow] = useState(checkstatus())
    function checkstatus() {
        const token: any = sessionStorage.getItem("us_st_d");
        const tokenDecode: any = jwt_decode(token)
        // console.log("tokendecode from siebar---->", tokenDecode)

        return tokenDecode.data.user.approve_status
    }
    const decode = tokenDecode();
    // console.log("token decode-->", decode)
    const logouthandler = () => {
        sessionStorage.removeItem('us_st_d');
        history.push('/');
    }
    const { data } = decode;
    const id = data.user.seller_id;
    const role_id = data.user.role_id;
    const user_id = data.user.id;
    const dat = useSelector((state: any) => state.loginReducer);
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const dashboardListing = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/total_countdata`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                     "ens":"multisellers.thesellerapp.com"
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id
                }
            });

            if (data.status === 200) {
                setLoading(false);
                setDashboard(Object.values(data.data));
            }
            // if (data.status == "Token is Expired") {
            //     logouthandler()
            // }
        } catch (error) {
        }
    }
    const checkServer = async () => {
        // let apiEndpoint =  baseUrlDefiner();
        try {
            const  data  = await axios({
                url: `https://multisellers.thesellerapp.com/v1/heritage_check`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    "Ens":"multisellers.thesellerapp.com"
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id
                }
            });

            console.log(data)
        } catch (error) {
        }
    }

    useEffect(() => {
        dashboardListing();
        checkServer();
    }, [])


    let paths: any;
    if (id == 1) {
        paths = [
            { path: "/masterproductlist" },
            { path: "/sellerlist" },
            { path: "/orderlist" },
            { path: "/nonapprovedproducts" },

        ]
    } else {
        paths = [
            { path: "/" },
            { path: "/orderlist" },
            { path: "/catalog" },
            { path: "/" },

        ]
    }



    return (

        <ThemeMaker>
            {sidebarShow == 1 ?
             <div className={`container-fluid  ${styles.mobile_dashboard}`}>
                <div className={`d-sm-flex align-items-center justify-content-between my-4 ${styles.heading}`}>
                    <h1 className="h3 mb-0 d-flex" style={{ gap: "15px" }}>
                        {/* <i className="fa fa-home" aria-hidden="true" ></i>  */}
                        Dashboard</h1>
                    {/* <span>Dashboard & Statics</span> */}
                </div>

                <div className="row m-0" style={{ gap: "10px", width: "100%" }}>
                    {isLoading ? <div className="loader-wrapper"><div className="loader"></div></div> : null}

                    {Dashboard.map((item, index) => {

                        // const abc = paths.find((xyz: any, i: any) => i == index)
                        // if (id > 1 && index === 0) {
                        //     return null;
                        // }

                        return <div className={styles.dashbordCards} key={index}>
                            <div className={`card border-left-primary shadow h-100 py-3 ${styles.cardSttyle}`}>
                                <div className="card-body" >
                                    <div className="row no-gutters align-items-center">

                                        <div className="col mr-2">
                                            {index === 0 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>{id === 1 ? "Total Product" : "Total Order"}</div> : null}
                                            {index === 1 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>{id === 1 ? "Total Seller" : "Total Product"}</div> : null}
                                            {index === 2 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>{id === 1 ? "Total Order" : "Total Revenue"}</div> : null}
                                            {index === 3 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}> {id === 1 ? "Open Tickets" : "Open Tickets"}</div> : null}
                                            {/* {(index === 4 && (id > 1 || data.user.parent_id > 1)) ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>Total Revenue </div> : null}
                                            {(index === 4 && id == 1) ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>Open Tickets </div> : null}
                                            {index === 5 ? <div className={`text-uppercase mb-1 ${styles.cardHeading}`}>Open Tickets </div> : null} */}
                                            <div className={`h5 mb-0 font-weight-bold  ${styles.count}`} >{item}</div>

                                        </div>
                                        <div className={`col-auto ${styles.iconColor}`}>
                                            {index === 0 ? <>{id === 1 ? <img src={producticon} alt="" /> : <img src={ordericon} alt="" />} </> : null}
                                            {index === 1 ? <>{id === 1 ? <img src={sellerDetails} alt="" style={{ width: "60.2995px", height: "61.1849px" }} /> : <img src={producticon} alt="" />} </> : null}
                                            {index === 2 ? <>{id === 1 ? <img src={ordericon} alt="" /> : <img src={sellericon} alt="" />} </> : null}
                                            {index === 3 ? <PendingActionsIcon style={{ width: "60px", height: "70px", color: "#2392ff" }} /> : null}
                                            {/* {(index === 4 && (id > 1 || data.user.parent_id > 1)) ? <img src={ordericon} alt="" /> : null}
                                            {(index === 4 && id == 1) ? <PendingActionsIcon style={{ width: "60px", height: "70px", color: "#2392ff" }} /> : null}
                                            {(index === 5) ? <PendingActionsIcon style={{ width: "60px", height: "70px", color: "#2392ff" }} /> : null} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                    }
                </div>
            </div> : <div style={{ minHeight: "90vh" }} className="d-flex justify-content-center align-items-center">
                <h5 className="text-center">Approval Status is pending</h5> </div>}
        </ThemeMaker >


    )
}

export default Dashboard; 
