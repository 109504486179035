import React, { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import axios from 'axios';
import Cookies from 'js-cookie';
import { tokenDecode } from '../../utils/helper';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment"
import { toast } from 'react-toastify';
export default function Discount() {
    const [iopen, setiopen] = React.useState(false);
    const [productlist, setproductlist] = useState([]);
    const [extraaStates, setExtraaStates] = useState({
        loading: false,
        searchedValue: ""
    })
    const handleimageClose = () => setiopen(false);
    const handleImageOpen = () => setiopen(true);
    const smstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        minHeight: 400,
        maxHeight: 700,
        overflow: "scroll",
        color: "black",
        borderRadius: "15px"
    };
    const decoded = tokenDecode();
    let current_sellerid = decoded.data.user.seller_id;
    const { apiEndpoint, baseNodeapiUrl } = config;

    useEffect(() => {
        if (extraaStates.searchedValue == "") {
            getDiscountList()
        } else {

            let timer = setTimeout(() => {
                let allItems = [...productlist];
                console.log(allItems)
                let ItemSearchedFor = allItems.filter(item => item?.offer_id?.toLowerCase().trim().includes(
                    extraaStates?.searchedValue?.toLowerCase()?.trim()
                ));
                setproductlist([...ItemSearchedFor])
            }, 1000)

            return () => clearTimeout(timer)

        }

    }, [extraaStates?.searchedValue])

    async function getDiscountList() {
        setExtraaStates((prev) => { return { ...prev, loading: true } })
        try {
            const { data } = await axios({
                url: `${baseNodeapiUrl}/cms/offer/get_offer`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    seller_id: current_sellerid,
                },
            });

            if (data) {
                setproductlist(data.data);
                setExtraaStates((prev) => { return { ...prev, loading: false } })
            } else {
                setExtraaStates((prev) => { return { ...prev, loading: false } })
                toast.error("Something Went Wrong!")
            }
        } catch (error) {
            setExtraaStates((prev) => { return { ...prev, loading: false } })
            toast.error("Server Not Working, please try again later")
            console.log(error);
        }
    }

    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row, count) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Offer Id",
            Footer: "Offer Id",
            accessor: "offer_id",
            sticky: "left"
        },
        {
            Header: "Offer Type",
            Footer: "Offer Type",
            accessor: "offer_type",
            sticky: "left"
        },
        {
            Header: "Start Date & Time",
            Footer: "Start Date",
            accessor: (row, count) => {
                return moment(row.start_time).format("YYYY-MM-DD  hh:mm:ss ")
            },
            sticky: "left"
        },
        {
            Header: "End Date & Time",
            Footer: "End Date",
            accessor: (row, count) => {
                return moment(row.end_time).format("YYYY-MM-DD hh:mm:ss")
            },
            sticky: "left"
        },





    ];

    return (
        <>
            <ThemeMaker>
                <div style={{ minHeight: "90vh" }}>
                    <div className="col-md-12 mt-2">
                        <div className="card shadow mb-4 h-100">
                            <div className="card-header py-3 ">
                                <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between w-100">
                                    <span >Discount</span>

                                    <div className='d-flex' style={{ gap: "20px" }}>

                                        <input type="text" className='form-control' placeholder='Enter Offer Name' onChange={(e) => {
                                            setExtraaStates((prev) => {
                                                return { ...prev, searchedValue: e.target.value }
                                            })
                                        }
                                        } />


                                        <span className='badge badge-primary' style={{ cursor: "pointer" }} onClick={handleImageOpen}> Create Discount</span>

                                    </div>
                                </h6>
                            </div>
                            <div className='card-body' style={{ minHeight: "80vh" }}>

                                {!extraaStates.loading ? <Paginated data={productlist} columns={COLUMNS} /> : <>
                                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>
                                </>}



                            </div>
                        </div>
                    </div>

                    {/* Modals */}
                    <div>

                        <Modal
                            open={iopen}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={smstyle}>


                                <div className="card-header py-3 ">
                                    <h6 className="m-0 font-weight-bold text-left d-flex justify-content-between w-100">
                                        <span style={{ color: "black" }}>Discount</span>

                                        <CloseIcon style={{ cursor: "pointer" }} onClick={handleimageClose} />


                                    </h6>
                                </div>
                                <div className='card-body p-0 m-0' >

                                    <div className='mt-2'>

                                        <Link to="/discountbyOrderAmount?type=amountofforder" style={{ color: "black" }}>

                                            <div className='p-2 ps-2 m-0 d-flex justify-content-between align-items-center' style={{ borderBottom: "1px solid grey", cursor: "pointer" }}>
                                                <div>
                                                    <p className='p-0 m-0 text-md'>Amount Off Order</p>
                                                    <span className='text-sm'>Order Discount</span>
                                                </div>

                                                <ArrowForwardIosIcon style={{ color: 'gray' }} />
                                            </div>

                                        </Link>


                                        <Link to="/discountbyOrderAmount?type=buyXgetY" style={{ color: "black" }}><div className='p-2 ps-2 m-0 d-flex justify-content-between align-items-center' style={{ borderBottom: "1px solid grey", cursor: "pointer" }}>
                                            <div>
                                                <p className='p-0 m-0 text-md'>Buy X Get Y</p>
                                                <span className='text-sm'>Product Discount</span>
                                            </div>

                                            <ArrowForwardIosIcon style={{ color: 'gray' }} />
                                        </div>
                                        </Link>

                                    </div>



                                </div>


                            </Box>
                        </Modal>

                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}
