import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../../store/features/login/loginSlice";
import { useHistory } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import image from '../images/1.png';
import img from '../images/2.png';
import img3 from '../images/3.png';
import Cookies from "js-cookie";
// import logo from '../images/logo.png';
import logo from "../../icons/xpressbazarlogo.jpg";
import ideaMasterLogo from "../../icons/ideamaster.png";
import avysh_logo from "../../icons/avysh_logo.jpg"


const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch<any>();
    const loginData = useSelector((state: any) => state.loginReducer);
    // console.log("loginData--->", loginData);
    const { loading } = loginData;
    const [user, setUser] = useState({
        email: "",
        password: ""
    });
    const [errorMessage, setErrorMessage] = useState({
        email: true,
        password: true
    });

    const loginMannage = async () => {
        // console.log("user---");
        dispatch(checkLogin(user));
    }

    const loginChecker = (e: any) => {
        e.preventDefault();
        if (errorMessage.email || errorMessage.password) {
            toast.error("Email and password is required !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            loginMannage();
        }
    }
    const onChangeHandler = (e: any) => {
        setUser({ ...user, [e.target.name]: e.target.value });
        setErrorMessage({ ...errorMessage, [e.target.name]: e.target.value ? false : true })
    }
    const redirectHandler = () => {
        try {
            const decode = tokenDecode();
            const currentTime = Date.now() / 1000;
            if (decode) {
                history.push('/dashboard');
            } else {
                history.push('/');
            }
        } catch (error) {
            history.push('/');
        }
    }
    useEffect(() => {
        redirectHandler();
    }, [loginData]);

    let fullUrl = window.location.host;
    


    return (
        <>
            {/*  */}
            <div className="">
                {/* <div className="row justify-content-center"> */}
                {/* <div className="col-md-6 mt-4 mb-4">
                        <div className="card o-hidden border-5 shadow-lg my-5">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-center mb-3">
                                                <img src={logo} style={{ height: "65px" }} alt="logo here" />
                                            </div>
                                            <form className="user mb-4 mt-2" onSubmit={(e) => loginChecker(e)} >
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user"

                                                        placeholder="Enter Email Address..."
                                                        name="email"
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user"
                                                        placeholder="Password"
                                                        name="password"
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                                {
                                                    loading ? <button className="btn btn-dark btn-user btn-block mt-4 mb-4">
                                                        Loading...
                                                    </button> : <button className="btn btn-dark btn-user btn-block mt-4 mb-4">
                                                        Sign In
                                                    </button>
                                                }
                                            </form>
                                            <hr />
                                            <div className="text-center ">
                                                <Link className="small" to="/forget-password">Forgot Password?</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                <section className='d-flex align-items-center' style={{ height: '100vh', backgroundColor: '#dfe7f0' }}>
                    <div className="container main_container">
                        <div className="row col-sm-12 w-100 m-auto shadow-regular align-items-center bg-white rounded rounded-4 shadow-sm p-3 mb-5 flex_direction">
                            <div className="col-lg-6 col-sm-12 col-md-6 p-0" >
                                <div className='image_wrapper position-relative'>
                                    <div className="image1">
                                        <img className='w-100' src="img/loginGround.png" alt="loginGround" />
                                    </div>
                                    <div className='imagesSetup'>
                                        <img className='w-100' src="img/loginCard.png" alt="loginCard" />
                                    </div>
                                    <div className='imagesSetup'>
                                        <img className='w-100' src="img/loginCardImage.png" alt="loginCardImage" />
                                    </div>
                                </div>
                            </div>
                            <div className='loginFormat col-lg-6 col-sm-12 col-md-6 p-5' id="padding">
                                {fullUrl === "multipleselleradmin.thesellerapp.com" && <img className='d-block m-auto w-50' src={ideaMasterLogo} alt="" />
                                }
                                {fullUrl === "preprodadmin.xpressbaazaar.com" && <img className='d-block m-auto w-50' src={logo} alt="" />
                                }
                                {(fullUrl === "localhost:3000" || fullUrl === "localhost:3001" || fullUrl === "localhost:3002") && <img className='d-block m-auto w-50' src={avysh_logo}  alt="" />
                                }
                                {(fullUrl === "multisellersadmin.thesellerapp.com" ) && <img className='d-block m-auto w-50' src={avysh_logo} alt="" />
                                }
                                <form className="user mb-4 mt-2" onSubmit={(e) => loginChecker(e)} >
                                    <div className='position-relative'>
                                        <div className='form-group mt-3'>
                                            <input type="email" className="form-control form_padding"
                                                placeholder="Enter Email Address..."
                                                name="email"
                                                onChange={(e) => onChangeHandler(e)}
                                            />
                                        </div>
                                        <span className='Icons'>
                                            <PersonOutlineIcon />
                                        </span>
                                    </div>
                                    <div className='position-relative'>
                                        <div className='form-group mt-3 mb-2'>
                                            <input type="password" className="form-control form_padding"
                                                placeholder="Password"
                                                name="password"
                                                onChange={(e) => onChangeHandler(e)}
                                            />
                                        </div>
                                        <span className='Icons'>
                                            <LockOpenIcon />
                                        </span>
                                    </div>
                                    <div className="">
                                        <Link className="small" to="/forget-password">Forgot Password?</Link>
                                    </div>
                                    <div className='login_btn mt-3 rounded rounded-3'>
                                        {/* <button className='btn text-white w-100'>Login Now</button> */}
                                        {
                                            loading ? <button className="btn text-white w-100">
                                                Loading...
                                            </button> : <button className="btn text-white w-100">
                                                Sign In
                                            </button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default Login;
