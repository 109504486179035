// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_search_input_container__W7mP6 {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .style_search_select__f-jhT,
  .style_search_input__W2KKw {
    border: none !important;
  }`, "",{"version":3,"sources":["webpack://./src/pages/catalog/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;;IAEE,uBAAuB;EACzB","sourcesContent":[".search_input_container {\n    display: flex;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .search_select,\n  .search_input {\n    border: none !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_input_container": `style_search_input_container__W7mP6`,
	"search_select": `style_search_select__f-jhT`,
	"search_input": `style_search_input__W2KKw`
};
export default ___CSS_LOADER_EXPORT___;
