import { useState, useEffect, useCallback } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import "../NewmasterPage/Master.css"
import '../../components/table.css';
import styles from "../transaction/transaction.module.css"
import searchicon_black from "../../icons/searchicon_black.png"
import style from "../../components/Search&Options/Search&Options.module.css"
import Order from "../order";
import moment from "moment"
import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import "../../index.css"
import { baseUrlDefiner, NodebaseUrlDefiner } from "../../utils/config";
//import { toast } from "react-toastify";
const { apiEndpoint, baseNodeapiUrl } = config;

var $ = require('jquery');

// const badgeMaker: any = {
//     "Created": (order_state: any) => <span className="badge badge-primary">{order_state}</span>,
//     "Shipped": (order_state: any) => <span className="badge badge-warning">{order_state}</span>,
//     "Delivered": (order_state: any) => <span className="badge badge-success">{order_state}</span>,
//     "Canceled": (order_state: any) => <span className="badge badge-danger">{order_state}</span>,
// }

const OrderList = () => {
    const decoded: any = tokenDecode();
    const current_seller_id = decoded?.data?.user?.seller_id
    const [orderList, setOrderList] = useState([]);
    const [sorderList, setSorderList] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const [updateOrder_, setUpdateOrder_]: any = useState({
        order_id: "",
        tracking_url: "",
        order_state: ""
    })
    const [orderDetail, setorderDetail]: any = useState([]);
    const [_orderDetail, _setorderDetail]: any = useState({
        billing_address: "",
        contact_number: "",
        created_at: "",
        email: "",
        id: '',
        name: "",
        order_id: "",
        order_state: "",
        provider_id: "",
        shipping_address: "",
        status: "",
        total_price: 0
    });
    const rawData = {
        provider_id: ''
    }
    const Sstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "10px",
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
    };
    const [toggle, setToggle] = useState(false);
    const [searchData, setSearchData]: any = useState(rawData);

    const [valuename, setValuename] = useState("");
    const [valueid, setValueid] = useState("");
    const [valueemail, setValueemail] = useState("");
    const [valuecontact, setValuecontact] = useState("");
    const [valuestatus, setValuestatus] = useState("");
    const [valueamount, setValueamount] = useState("");
    const [valuedate, setValuedate] = useState("");
    const [orderisLoading, setorderisLoading] = useState(false);
    const [allorders, setallorders] = useState([])
    const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
    const [fraudModalOpen, setfraudModalOpen] = useState(false);
    const [isAnalyzing, setisAnalyzing] = useState(false)
    const [fraudLevel, setfraudLevel] = useState("");
    const [selectedCities, setSelectedCities] = useState<any>([]);
    const [singleSellerSelect, setsingleSellerSelect] = useState("")
    const { data } = decoded;
    const { permission: { seller } } = data;
    const [filterDaata, setfilterDaata] = useState({
        seller_id: "",
        orderCurrentStatus: "",
        endDate: ""
    })
    const [fraudDaata, setfraudDaata] = useState({
        provider_id: "",
        start_date: "",
        end_date: "",
        limit_count: "",
        limit_price: ""
    })

    function fraudChangeHandler(e: any) {
        setfraudDaata((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    var count = 1;
    function handleShipementModalClose() {
        setshipmentModalOpen(false)
    }
    function handleFraudModalClose() {
        setfraudModalOpen(false)
    }


    const fetchSellerList = async () => {
        let apiEndpoint =  baseUrlDefiner();
        let endpoint;
        if (decoded?.data?.user?.seller_id === 1) {
            endpoint = "getallorder?page=1"
        } else {
            endpoint = "getsellerordercompletelist"
        }
        setorderisLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/${endpoint}`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    seller_id: decoded?.data?.user?.seller_id,
                }
            });
            // console.log("order data -> ", data);
            if (data.status === 200) {
                setorderisLoading(false);
                setOrderList(data.data);

            }
        } catch (error) {
            console.log("error", error);

            setorderisLoading(false);
        }
    }

    // const [exceldataLoading, setexcelDataLoading] = useState(false)
    const allorderList = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            // setexcelDataLoading(true)
            const { data } = await axios({
                url: `${apiEndpoint}/getallstatusorder`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    seller_id: decoded?.data?.user?.seller_id,
                }
            });
            // console.log("order data -> ", data);
            if (data.status === 200) {

                setallorders(data.data);
                // setexcelDataLoading(false)
            }
        } catch (error) {
            console.log("error", error);
            // setexcelDataLoading(false)
        }
    }

    const updateOrder = async (order_id: any) => {
        let apiEndpoint =  baseUrlDefiner();
        try {

            const { data } = await axios({
                url: `${apiEndpoint}/updateorderstatus`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: updateOrder_
            });
            // console.log(data, "datadata");

            if (data.message === "flag1") {
                toast.success("Order updated !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // fetchSellerList();
            } else {
                toast.error("Something went wrong !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (error) {
            console.log("update error", error);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            // window.location.reload();

        }
    }

    const getSellerList = async () => {
        let apiEndpoint =  baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                }
            });
            if (data.status === 200) {
                // console.log(data.data)
                setSellerList(data.data.filter((item: any) => item.approve_status === 1));
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        setValuename("");
        setValueid("");
        setValueemail("");
        setValuecontact("");
        setValuestatus("");
        setValueamount("");
        setValuedate("");
        var optionvalue = e.target.value;
        if (optionvalue) {
            try {
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbyseller`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        provider_id: optionvalue
                    }

                });

                if (data.status === 200) {
                    // console.log(data);
                    setOrderList(data.data);
                    setSorderList(data.data);
                    setToggle(true)
                }


            } catch (error) {
                console.log("error", error);
            }
        }
        else {
            setOrderList(sorderList);
        }
    };
    const inputeHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {
                const {
                    provider_id
                } = searchData;
                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        provider_id: { searchData },
                        name: optionvalue
                    }

                });


                if (data.status === 200) {
                    // console.log(data);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputeeHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        name: optionvalue
                    }

                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputoHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data:
                    {
                        // provider_id: { searchData },
                        order_id: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    // console.log("line 332", data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
            fetchSellerList()
        }
    };
    const inputooHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        order_id: optionvalue
                    }
                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputemHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        // console.log(optionvalue)
        setValueemail(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data:
                    {
                        provider_id: { searchData },
                        email: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    // console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputeemHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValueemail(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        email: optionvalue
                    }
                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputcHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuecontact(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data:
                    {
                        provider_id: { searchData },
                        contact_number: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    // console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputccHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuecontact(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        contact_number: optionvalue
                    }
                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputpHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        // console.log(optionvalue)
        setValuestatus(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data:
                    {
                        provider_id: { searchData },
                        status: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    // console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }


    };


    const inputOrderStatusHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        // console.log(optionvalue)
        setValuestatus(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data:
                    {
                        provider_id: searchData?.provider_id,
                        order_state: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    // console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }


    };
    const inputppHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuestatus(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        status: optionvalue
                    }
                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };

    const inputOOrderStatusHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuestatus(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        order_state: optionvalue
                    }
                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputaHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data:
                    {
                        provider_id: { searchData },
                        order_total: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    // console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    }
    const inputaaHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        order_total: optionvalue
                    }
                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    }
    const inputdHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuedate(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data:
                    {
                        provider_id: { searchData },
                        created_at: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    // console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    }
    const inputddHandler = async (e: any) => {
        let apiEndpoint =  baseUrlDefiner();
        const optionvalue = e.target.value;
        setValuedate(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: {
                        created_at: optionvalue
                    }
                });

                if (data.status === 200) {
                    // console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    }

    const COLUMNS = [
        {
            Header: "Serial No.",
            Footer: "Serial No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Order ID",
            Footer: "Order ID",
            accessor: "order_id",
            sticky: "left"
        },
        {
            Header: "Order Status",
            Footer: "Order Status",
            accessor: "order_state",
            sticky: "left",

        },
        {
            id: 'sellerNameColumn',
            Header: () => (
                <div>
                    <select name="" value={singleSellerSelect} id="" style={{ color: "white", border: "none", width: "125px", textAlign: 'center' }} onChange={(e) => {
                        setsingleSellerSelect(e.target.value);
                        setfilterDaata((prev) => (
                            { ...prev, seller_id: e.target.value }
                        ))
                    }}>
                        <option value="" style={{ background: "#001ad5" }} disabled > Seller Name</option>
                        {
                            sellerList.map((item: any) => (
                                <option value={item?.seller_id} key={item?.seller_id} style={{ background: "#001ad5" }}>{item?.name}</option>
                            ))
                        }
                    </select>

                </div>
            ),
            Footer: "Seller Name",
            accessor: (row: any) => {
                let sellerName = row?.address?.start?.location?.descriptor?.name
                if (sellerName) {
                    return sellerName
                } else if(row?.fulfillments){
                    const parsedData = JSON.parse(row?.fulfillments);
                    const selletName = parsedData[0]?.start?.location?.descriptor?.name

                    return selletName
                }else{
                    return ""
                }
                // return "Hello"
            },
            sticky: "left"
        },
        {
            Header: "Ageing (in days)",
            Footer: "Ageing",
            accessor: (row: any) => {
                if (row.order_state === "Completed" || row.order_state === "Cancelled") {

                    var date1 = moment(row.created_at);
                    var date2 = moment(row.updated_at);
                    var days = date2.diff(date1, 'days')
                    return days
                } else {

                    var date1 = moment(row.created_at);
                    var date2 = moment(new Date());
                    var days = date2.diff(date1, 'days')
                    return days
                }

            },
            sticky: "left"
        },
        {
            Header: "Customer name",
            Footer: "Customer name",
            accessor: "name",
            sticky: "left"
        },
         {
            Header: "Buyerapp name",
            Footer: "Buyerapp name",
            accessor: "bap_id",
            sticky: "left"
        },
        {
            Header: "Total (In Rs)",
            Footer: "Total",
            accessor: "order_total",
            sticky: "left"
        },
        {
            Header: "Payment Status",
            Footer: "Payment Status",
            accessor: (row: any) => {
                return <>{row.status === "NOT-PAID" ? "COD" : "PAID"}</>
            },
            sticky: "left"
        },
        {
            Header: "Created Date",
            Footer: "Created Date",
            accessor: (row: any) => {
                if (row?.created_at) {
                    return moment.utc(row?.created_at).format("DD-MM-YYYY  HH:mm:ss")
                } else {
                    return ""
                }
            },
            sticky: "left"
        },
        {
            Header: "Tracking URL",
            Footer: "Tracking URL",
            accessor: "tracking_url",
            sticky: "left"
        },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                return <div className="d-flex"><Link to={`../../orderCompleteDetail/${row.order_id}`} className="btn btn-success btn-circle"><i className="fas fa-pen"></i></Link>
                    <span className="btn btn-warning btn-circle" onClick={() => getorderDetailsByOrderID(row.order_id)}> <i className="fas fa-eye"  ></i> </span>   </div>
            },
            sticky: "left"
        },

    ];
    count++
    useEffect(() => {
        fetchSellerList();
        getSellerList();
        allorderList()
        // getAllOrderList();
    }, [])
    useEffect(() => {
        if (selectedCities.length > 0 || singleSellerSelect !== "") {
            applyFilterHandler()
        } else {
            fetchSellerList()
        }

        // getAllOrderList();
    }, [selectedCities, singleSellerSelect])


    const exportToExcel = (orders: any) => {
        // console.log("csv orders----->", orders)
        // Define custom column headers for the Excel file
        const headers = [
            'S.No', "Order Id", "Created At", "Seller Name", "Order State", " Customer Name", " Customer Email", " Customer Contact", "Status", "Item Count", "Order Total"

            // Add more custom headers as needed
        ];

        // // Prepare data with just the values to be displayed in the columns
        const data = orders.map((order: any, index: any) => {

            let orderCreatedDate = moment(order?.created_at).format("DD-MM-YYYY hh:mm:ss")
            let orderupdateddDate = moment(order?.updated_at).format("DD-MM-YYYY hh:mm:ss")
            let sellerName = order?.address?.start?.location?.descriptor?.name
            let sellerEmail = order?.address?.end?.contact?.email
            let sellerContact = order?.address?.end?.contact?.phone
            function calculateAge(order: any) {
                if (order.order_state === "Completed" || order.order_state === "Cancelled") {

                    var date1 = moment(order.created_at);
                    var date2 = moment(order.updated_at);
                    var days = date2.diff(date1, 'days')
                    return days
                } else {

                    var date1 = moment(order.created_at);
                    var date2 = moment(new Date());
                    var days = date2.diff(date1, 'days')
                    return days
                }
            }
            return {
                'S.No': index + 1,
                "Order Id": order.order_id,
                "Created At": orderCreatedDate,
                "Updated At": orderupdateddDate,
                "Ageing": calculateAge(order),
                "Seller Name": sellerName,
                "Order State": order?.order_state,
                " Customer Name": order.name,
                " Customer Email": sellerEmail,
                " Customer Contact": sellerContact,
                "Status": order?.status,
                "Item Count": order?.item_count,
                "Order Total": order?.order_total

            }
        });

        // // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data, { header: headers });

        // // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');

        // // Generate a unique name for the Excel file (you can customize this as needed)
        const fileName = `allorders_${new Date().getTime()}.xlsx`;

        // // Export the workbook as an Excel file
        XLSX.writeFile(wb, fileName);
    };

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month: string | number = today.getMonth() + 1;
        let day: string | number = today.getDate();

        // Add leading zero if month or day is a single digit
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }

    async function filterChangeHandler(e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) {
        if (e.target.name !== "orderCurrentStatus") {
            setfilterDaata((prev) => {
                return { ...prev, [e.target.name]: e.target.value }
            })
        } else {
            setfilterDaata((prev: any) => {
                return { ...prev, orderCurrentStatus: [e.target.value] }
            })
        }

    }

    async function applyFilterHandler() {
        let apiEndpoint =  baseUrlDefiner();
        let payload;
        if (selectedCities.length > 0) {
            payload = {
                date: filterDaata.endDate,
                order_status: [...selectedCities],
                seller_id: filterDaata.seller_id
            }
        } else {
            payload = {
                date: filterDaata.endDate,
                order_status: filterDaata.orderCurrentStatus,
                seller_id: filterDaata.seller_id
            }
        }
        setorderisLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/ordersfilter`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    // date: filterDaata.endDate,
                    // order_status: filterDaata.orderCurrentStatus,
                    // seller_id: filterDaata.seller_id
                    ...payload
                }

            });
            if (data.status === 200) {
                setOrderList(data.data);
                setshipmentModalOpen(false)
                setorderisLoading(false);

            }
        } catch (error) {
            setorderisLoading(false);
            console.log("error", error);
        }
    }

    const preventMinus = (e: any) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    async function analyzerFunction() {
        let apiEndpoint =  NodebaseUrlDefiner();
        setfraudLevel("")
        try {
            setisAnalyzing(true)
            const { data } = await axios({
                url: `${apiEndpoint}/analytics/fraudAnalytics_seller_order_count`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    ...fraudDaata
                }

            });
            if (data === "Low") {
                setfraudLevel("33%")
            }
            if (data === "Medium") {
                setfraudLevel("66%")
            }
            if (data === "High") {
                setfraudLevel("99%")
            }

            setisAnalyzing(false)
        } catch (error) {
            setisAnalyzing(false)
            console.log("error", error);
        }
    }

    const steps = [
        'Low',
        'Medium',
        'High',
    ];


    const allOptions = [
        "Accepted", "In-progress", "Completed", "Cancelled"
    ]
    const handleAutocompleteChange = (
        event: React.ChangeEvent<{}>,
        newSelectedCities: any
    ) => {
        setSelectedCities(newSelectedCities);
    };

    const [orderDetails, setOrderDetails] = useState(null);
    const [orderUpdateModal, setorderUpdateModal] = useState(false);
    const [orderStatus, setorderStatus] = useState([
        "Created",
        "Accepted",
        "In-progress",
        "Completed",

    ]);

    const [orderState, setOrderState] = useState({
        order_id: "",
        order_state: "",
        fulfillment_state: "",
        tracking_url: ""
    })

    function handleOrderUpdateModalClose() {
        setOrderState({
            order_id: "", order_state: "", fulfillment_state: "", tracking_url: ""
        });
        setorderStatus(["Created", "Accepted", "In-progress", "Completed"])
        setshipmentModalOpen(false);
        setOrderDetails(null)
    }

    async function getorderDetailsByOrderID(order_id: string) {
        let apiEndpoint =  baseUrlDefiner();
        // let apiEndpoint =  baseUrlDefiner();
        setOrderState((prev) => {
            return { ...prev, order_id: order_id }
        })
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerorder`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    order_id,
                },
            });

            // console.log("setOrderDetails--------->", data?.data[0]?.order_state);

            if (data.status == 200) {
                setOrderDetails(data);
                setOrderState((prev) => {
                    return { ...prev, order_state: data?.data[0]?.order_state, fulfillment_state: data?.products[0]?.fulfillment_state, tracking_url: data?.products[0]?.tracking_url }
                })
                if (data.data[0].order_state !== "") {

                    const index = orderStatus.findIndex((item) => item === data.data[0].order_state);

                    if (index !== -1) {
                        let slicedStatus = orderStatus.slice(index);

                        setorderStatus([...slicedStatus]);
                    }
                }
            } else {
                toast.error("Error getting order details!")
            }
            setorderUpdateModal(true)
        } catch (err) {
            toast.error("Something Went Wrong!!")
        }

    }

    const updateThisOrder = async (e: any) => {
        console.log(orderState)
        let apiEndpoint =  baseUrlDefiner();
        // let apiEndpoint =  baseUrlDefiner();
        try {

            const { data } = await axios({
                url: `${apiEndpoint}/updateorderstatus`,
                method: "POST",
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: orderState,
            });


            if (data.status === 200) {

                if (orderState.order_state === "Created") {
                    toast.success("Order Created successfully!");
                } else if (orderState.order_state === "Accepted") {
                    toast.success("Order Accepted successfully!");
                } else if (orderState.order_state === "In-progress") {
                    toast.success("Order In-progress!");
                } else if (orderState.order_state === "Completed") {
                    toast.success("Order Completed successfully!");
                } else if (orderState.order_state === "Cancelled") {
                    toast.success("Order Cancelled successfully!");
                }
                // fetchSellerList()
            } else {
                toast.error("Something went wrong !");
            }
        } catch (error) {
            toast.error("Something went wrong 500 !")
        }
    };

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">

                            {(current_seller_id === 1) ? <div className="card-header p-2">
                                <div className={`d-flex justify-content-between p-2 align-items-center ${styles.headerContainer}`}>
                                    <h6 className="m-0 font-weight-bold text-primary text-left" style={{ width: "100px" }}>
                                        Order list
                                    </h6>
                                    {seller?.read ?
                                        <div className="d-flex align-items-center w-100">
                                            {/* <div className={styles.sellerListOptions}><b>Filter By Seller: </b><select name="provider_id" id="" onChange={(e: any) => inputHandler(e)} >
                                                <option value="">Select Seller</option>
                                                {
                                                    sellerList.map((item: any) => (
                                                        <option value={item?.provider_id} key={item?.provider_id}>{item?.name}</option>
                                                    ))
                                                }

                                            </select>
                                            </div> */}

                                            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 ml-2 mt-sm-0 w-100" style={{ gap: "11px" }}>
                                                <div className="d-flex justify-content-end bg-white" >

                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        id="checkboxes-tags-demo"
                                                        options={allOptions}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option: any) => option}
                                                        onChange={handleAutocompleteChange}
                                                        className="multiOrderStatusSelector"
                                                        value={selectedCities}
                                                        renderOption={(props, option: any, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option}
                                                            </li>
                                                        )}
                                                        // style={{
                                                        //     maxWidth: "400px", width: "100%"
                                                        //     // width: "400px"
                                                        // }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Select Order Status" placeholder="Search" />
                                                        )}
                                                    />
                                                </div>

                                                <input type="text" className="form-control  w-100 w-md-50 mt-2 mt-md-0" placeholder="Enter Order ID.." onChange={(e) => { seller?.read ? inputoHandler(e) : inputooHandler(e) }} />

                                                <div className="d-flex  align-items-center" style={{ gap: "11px" }}>
                                                    <button className="btn btn-primary commonBtn " onClick={() => {
                                                        setshipmentModalOpen(true)
                                                    }} style={{ letterSpacing: "1px" }}>Filters</button>

                                                    <button className="btn btn-primary commonBtn " disabled={allorders.length === 0 ? true : false} onClick={() => setTimeout(() => {
                                                        exportToExcel(allorders)
                                                    }, 2000)} style={{ width: "155px" }} >Export Orders </button>

                                                    <button className="btn btn-primary commonBtn d-none" onClick={() => {
                                                        setfraudModalOpen(true)
                                                    }} style={{ letterSpacing: "1px", width: "150px" }}>Fraud Analysis</button>

                                                </div>


                                            </div>


                                        </div>
                                        : ""}</div>
                                <div className={`search mt-1 d-none `}>
                                    <div className={`row w-100 d-flex m-0`} style={{ flexWrap: "wrap" }}>
                                        <div className={style.inputMain}>
                                            <img src={searchicon_black} alt="" />   <input type="search" name="order_id" placeholder="Search by order id" onChange={(e) => { seller?.read ? inputoHandler(e) : inputooHandler(e) }} value={valueid ? valueid : ""} />
                                        </div>

                                        <div className={`${style.inputMain} d-none`} >
                                            <img src={searchicon_black} alt="" />   <input type="search" name="name" placeholder="Search by name" onChange={(e) => { seller?.read ? inputeHandler(e) : inputeeHandler(e) }} value={valuename ? valuename : ""} />
                                        </div>

                                        <div className={`${style.inputMain} d-none`}>
                                            <img src={searchicon_black} alt="" />   <input type="search" name="email" placeholder="Search by email" onChange={(e) => { seller?.read ? inputemHandler(e) : inputeemHandler(e) }} value={valueemail ? valueemail : ""} />
                                        </div>

                                        <div className={`${style.inputMain} d-none`}>
                                            <img src={searchicon_black} alt="" />   <input type="search" name="contact_number" placeholder="Search by Contact" onChange={(e) => { seller?.read ? inputcHandler(e) : inputccHandler(e) }} value={valuecontact ? valuecontact : ""} />
                                        </div>


                                        <select name="payment_status" onChange={(e) => { seller?.read ? inputpHandler(e) : inputppHandler(e) }} value={valuestatus ? valuestatus : ""} className={style.selector} style={{ display: "none" }} >
                                            <option >Select Payment Status</option>
                                            <option value="NOT-PAID">Cod</option>
                                            <option value="pa">Paid</option>
                                        </select>
                                        <select name="order_status" onChange={(e) => { seller?.read ? inputOrderStatusHandler(e) : inputOOrderStatusHandler(e) }} value={valuestatus ? valuestatus : ""} className={style.selector} >
                                            <option >Select Order Status</option>
                                            <option value="Accepted">Accepted</option>
                                            <option value="Completed">Completed</option>
                                            <option value="In-progress">In-progress</option>
                                            <option value="Cancelled">Cancelled</option>
                                        </select>

                                        <div className={`${style.inputMain} d-none`}>
                                            <img src={searchicon_black} alt="" />    <input type="search" name="amount" className="d-none" placeholder="Search by Amount" onChange={(e) => { seller?.read ? inputaHandler(e) : inputaaHandler(e) }} value={valueamount ? valueamount : ""} />
                                        </div>

                                        <div className="mt-3 w-100">
                                            <span style={{ lineHeight: "34px" }} className={style.date}>Search By Date:</span> <input type="date" name="date" placeholder="Search by Date" onChange={(e) => { seller?.read ? inputdHandler(e) : inputddHandler(e) }} value={valuedate ? valuedate : ""} className={style.inputTypeDate} />
                                        </div>
                                    </div>
                                </div>

                            </div> : null}


                            <div className="card-body p-1" style={{ minHeight: "80vh" }} >
                                <div className="table-responsive ">

                                    {
                                        // decoded?.data?.user?.seller_id > 1 || (decoded?.data?.user?.seller_id == 0) ?
                                        orderisLoading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div> :
                                            <div >
                                                <div className="showdiv">
                                                    <Paginated data={orderList} columns={COLUMNS} />
                                                </div>
                                                <div className="hidediv">

                                                    {orderList.map((item: any) => {
                                                         let sellerName = item?.address?.start?.location?.descriptor?.name
                                                         if (sellerName) {
                                                             return sellerName
                                                         } else if(item?.fulfillments){
                                                             const parsedData = JSON.parse(item?.fulfillments);
                                                             const selletName = parsedData[0]?.start?.location?.descriptor?.name
                                         
                                                             return selletName
                                                         }else{
                                                             return ""
                                                         }

                                                        return <div className="border p-1">
                                                            <div className="d-flex  mt-1 align-items-center" style={{ gap: "10px" }}>
                                                                <span className="genericlabel" style={{ width: "28%" }} >Order ID</span>
                                                                <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_id}</span>
                                                            </div>
                                                            <div className="d-flex  mt-1" style={{ gap: "10px" }}>
                                                                <span className="genericlabel " style={{ width: "28%" }} >Order Status</span>
                                                                <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_state}</span>
                                                            </div>
                                                            <div className="d-flex mt-1" style={{ gap: "10px" }}>
                                                                <span className="genericlabel " style={{ width: "28%" }} > Seller Name</span>
                                                                <span className="" style={{ fontSize: "14px", width: '70%' }}>{sellerName}</span>
                                                            </div>
                                                            <div className="d-flex mt-1" style={{ gap: "10px" }}>
                                                                <span className="genericlabel " style={{ width: "31%" }}>Payment Status</span>
                                                                <span className="" style={{ fontSize: "14px", width: '69%' }}>{item?.status}</span>
                                                            </div>
                                                            <div className="mt-2">
                                                                <Link to={`../../orderCompleteDetail/${item.order_id}`}> <p style={{ width: "120px", fontWeight: 600, borderRadius: "17px", color: "white", background: "blue", padding: "6px", textAlign: "center" }} >Check Order</p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <>
                    <Modal
                        open={shipmentModalOpen}
                        // onClose={handleShipementModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Sstyle} className="col-md-3 w-75 w-md-25">

                            <div className="modal-content ">
                                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                    <p className="modal-title genericlabel  " style={{ letterSpacing: "3px" }}>Filters:</p>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <select name="seller_id" id="" className="form-control" value={filterDaata.seller_id} onChange={filterChangeHandler}>
                                            <option value="" disabled selected >Select Seller</option>
                                            {
                                                sellerList.map((item: any) => (
                                                    <option value={item?.seller_id} key={item?.seller_id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div className="mt-2">
                                        <select name="orderCurrentStatus" id="" className="form-control" value={filterDaata.orderCurrentStatus} onChange={filterChangeHandler}>
                                            <option value="" disabled selected >Select Order Status</option>
                                            <option value="Accepted" className="form-control">Accepted</option>
                                            <option value="Completed">Completed</option>
                                            <option value="In-progress">In-progress</option>
                                            <option value="Cancelled">Cancelled</option>
                                        </select>
                                    </div>

                                    <div className="mt-2">
                                        <input type="date" name="endDate" className="form-control" max={getCurrentDate()} value={filterDaata.endDate} onChange={filterChangeHandler} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={() => {
                                        fetchSellerList(); setshipmentModalOpen(false); setfilterDaata({
                                            seller_id: "",
                                            orderCurrentStatus: "",
                                            endDate: ""
                                        })
                                    }} >Clear Filter</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={applyFilterHandler} >Apply Changes</button>
                                </div>


                            </div>
                        </Box>
                    </Modal>
                </>

                {/* ------------------  FRAUD MODAL -------------------------- */}
                <>
                    <Modal
                        open={fraudModalOpen}
                        // onClose={handleShipementModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Sstyle} className="col-md-3 w-75 w-md-25">

                            <div className="modal-content ">
                                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                                    <p className="modal-title genericlabel  " style={{ letterSpacing: "3px" }}>Fraud Analysys:</p>
                                    <div className="d-flex align-items-center" style={{ gap: "14px" }}>


                                        {(fraudDaata.provider_id && fraudDaata.limit_count) && <span className="d-block text-center" style={{ width: "60px", padding: "1px", cursor: "pointer", borderRadius: "20px", fontSize: "12px", background: "lightgrey", height: "20px" }} onClick={() => {
                                            setfraudDaata({
                                                provider_id: "",
                                                start_date: "",
                                                end_date: "",
                                                limit_count: "",
                                                limit_price: ""
                                            });
                                            setfraudLevel("")
                                        }} >Clear</span>}


                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleFraudModalClose} /></span>
                                        </button>

                                    </div>

                                </div>
                                <div className="modal-body">
                                    <div>
                                        <label htmlFor="" className="genericlabel" >Choose Seller</label> <span className="text-danger" > * </span>
                                        <select name="provider_id" id="" className="form-control" value={fraudDaata.provider_id} onChange={fraudChangeHandler}>
                                            <option value="" disabled selected >Select Seller</option>
                                            {
                                                sellerList.map((item: any) => (
                                                    <option value={item?.provider_id} key={item?.seller_id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="" className="genericlabel" >Order Limit</label> <span className="text-danger" > * </span>
                                        <input type="number" min={0} onKeyPress={preventMinus} value={fraudDaata.limit_count} name="limit_count" className="form-control" onChange={fraudChangeHandler} />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="" className="genericlabel" >Price Limit</label> <span className="text-danger" > * </span>
                                        <input type="number" min={0} onKeyPress={preventMinus} value={fraudDaata.limit_price} name="limit_price" className="form-control" onChange={fraudChangeHandler} />
                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="" className="genericlabel" >Start Date</label> <span className="text-danger" > * </span>
                                        <input type="date" value={fraudDaata.start_date} name="start_date" className="form-control" max={getCurrentDate()} onChange={fraudChangeHandler} />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="" className="genericlabel" >End Date</label> <span className="text-danger" > * </span>
                                        <input type="date" value={fraudDaata.end_date} name="end_date" className="form-control" max={getCurrentDate()} onChange={fraudChangeHandler} />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    {/* <button type="button" className="btn btn-danger" onClick={() => {
                                        fetchSellerList(); setshipmentModalOpen(false); setfilterDaata({
                                            seller_id: "",
                                            orderCurrentStatus: "",
                                            endDate: ""
                                        })
                                    }} >Clear Filter</button> */}
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={analyzerFunction} disabled={(fraudDaata.end_date && fraudDaata.start_date && fraudDaata.limit_count && fraudDaata.limit_price && fraudDaata.provider_id) ? false : true} >{isAnalyzing ? "Please Wait.." : "Analyze"}</button>

                                    {fraudLevel !== "" && <div className="" style={{ width: "60%" }}>

                                        <div>
                                            <div className="progress">
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: `${fraudLevel}` }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                            <div className="d-flex justify-content-around mt-2" >
                                                {steps.map(item => {
                                                    return <span className="genericlabel">{item}</span>
                                                })}
                                            </div>
                                        </div>
                                    </div>}

                                </div>


                            </div>
                        </Box>
                    </Modal>
                </>

                {/* ------------------  FRAUD MODAL -------------------------- */}


            </ThemeMaker >

            <Modal
                open={orderUpdateModal}
                // onClose={handleOrderUpdateModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Sstyle} className="col-md-4 ">

                    <div className="modal-content " >
                        <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                            <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Order ID : {orderState.order_id} </p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ cursor: "pointer" }} ><CloseIcon onClick={() => {
                                    setorderUpdateModal(false);
                                    setOrderState({
                                        order_id: "", order_state: "", fulfillment_state: "", tracking_url: ""
                                    });
                                    setorderStatus(["Created", "Accepted", "In-progress", "Completed"])

                                    setOrderDetails(null)
                                }} /></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {orderState?.order_state !== "Cancelled" ? <div>
                                <div>
                                    <label htmlFor="">Order Status</label> <br />
                                    <select name="" id="" value={orderState?.order_state} onChange={(e) => {
                                        setOrderState((prev) => {
                                            return { ...prev, order_state: e.target.value }
                                        })
                                    }} >
                                        <option value="" disabled selected>Select Order Status </option>
                                        {orderStatus.map((item) => {
                                            return <option value={item} key={item}>{item}</option>
                                        })
                                        }
                                    </select>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="">Fulfillment Status</label> <br />
                                    <select name="" id="" value={orderState?.fulfillment_state} onChange={(e) => {
                                        setOrderState((prev) => {
                                            return { ...prev, fulfillment_state: e.target.value }
                                        })
                                    }}>
                                        <option value="">Select Fulfillment Status </option>
                                        {(orderState?.order_state === "Created" || orderState?.order_state === "Accepted") && <option value="Pending">Pending </option>}

                                        {orderState?.order_state === "In-progress" &&
                                            <> <option value="Packed">Packed</option>
                                                <option value="Order-picked-up">Order-picked-up</option>
                                                <option value="Out-for-delivery">Out-for-delivery</option>
                                            </>}
                                        {orderState?.order_state == "Completed" && <option value="Order-delivered">Delivered</option>}
                                    </select>
                                </div>
                            </div> : <p className="text-center">This Order has cancelled !</p>}

                        </div>

                        {orderState?.order_state !== "Cancelled" ? <div className="modal-footer d-flex justify-content-between">
                            <input type="text" className="form-control col-md-8" placeholder="Tracking Url" value={orderState?.tracking_url}
                                onChange={(e) => {
                                    setOrderState((prev: any) => {
                                        return { ...prev, tracking_url: e.target.value }
                                    })
                                }}
                            />

                            <button className="btn btn-success" onClick={updateThisOrder} > Update </button>

                        </div> : null}
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default OrderList;
