import React from 'react'
import "./ImageAdd.css"
import CloseIcon from '@mui/icons-material/Close';
export default function ImageAdd({ productImageHandler, productData, removeImageHandler, imageIndex }) {
    console.log(("productData-------->", productData.image))
    return (
        <div>

            <hr classNameName="sidebar-divider" />


            <div className="file-input-container" style={{ position: "absolute", top: "13px", right: "100px" }}>
                <input type="file" id="image" accept="image/*" name="image" multiple className='input_file' onChange={(e) => productImageHandler(e, imageIndex)}
                />
                <label for="image" className="custom-file-input-button">
                    Upload New
                </label>
            </div>

            {productData.image.length > 0 ? <div className='d-flex w-100 justify-content-start' style={{ gap: '30px', flexWrap: "wrap" }}>

                {productData.image.map((img, index) => (
                    <div key={index} className='p-4' style={{ border: "1px solid lightgrey", boxSizing: "border-box", justifySelf: "flex-start", maxWidth: "200px", maxHeight: "200px", position: "relative" }}>

                        <CloseIcon style={{ position: "absolute", top: "5px", right: "5px", cursor: "pointer", fontSize: '12px' }} onClick={() => removeImageHandler(index, imageIndex)} />

                        <div key={index} className='w-100 h-100' >

                            <img src={img} className='w-100' style={{ objectFit: "contain", height: "100%" }} />

                        </div>
                    </div>

                ))
                }

            </div> : <p className='text-center mt-5' style={{ fontSize: "20px", fontWeight: "600" }}>Add Product Images</p>}

        </div>
    )
}
