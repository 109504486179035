// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_bloc_tabs__Gv5cs {
    display: flex;
    list-style: none;
}

.style_bloc_tabs__Gv5cs li {
    font-size: 16px;
    letter-spacing: .5px;
}

.style_tabs__VkVIu {
    padding: 10px 10px 10px 15px;
    text-align: center;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
    background: transparent;
}

.style_active_tabs__KwuEA {
    color: #36b9cc;
    border-bottom: 3px solid;
}

.style_content_tabs__GPEtY {
    flex-grow: 1;
}

.style_contentShow__FMgmU {
    width: 100%;
    height: 100%;
    display: none;
}

.style_active_content__AgLRs {
    display: block;
}

.style_search_input_container__Bw5jV {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .style_search_select__FeRgr,
  .style_search_input__3Vwnn {
    border: none !important;
  }
  
  .style_search_select__FeRgr {
    /* margin-right: 10px;
    flex-shrink: 0;  */
  }`, "",{"version":3,"sources":["webpack://./src/pages/payments/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,eAAe;IACf,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;;IAEE,uBAAuB;EACzB;;EAEA;IACE;sBACkB;EACpB","sourcesContent":[".bloc_tabs {\n    display: flex;\n    list-style: none;\n}\n\n.bloc_tabs li {\n    font-size: 16px;\n    letter-spacing: .5px;\n}\n\n.tabs {\n    padding: 10px 10px 10px 15px;\n    text-align: center;\n    cursor: pointer;\n    box-sizing: content-box;\n    position: relative;\n    outline: none;\n    background: transparent;\n}\n\n.active_tabs {\n    color: #36b9cc;\n    border-bottom: 3px solid;\n}\n\n.content_tabs {\n    flex-grow: 1;\n}\n\n.contentShow {\n    width: 100%;\n    height: 100%;\n    display: none;\n}\n\n.active_content {\n    display: block;\n}\n\n.search_input_container {\n    display: flex;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .search_select,\n  .search_input {\n    border: none !important;\n  }\n  \n  .search_select {\n    /* margin-right: 10px;\n    flex-shrink: 0;  */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bloc_tabs": `style_bloc_tabs__Gv5cs`,
	"tabs": `style_tabs__VkVIu`,
	"active_tabs": `style_active_tabs__KwuEA`,
	"content_tabs": `style_content_tabs__GPEtY`,
	"contentShow": `style_contentShow__FMgmU`,
	"active_content": `style_active_content__AgLRs`,
	"search_input_container": `style_search_input_container__Bw5jV`,
	"search_select": `style_search_select__FeRgr`,
	"search_input": `style_search_input__3Vwnn`
};
export default ___CSS_LOADER_EXPORT___;
