// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sellerList_sellerListing_mobile__s4Plv {
  display: none;
}

.sellerList_sellerListOptions__DUQ\\+X {
  /* border: 1px solid red; */
  width: 46%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b9bbc0;
  padding-left: 5px;
  font-weight: 300;
  color: black;
  border-radius: 5px;
  margin-top: 10px;
}

.sellerList_sellerListOptions__DUQ\\+X select {
  background-color: blue;
  width: 140px;
  margin: 0 !important;
  font-weight: 500;
  color: white;
}

.sellerList_sellerListOptions__DUQ\\+X select option {
  background-color: #f8f9fc;
  color: black;
}

@media screen and (max-width: 768px) {

  .sellerList_sellerListOptions__DUQ\\+X {
    width: 84%;
    font-size: 12px;
  }
  .sellerList_sellerListing_desktop__6a4az {
    display: none;
  }

  .sellerList_sellerListing_mobile__s4Plv {
    display: block;
  }

  .sellerList_cardbody__NClnk {
    background-color: #F5F5F5;
    padding: 15px !important;
  }


}

@media screen and (max-width: 375px) {
  .sellerList_sellerListOptions__DUQ\\+X {
    /* width: 46%; */
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .sellerList_cardbody__NClnk {
    padding: 6px !important;
  }

  .sellerList_sellerListOptions__DUQ\\+X {
    width: 100%;
    font-size: 11px;
  }

  .sellerList_sellerListOptions__DUQ\\+X select {
    width: 150px;
  }
} `, "",{"version":3,"sources":["webpack://./src/pages/sellerlist/sellerList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,UAAU;EACV,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;;EAEE;IACE,UAAU;IACV,eAAe;EACjB;EACA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,wBAAwB;EAC1B;;;AAGF;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".sellerListing_mobile {\n  display: none;\n}\n\n.sellerListOptions {\n  /* border: 1px solid red; */\n  width: 46%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #b9bbc0;\n  padding-left: 5px;\n  font-weight: 300;\n  color: black;\n  border-radius: 5px;\n  margin-top: 10px;\n}\n\n.sellerListOptions select {\n  background-color: blue;\n  width: 140px;\n  margin: 0 !important;\n  font-weight: 500;\n  color: white;\n}\n\n.sellerListOptions select option {\n  background-color: #f8f9fc;\n  color: black;\n}\n\n@media screen and (max-width: 768px) {\n\n  .sellerListOptions {\n    width: 84%;\n    font-size: 12px;\n  }\n  .sellerListing_desktop {\n    display: none;\n  }\n\n  .sellerListing_mobile {\n    display: block;\n  }\n\n  .cardbody {\n    background-color: #F5F5F5;\n    padding: 15px !important;\n  }\n\n\n}\n\n@media screen and (max-width: 375px) {\n  .sellerListOptions {\n    /* width: 46%; */\n    font-size: 12px;\n  }\n}\n\n@media screen and (max-width: 320px) {\n  .cardbody {\n    padding: 6px !important;\n  }\n\n  .sellerListOptions {\n    width: 100%;\n    font-size: 11px;\n  }\n\n  .sellerListOptions select {\n    width: 150px;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sellerListing_mobile": `sellerList_sellerListing_mobile__s4Plv`,
	"sellerListOptions": `sellerList_sellerListOptions__DUQ+X`,
	"sellerListing_desktop": `sellerList_sellerListing_desktop__6a4az`,
	"cardbody": `sellerList_cardbody__NClnk`
};
export default ___CSS_LOADER_EXPORT___;
