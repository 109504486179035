// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errmess {
    font-size: 12px;
    color: red;
    padding-left: 17px;
    margin-top: 8px;
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/reset-password/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".errmess {\n    font-size: 12px;\n    color: red;\n    padding-left: 17px;\n    margin-top: 8px;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
