// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Discount_togglePerAmount__rwXex {
    background-color: grey;

}

.Discount_perAmount__g8p2w {
    text-align: center;
    width: 50%;
    padding: 0.25rem;
    font-size: 13px;
    padding-top: 0.5rem;

}

.Discount_addButtonSection__bwvv7 {
    padding: 9px;
    position: sticky;
    /* position: fixed; */
    bottom: -2px;
    background: white;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.Discount_radioStyle__kwxC4{
    /* style={{ width: "22px", height: "16px" }} */
    width: 22px !important;
    height: 16px !important;

}`, "",{"version":3,"sources":["webpack://./src/components/Discount/Discount.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;;AAE1B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,mBAAmB;;AAEvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,8CAA8C;IAC9C,sBAAsB;IACtB,uBAAuB;;AAE3B","sourcesContent":[".togglePerAmount {\n    background-color: grey;\n\n}\n\n.perAmount {\n    text-align: center;\n    width: 50%;\n    padding: 0.25rem;\n    font-size: 13px;\n    padding-top: 0.5rem;\n\n}\n\n.addButtonSection {\n    padding: 9px;\n    position: sticky;\n    /* position: fixed; */\n    bottom: -2px;\n    background: white;\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n}\n\n.radioStyle{\n    /* style={{ width: \"22px\", height: \"16px\" }} */\n    width: 22px !important;\n    height: 16px !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"togglePerAmount": `Discount_togglePerAmount__rwXex`,
	"perAmount": `Discount_perAmount__g8p2w`,
	"addButtonSection": `Discount_addButtonSection__bwvv7`,
	"radioStyle": `Discount_radioStyle__kwxC4`
};
export default ___CSS_LOADER_EXPORT___;
